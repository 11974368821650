import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FloorViewerSmartLockPoint from './FloorViewerSmartLockPoint.jsx';


export default function FloorPlanViewer({ selectedElements, highLightSelectedElements, floorPlanSmartLocks, hideCard, onOpenCameraFeed, onOpenSmartLocksEvents, onOpenLockDetails, onRemoteOpenRequest, showActions, selectedLocksAreaIN, selectedLocksAreaOUT, onToggleAreaIN, onToggleAreaOUT }) {
  const [dropCanvasBbox, setDropCanvasBbox] = useState({});
  const floorPlan = useSelector((state) => state.floorPlans.selectedFloorPlan);
  const smartLocks = floorPlanSmartLocks ? floorPlanSmartLocks : useSelector((state) => state.locks.data.content);
  const imageSource = floorPlan && floorPlan.link ? floorPlan.link : '';

  const setDropCanvasBBoxInState = () => {
    const dropCanvas = document.getElementById("dropCanvas");
    setDropCanvasBbox(dropCanvas ? dropCanvas.getBoundingClientRect() : {});
  }

  useEffect(() => {
    setDropCanvasBBoxInState();
    window.addEventListener('resize', setDropCanvasBBoxInState);
    return () => window.removeEventListener('resize', setDropCanvasBBoxInState);
  }, []);


  return (
    <div className={`floorMapCanvasContainer ${hideCard || "card-noHover"}`} style={{  overflow: 'auto', position: 'relative', margin: 20, marginLeft:0, marginTop: 0, padding: 10, height: '95vh', zIndex: 0 }}>
        <img id="dropCanvas" src={imageSource} style={{ height: '100%', display: 'flex', flex: 1, objectFit: 'contain' }} onLoad={() => setDropCanvasBBoxInState()} />
        {_.map(smartLocks, (smartLock) =>  {
          if (!smartLock || !smartLock.locationCoordinates || !smartLock.locationMedia || smartLock.locationMedia.id !== floorPlan.id) {
            return null;
          }
          const resourceX = smartLock.locationCoordinates.split(';')[0];
          const resourceY = smartLock.locationCoordinates.split(';')[1];
          return dropCanvasBbox ? (
            <FloorViewerSmartLockPoint
              key={smartLock.id}
              element={smartLock}
              x={resourceX * dropCanvasBbox.width}
              y={resourceY * dropCanvasBbox.height}
              showActions={showActions}
              highLightSelectedElements={highLightSelectedElements}
              isSelected={selectedElements && !_.isEmpty(selectedElements) && _.find(selectedElements, element => element.id === smartLock.id)}
              isSelectedAreaIN={selectedLocksAreaIN && !_.isEmpty(selectedLocksAreaIN) && _.find(selectedLocksAreaIN, element => element.id === smartLock.id)}
              isSelectedAreaOUT={selectedLocksAreaOUT && !_.isEmpty(selectedLocksAreaOUT) && _.find(selectedLocksAreaOUT, element => element.id === smartLock.id)}
              onOpenSmartLocksEvents={element => onOpenSmartLocksEvents(element)}
              onRemoteOpenRequest={element => onRemoteOpenRequest(element)}
              onOpenCameraFeed={element => onOpenCameraFeed(element)}
              onOpenLockDetails={element => onOpenLockDetails(element.id)}
              onToggleAreaIN={onToggleAreaIN?element => onToggleAreaIN(element.id): null}
              onToggleAreaOUT={onToggleAreaOUT?element => onToggleAreaOUT(element.id): null}
            /> ): null
        })}
    </div>
  )
}

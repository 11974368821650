import CardIcon from '@material-ui/icons/CreditCard';
import Info from '@material-ui/icons/InfoOutlined.js';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { ORANGE, PERMISSION_ENTITIES } from '../../../_config/consts';
import { hasFormWritePermission } from '../../../_config/utils';
import * as GuestActions from '../../../redux/actions/guest.actions.js';
import VisitorsIconCustom from '../../CustomIcons/VisitorsIconCustom.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import MDRadioButtonsGroupField from '../Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import CustomField from '../Fields/CustomField.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import { Warning } from '@material-ui/icons';
import moment from 'moment';

const validate = values => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = 'required';
  }

  if (!values.lastname) {
    errors.lastname = 'required';
  }
  if (!values.contactEmail) {
    errors.contactEmail = 'required';
  }

  if (!values.username) {
    errors.username = 'required';
  }

  if (!values.email) {
    errors.email = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'invalidEmailAddress';
  }
  return errors;
};


const visitDateOptions = [
  {
    label: <Entity entity="guestAlreadyInThePremise" />,
    value: 0,
  },
  {
    label: <Entity entity="selectVisitDate" />,
    value: 1,
  },
];

const credentialOptions = [
  {
    label: <Entity entity="dontCreateCredential" />,
    value: 'NO_CREDENTIAL',
  },
  {
    label: <Entity entity="cardCredential" />,
    value: 'CARD',
  },
  {
    label: <Entity entity="smartphoneCredential" />,
    value: 'SMARTPHONE',
  },
]

@reduxForm({ form: 'VisitorsForm', validate })
@connect(state => ({ form: state.form.VisitorsForm, companyConfigurations: state.user.companyConfigurations, customFields: state.customFields.users }))
class VisitorsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestsOptions: [],
    };
  }

  async onFilterGuests(value) {
    const { dispatch } = this.props;
    setTimeout(async () => {
      const guests = await dispatch(GuestActions.fetchGuestsForSelector(value));
      const filterSingleRoleGuests = _.filter(guests, guest => _.size(guest.roles) === 1);
      const options = _.map(filterSingleRoleGuests, guest => ({ value: guest, label: guest.email }));
      this.setState({ guestsOptions: options });
      return options;
    }, 800);
  }
  
  onSelectGuest(guest) {
    const { onSelectReferentUser } = this.props;
    if (guest) {
      if (onSelectReferentUser) onSelectReferentUser(guest.value);
    } else {
      if (onSelectReferentUser) onSelectReferentUser(null);
    }
  }

  render() {
    const {
      form,
      formStyle,
      dispatch,
    } = this.props;
      const {  guestsOptions } = this.state;
    const isEditing = form && form.values && form.values.id;
    const visitDateSelection = form && form.values && form.values.visitDateSelection;
    const selectedCredentialType = form && form.values && form.values.visitorCredentialSelection;
    return (
      <Form style={formStyle} autoComplete="off">
        <input style={{ display: 'none' }} type="username" name="username" />
        <FormCardContainer title="visitData" subtitle="newVisitorDescription" containerStyle={{ margin: 0, marginRight: 25 }} icon={<VisitorsIconCustom style={{ width: 25, marginBottom: 4, color: '#3f3f3f' }} />}>
          <h4 style={{ marginBottom: 0, marginTop: 30, color: '#3f3f3f', fontSize: 16, fontWeight: 'bold' }}><Entity entity="visitorExpectedDateTitle" /></h4>
            <Field
              name="visitDateSelection"
              component={MDRadioButtonsGroupField}
              containerStyle={{ marginBottom: 5 }}
              horizontal
              showErrorLabel
              onHandleChange={value => {
                if (value === 0) {
                  dispatch(change('VisitorsForm', 'expectedDate', null))
                }
              }}
              options={visitDateOptions}
            />
            {visitDateSelection && visitDateSelection === 1 ? (
              <div>
                <Field
                  id="expectedDate"
                  name="expectedDate"
                  hideIcon
                  placeholder="--/--/----"
                  containerStyle={{ marginTop: -10, marginBottom: 20 }}
                  onHandleChange={value => dispatch(change('VisitorsForm', 'expectedDate', value))}
                  isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                  component={DatePickerField}
                />
                <Entity
                  title={<Entity entity="notesForVisitor" />}
                  componentClass={Field}
                  name="notes"
                  componentAttribute="placeholder"
                  entity="insertNoteForVisitor"
                  component={CustomField}
                  type="textarea"
                  className="form-control-custom"
                />
              </div>
            ): null}
          <Entity
            componentClass={Field}
            name="firstname"
            componentAttribute="placeholder"
            entity="insertFirstName"
            title={<Entity entity="firstName" />}
            component={CustomField}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.VISITORS, isEditing)}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="lastname"
            componentAttribute="placeholder"
            entity="insertSurname"
            title={<Entity entity="surname" />}
            component={CustomField}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.VISITORS, isEditing)}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="email"
            componentAttribute="placeholder"
            entity="insertEmail"
            title="E-mail"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.VISITORS, isEditing)}
            component={CustomField}
            mandatory
          />
          <h4 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0 }}><Entity entity="contactEmail" /><span style={{ color: 'red' }}>*</span></h4>
          <Field
            name="guestTagSelectedSearch"
            component={SelectableField}
            noCache
            options={guestsOptions}
            placeholder={<Entity entity="guestNameSearch" />}
            style={{ marginTop: 10 }}
            onInputChange={value => this.onFilterGuests(value)}
            onSelect={tagData => this.onSelectGuest(tagData)}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Info style={{ fontSize: 20, color: '#3f3f3f', marginRight: 10 }} />
            <h4 style={{ fontSize: 14, marginTop: 15 }}><Entity entity="contactEmailDescription" /></h4>
          </div>
        </FormCardContainer>
        <FormCardContainer title="visitorCredential" subtitle="visitorCredentialDescription" containerStyle={{ margin: 0, marginRight: 25 }} icon={<CardIcon style={{ fontSize: 27 }} />}>
          <Field
            name="visitorCredentialSelection"
            component={MDRadioButtonsGroupField}
            containerStyle={{ marginBottom: 5, marginTop: 10 }}
            showErrorLabel
            options={credentialOptions}
          />
          {selectedCredentialType && selectedCredentialType === 'SMARTPHONE' ? (
            <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
              <Warning style={{ color: ORANGE, marginRight: 5 }} />
              <h5 style={{ margin: 0 }}><Entity entity="smartphoneCredentialForVisitorWarning" /></h5>
            </div>
          ): null}
          </FormCardContainer>
      </Form>
    );
  }
} 

export default VisitorsForm;

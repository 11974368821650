import { Card, Grid, withStyles } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import CheckInIcon from '@material-ui/icons/CheckCircleOutline.js';
import CalendarIcon from '@material-ui/icons/Event.js';
import CheckoutIcon from '@material-ui/icons/ExitToApp.js';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { change, initialize, submit } from 'redux-form';
import { GREEN, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../../_config/consts';
import { localizeHelpCenterLink } from '../../_config/utils.js';
import ScrollableBarChart from '../../components/Charts/ScrollableBarChart.jsx';
import VisitorsIconCustom from '../../components/CustomIcons/VisitorsIconCustom.jsx';
import EmptyView from '../../components/EmptyView.jsx';
import SimpleExportMenu from '../../components/ExportMenus/SimpleExportMenu.jsx';
import MDButton from '../../components/MDButton/MDButton.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import MetricCard from '../../components/Visitors/MetricCard.jsx';
import VisitorsTableView from '../../components/Visitors/VisitorsTableView.jsx';
import VisitorsFilters from '../../components/forms/Visitors/VisitorsFilters.jsx';
import VisitorsForm from '../../components/forms/Visitors/VisitorsForm.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as GuestActions from '../../redux/actions/guest.actions.js';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as VisitorsActions from '../../redux/actions/visitors.actions.js';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
  },
  header: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  chartsContainer: {
    flexWrap: 'unset !important',
    margin: 25,
    marginTop: 120,
    width: 'auto'
  },
});


let filterTimeout;
@connect(state => ({ form: state.form.CommunicationForm, visitors: state.visitors, routing: state.router, languange: state.settings.language, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class VisitorsManagementView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRefreshingVisitorsChartData: false,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    this.onFetchVisitors();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.setVisitorsOperationalMode(false));
  }

  async onFetchVisitors() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(VisitorsActions.fetchVisitors());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.fetchVisitors())
  }

  onNewVisitorButtonClicked() {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.setVisitorsOperationalMode(true));
    dispatch(VisitorsActions.setSelectedVisitor({}));
    dispatch(initialize('VisitorsForm', { visitDateSelection: 0, visitorCredentialSelection: 'NO_CREDENTIAL' }))

  }


  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.setSelectedVisitor({}));
    dispatch(VisitorsActions.setVisitorsOperationalMode(false));
  }

  onSetFilter(field, value) {
    const { dispatch } = this.props;
    let finalValue = value;
    if (!value || (value && _.isEmpty(value))) {
      finalValue = null;
    }
    dispatch(VisitorsActions.setVisitorsFilter(field, finalValue));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.onFetchVisitors();
    }, 500);
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.resetVisitorsFilters());
    this.resetDateFilter();
    setTimeout(() => this.onFetchVisitors(), 400);
  }


  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.exportVisitors(format));
  }
  
  onVisitorsListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    VisitorsActions.setVisitorsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(VisitorsActions.resetVisitorsPagination());
      dispatch(VisitorsActions.fetchVisitors());
    }
  }

  onFetchVisitorsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(VisitorsActions.fetchVisitors(page));
  }

  async onCreateNewVisitor(visitorData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const newVisitor = await dispatch(VisitorsActions.createVisitor(visitorData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchVisitors();
      dispatch(VisitorsActions.setVisitorsOperationalMode(false));
      dispatch(VisitorsActions.setSelectedVisitor({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="visitorCreatedSuccessFully" /></h6>),
        },
      }));
    if (visitorData && visitorData.visitorCredentialSelection && visitorData.visitorCredentialSelection !== 'NO_SELECTION') {
      dispatch(VisitorsActions.setSelectedVisitor(newVisitor));
      switch (visitorData.visitorCredentialSelection) {
        case 'CARD':
          return this.onCreateUserAndOpenCardForm(newVisitor)
        case 'SMARTPHONE':
          return this.onCreateUserAndOpenSmartphoneCredentialForm(newVisitor)
      }
    }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="visitorCreateError" /></h6>),
        },
      }));
    }
  }

  async onCreateUserAndOpenSmartphoneCredentialForm(newVisitor) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const newGuest = await dispatch(GuestActions.createGuest({ ...newVisitor, username: newVisitor.email }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(push(`/credentials?newElementForGuest=${newGuest.userTag.id}`));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onCreateUserAndOpenCardForm(newVisitor) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const newGuest = await dispatch(GuestActions.createGuest({ ..._.omit(newVisitor, 'email'), username: newVisitor.email }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(push(`/cards?newElementForGuest=${newGuest.userTag.id}`));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onOpenInfo() {
    const helpLink = localizeHelpCenterLink('luckey-visitors-addon');
    window.open(helpLink);
  }

  onSelectReferentUser(user) {
    const { dispatch } = this.props;
    if (!user) {
      dispatch(change('VisitorsForm', 'contactEmail', null));
    } else {
      dispatch(change('VisitorsForm', 'contactEmail', user.email));
    }
  }

  onFilterForReferentUser(user) {
    const { dispatch } = this.props;
    if (!user) {
      dispatch(VisitorsActions.setVisitorsFilter('contactEmail', null));
    } else {
      dispatch(VisitorsActions.setVisitorsFilter('contactEmail', user.email));
    }
    setTimeout(() => this.onFetchVisitors(), 300);
  }

  onDateRangeSelected(startDate, endDate) {
    const { dispatch } = this.props;
    const finalStartDate = startDate ? moment(startDate).startOf('day').valueOf() : moment().subtract(1, 'day').startOf('day').valueOf();
    const finalEndDate = endDate ? moment(endDate).endOf('day').valueOf() : moment().add(1, 'day').endOf('day').valueOf();
    dispatch(VisitorsActions.setVisitorsFilter('fromDate', finalStartDate));
    dispatch(VisitorsActions.setVisitorsFilter('toDate', finalEndDate));
    setTimeout(() => this.onFetchVisitors(), 300);
  }

  resetDateFilter() {
    this.onDateRangeSelected(moment(), moment());
  }

  async onCheckoutVisitor(visitor) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(VisitorsActions.checkOutVisitor(visitor.code));
      dispatch(VisitorsActions.fetchVisitors());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="checkoutVisitorSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="checkoutVisitorError" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCheckInVisitorRequest(visitor) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="checkinAlertDescription" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onCheckInVisitorConfirm(visitor),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onCheckInVisitorConfirm(visitor) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(VisitorsActions.checkInVisitor(visitor.code));
      dispatch(VisitorsActions.fetchVisitors());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="checkInVisitorSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="checkInVisitorError" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onDeleteVisitorRequest(visitor) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteVisitorDescriptionDescription" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteVisitorConfirm(visitor),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteVisitorConfirm(visitor) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(VisitorsActions.deleteVisitor(visitor.id));
      dispatch(VisitorsActions.fetchVisitors())
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deleteVisitorSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deleteVisitorError" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { classes, visitors: { isOperationalMode, data: { content, chartData, filters: { fromDate, toDate } } }, themeName, dispatch } = this.props;
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.VISITORS);
    const checkInNumbers = _.size(_.filter(content, visit => visit.checkInDate))
    const checkOutNumbers = _.size(_.filter(content, visit => visit.checkOutDate));
    const lastVisitDate = _.last(content) && _.last(content).expectedDate;
    return (
      <div style={{ height: '120vh', paddingBottom: 50, overflow: 'scroll' }}>
        <Grid container spacing={2} className={classes.chartsContainer}>
          <Grid item md={3} xs={12} className={classes.chartItem}>
            <MetricCard
              title={<Entity entity="visitors" />}
              metricNumber={_.size(content)}
              icon={<VisitorsIconCustom style={{ width: 30 }} />}
            />
            </Grid>
            <Grid item md={3} xs={12} className={classes.chartItem}>
              <MetricCard
                title={<Entity entity="checkIn" />}
                metricNumber={checkInNumbers}
                icon={<CheckInIcon style={{ fontSize: 30 }} />}
              />
            </Grid>
            <Grid item md={3} xs={12} className={classes.chartItem}>
              <MetricCard
                title={<Entity entity="checkOut" />}
                metricNumber={checkOutNumbers}
                icon={<CheckoutIcon style={{ fontSize: 30 }} />}
              />
            </Grid>
            <Grid item md={3} xs={12} className={classes.chartItem}>
              <MetricCard
                title={<Entity entity="lastExpectedVisit" />}
                metricNumber={moment(lastVisitDate).format('LL')}
                icon={<CalendarIcon style={{ fontSize: 30 }} />}
              />
            </Grid>
        </Grid>
        <ReactCSSTransitionGroup
          style={{ paddingBottom: 0 }}
          transitionName="fadeInOut"
          transitionEnterTimeout={0}
          transitionLeaveTimeout={0}
        >
          {content && !_.isEmpty(content) ? (
            <Card style={{ borderRadius: 10, margin: 25, marginTop: 0 }}>
              <h4 style={{ fontWeight: 'bold', margin: 20 }}><Entity entity="visitorAverageTimeArrival" /></h4>
              <ScrollableBarChart
                height={100}
                chartMaxWidth={window.screen.width - 270}
                data={_.cloneDeep(chartData)}
                dataSetSize={24}
                showLegend={false}
              />
            </Card>
          ) : null}
        </ReactCSSTransitionGroup>
        <div className={classes.header}>
          <VisitorsFilters
            themeName={themeName}
            fromDate={fromDate}
            toDate={toDate}
            onDatesChanged={(startDate, endDate) => this.onDateRangeSelected(startDate, endDate)}
            onResetDateFilters={() => this.resetDateFilter()}
            onSelectReferentUser={guest => this.onFilterForReferentUser(guest)}
            onSetFilter={(field, value) => this.onSetFilter(field, value)}
            onNewVisitorButtonClicked={canCreateItem ? () => this.onNewVisitorButtonClicked() : null}
            onExportElements={format => this.onExportElements(format)}
            onResetFilters={() => this.onSearchReset()}
          />
        </div>
        {content && !_.isEmpty(content) ? (
          <div style={{ marginTop: 40, margin: 25 }}>
            <VisitorsTableView
              themeName={themeName}
              canCreateItem={canCreateItem}
              onRefreshVisitors={() => this.onFetchVisitors()}
              onCheckoutVisitor={(visitor) => this.onCheckoutVisitor(visitor)}
              onCheckInVisitor={visitor => this.onCheckInVisitorRequest(visitor)}
              onDeleteVisitor={visitor => this.onDeleteVisitorRequest(visitor)}
              onFetchVisitorsOnPage={page => this.onFetchVisitorsOnPage(page)}
              onOpenReferentDetails={visitorEmail => dispatch(push(`/guests?guestEmail=${visitorEmail}`))}
              onOrderChanged={() => this.onOrderChanged()}
              onRefresh={() => this.onFetchVisitors()}
            />
          </div>
        ) : (
          <EmptyView
            title="noVisitors"
            containerStyle={{ paddingTop: 0, marginTop: 50 }}
            subtitle="registerVisitor"
            newEntityTitle="addVisitor"
            onNewEntity={canCreateItem ? () => this.onNewVisitorButtonClicked() : null}
            iconName="icon-simple-line-icons-user-following"
          />
        )}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="newVisitor" />}
        >
          <div style={{ padding: 0, paddingBottom: '30%' }}>
            <VisitorsForm
              onSelectReferentUser={guest => this.onSelectReferentUser(guest)}
              onSubmit={visitorData => this.onCreateNewVisitor(visitorData)}
            />
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              onClick={() => dispatch(submit('VisitorsForm'))}
            />
          </div>
        </OperationalView>
      </div>
    );
  }
} 

export default withStyles(styles)(VisitorsManagementView);

// @ts-nocheck
import { IconButton, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import TimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Cancel';
import KeyboardArrowRight from '@material-ui/icons/ChevronRight';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import InvitationIcon from '@material-ui/icons/InsertLink';
import { Modal } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { BLUE, GREEN, INVITATION_STATUS_PARAMETERS, RED } from '../../_config/consts';
import { getLockImage } from '../../_config/utils';
import RegistryIconCustom from '../CustomIcons/RegistryIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import InvitationShareSection from './InvitationShareSection.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import PlatformUserIconCustom from '../CustomIcons/PlatformUserIconCustom.jsx';

const styles = {
  cardContainer: {
    margin: 'auto',
    marginTop: 50,
    borderRadius: 10,
    overflow: 'initial',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    width: '70%',
    position: 'relative',
  },
  headerIcon: {
    color: 'cornflowerblue',
    display: 'flex',
    margin: 'auto',
    fontSize: 50,
  },
  headerIconRoundedContainer: {
    width: 'min-content',
    padding: 20,
    borderRadius: 100,
    backgroundColor: 'white',
    margin: 'auto',
    marginTop: '-35px',
  },
  codeContainer: {
    padding: 5,
    borderRadius: 10,
    width: 'min-content',
    margin: 'auto',
    padding: 15,
    paddingLeft: 30,
    paddingRight: 22,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  codeText: {
    color: GREEN,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0
  },
  statusContainer: {
    borderRadius: 5,
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 10
  },
  closeContainer: {
    position: 'absolute',
    right: 10,
    borderRadius: 5,
    padding: 10,
  },
  closeIcon: {
    color: RED,
    fontSize: 40,
  },
  statusText: {
    fontWeight: 'bold',
    color: 'white',
    margin: 0,
    fontSize: 11,
  },
  sectionText: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  titleText: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 30,
  },
};

class InvitationDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSharingSection: false,
    };
  }

  render() {
    const { showSharingSection } = this.state;
    const { invitation, classes, onDeleteInvitation, onCloseModal, onShowInvitationEventsView } = this.props;
    const invitationFromDate = invitation && invitation.dateInterval && invitation.dateInterval.from;
    const invitationFromTo = invitation && invitation.dateInterval && invitation.dateInterval.to;
    const days = invitation && invitation.daysOfTheWeek && _.orderBy(invitation.daysOfTheWeek);
    const invitationStatusText = invitation && invitation.invitationStatus ? INVITATION_STATUS_PARAMETERS[invitation.invitationStatus].string : '';
    const invitationStatusColor = invitation && invitation.invitationStatus ? INVITATION_STATUS_PARAMETERS[invitation.invitationStatus].color : '';
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    const numberOfDevicesString = invitation && invitation.numberOfDevices === 0 ? ` - ${L20NContext.getSync('unlimited')}` : ` / ${invitation.numberOfDevices}`;
    return (
      <Card className={classes.cardContainer}>
        <Card className={classes.headerIconRoundedContainer}><InvitationIcon className={classes.headerIcon}/></Card>
        <h2 className={classes.titleText}>{invitation.name || <Entity entity="invitation" />}</h2>
        <div style={{ marginTop: 0, position: 'relative' }}>
          <h4 className={classes.sectionText} style={{ textAlign: 'center', fontWeight: 'normal' }}>{<Entity entity="invitationCode" />}</h4>
            <Card
              className={`card card-rounded ${classes.codeContainer}`}
              onClick={() => this.setState({ showSharingSection: true })}
            >
              <h2 className={classes.codeText}>{invitation.code}</h2>
              <ShareIconCustom style={{ marginLeft: 12, color: GREEN, width: 25 }} />
            </Card>
        </div>
        {onCloseModal ? (
          <div className={classes.closeContainer}>
            <IconButton onClick={() => onCloseModal()}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
        ) : null}
        <div style={{ marginTop: 40, width: 'fit-content' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <h4 className={classes.sectionText}>{<Entity entity="invitationValidity" />}</h4>
            <div className={classes.statusContainer} key={invitation.invitationStatus} style={{ backgroundColor: invitationStatusColor }}>
              <h5 className={classes.statusText}>
                <Entity
                  key={`status_${invitation.invitationStatus}`}
                  style={{ textTransform: 'uppercase' }}
                  entity={invitationStatusText}
                />
              </h5>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CalendarIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h4 style={{ margin: 0, marginLeft: 10, color: '#3f3f3f' }}>
                {moment(invitationFromDate).format('LL')} - {moment(invitationFromDate).format('LT')}
              </h4>
              <KeyboardArrowRight style={{ marginLeft: 10, marginRight: 10, fontSize: 30, color: '#3f3f3f' }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ color: '#3f3f3f', margin: 0 }}>
                {moment(invitationFromTo).format('LL')} - {moment(invitationFromTo).format('LT')}
              </h4>
            </div>
          </div>
          <h4 className={classes.sectionText} style={{ marginTop: 20 }}>{<Entity entity="createdBy" />}</h4>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {invitation.createdBy ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <UsersIconCustom style={{ color: '#3f3f3f', width: 20 }} />
                <h4 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10 }}>{`${invitation.createdBy.firstname} ${invitation.createdBy.lastname} ${invitation.createdBy.email}`}</h4>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <PlatformUserIconCustom style={{ color: '#3f3f3f', width: 20 }} />
                <h4 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10 }}><Entity entity="platformUser" /></h4>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <h4 className={classes.sectionText}>{<Entity entity="numberOfRedeems" />}</h4>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -10 }}>
            <h4 style={{ fontSize: 17, color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginLeft: 10 }}>
              {`${invitation.numberOfRedeems} ${numberOfDevicesString}`}
            </h4>
            <IconButton
              style={{ marginLeft: 5, color: '#3f3f3f' }}
              onClick={(e) => {
                e.stopPropagation();
                onShowInvitationEventsView(invitation);
              }}
            >
              <RegistryIconCustom style={{ width: 22, color: BLUE }} />
            </IconButton>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <h4 className={classes.sectionText}>{<Entity entity="invitationTimeProfile" />}</h4>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TimeIcon style={{ color: '#3f3f3f' }} />
              <h4 style={{ fontSize: 17, color: '#3f3f3f', margin: 0, marginLeft: 10 }}>
                {moment(invitation.invitationCredentialTimeIntervalFrom).format('LT')} - {moment(invitation.invitationCredentialTimeIntervalTo).format('LT')}
              </h4>
            </div>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              {_.map(daysOptions, option => (
                <h4 key={option.label} style={{ fontSize: 17, margin: 0, marginRight: 7, color: option.selected ? '#3f3f3f' : 'red', fontWeight: option.selected ? 'normal' : 'light' }}>
                  {option.label.toUpperCase()}
                </h4>
                ))}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <h4 className={classes.sectionText}><Entity entity="locks" />{` (${_.size(invitation.smartLocks)})`}</h4>
          <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
            <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
              <InfiniteScroll
                pageStart={0}
                useWindow={false}
                initialLoad={false}
              >
                {_.map(invitation.smartLocks, (item, index) =>
                  <div
                    className="card-noHover"
                    style={{ margin: 0 }}
                  >
                    <div className="card-body" style={{ position: 'relative' }}>
                      <div className="rounded-view" style={{ height: 'fit-content' }}>
                        <img alt="lock" className="lock-icon" style={{ width: 50, height: 50 }} src={getLockImage(item.model)} />
                      </div>
                      <h4 style={{ color: '#3f3f3f', marginLeft: 10 }}>
                        {item.name}
                      </h4>
                    </div>
                  </div>,
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
        <MDButton
          title={<Entity entity="deleteInvitation" />}
          backgroundColor={RED}
          containerStyle={{ marginTop: 40 }}
          onClick={() => onDeleteInvitation(invitation)}
        />
        <Modal show={showSharingSection} onHide={() => this.setState({ showSharingSection: false })}>
          <div style={{ padding: 20, paddingTop: 10 }}>
            <div style={{ display: 'flex', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="shareInvitationTitle" /></h2>
              <IconButton onClick={() => this.setState({ showSharingSection: false })}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
            <InvitationShareSection resource={invitation.code} />
          </div>
        </Modal>
      </Card>
      
    );
  }
}

export default withStyles(styles)(InvitationDetailView);


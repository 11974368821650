import { AppBar, Card, Grid, withStyles } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/InsertChart';
import { Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { BLUE, PERMISSIONS, PERMISSION_ENTITIES } from '../_config/consts';
import { getBatteryLevelFromPercentage, getColorFromThemeName, isMobileBrowser } from '../_config/utils';
import CameraLiveFeedView from '../components/Cameras/CameraLiveFeedView.jsx';
import DashboardFilters from '../components/Dashboard/DashboardFilters.jsx';
import FloorPlanDashboardViewer from '../components/FloorPlans/FloorPlanDashboardViewer.jsx';
import GuestDashboardDetails from '../components/Guests/GuestDashboardDetails.jsx';
import LockDashboardDetails from '../components/Locks/LockDashboardDetails.jsx';
import LockDashboardList from '../components/Locks/LockDashboardList.jsx';
import LockRemoteOpenView from '../components/Locks/LockRemoteOpenView.jsx';
import BaseMetricChart from '../components/MetricCharts/BaseMetricChart.jsx';
import DailyActiveUsersList from '../components/MetricCharts/DailyActiveUsersList.jsx';
import BasicModal from '../components/Modals/BasicModal.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import LuckeyStoreBanner from '../components/Store/LuckeyStoreBanner.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import * as CamerasActions from '../redux/actions/cameras.actions.js';
import * as CloudCreditsActions from '../redux/actions/cloudCredits.actions';
import * as FloorPlansActions from '../redux/actions/floorplans.actions.js';
import * as GatewaysActions from '../redux/actions/gateways.actions.js';
import * as LockActions from '../redux/actions/lock.actions';
import * as MetricsActions from '../redux/actions/metrics.actions';
import * as ModalActions from '../redux/actions/modal.actions.js';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import * as AreaActions from '../redux/actions/area.actions';
import SubcompaniesOrganizationChart from '../components/MetricCharts/SubcompaniesOrganizationChart.jsx';
import AreasListView from '../components/CapacityManagement/AreasListView.jsx';
import DailyActiveSmartLocksList from '../components/MetricCharts/DailyActiveSmartLocksList.jsx';
import DailyActiveEntityList from '../components/MetricCharts/DailyActiveEntityList.jsx';


const styles = (theme) => ({
  container: {
    height: '120vh',
    overflowY: 'scroll',
    overflowX: 'clip',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 400,
    marginTop: -5
  },
  chartsContainer: {
    flexWrap: 'unset !important',
    marginBottom: 10
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 10,
  },
  chartItem: {
    zoom: '85%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    margin: 10,
    marginBottom: 0,
  },
  logsCardContainer: {
    margin: 15,
    marginTop: 10,
    overflow: 'auto',
    backgroundColor: 'white',
    borderRadius: 10,
    zoom: '80%',
  },
  logTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logIcon: {
    color: BLUE,
    fontSize: 30,
  },
  logsGridContainer: {
    width: isMobileBrowser() ? '100%' : '100%',
    marginTop: 50,
  },
});

class DashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshingMetric: [],
      isRefreshingCounters: false,
      isRefreshingAccessChartData: false,
      isRefreshingAdminEvents: false,
      isRefreshingBatteryData: false,
      isRefreshingOccupancyHourlyData: false,
      isMetabaseEnabled: false,
      selectedGuest: null,
      selectedLock: null,
      showGuestLogsDetails: false,
      showLockLogsDetails: false,
      showLocksList: false,
      selectedSubCompany: null,
      selectedArea: null,
      initialArea: null,
    };
  }

  async componentWillMount() {
    const { dispatch } = this.props;
    try {
      await dispatch(CloudCreditsActions.fetchBannerContent());
    } catch (error) {}
    this.onInitializeMetric();
    const isMetabaseEnabled = dispatch(UtilsActions.isMetabaseEnabled());
    this.setState({ isMetabaseEnabled });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockActions.resetLocksFilters());
  }
  
  async onInitializeMetric() {
    const { dispatch } = this.props;
    this.setState({ isRefreshingCounters: true, isRefreshingLogs: true });
    const isCapacityManagementActive = dispatch(UtilsActions.isCapacityManagementActive());
    try {
      await dispatch(MetricsActions.fetchAllCounterMetrics());
      if (isCapacityManagementActive) {
        await dispatch(AreaActions.fetchAreas());
      }
      this.setState({ isRefreshingCounters: false });
    } catch (error) {
      this.setState({ isRefreshingCounters: false });
    }
    this.onFetchAccessDataAndCharts();
    this.onFetchLocksBatteryChartData();
    if (isCapacityManagementActive) {
      const { areas: { data: { content: areasData } } } = this.props;
      if (areasData && areasData.length > 0) {
        const dashboardSelectedAreaId = localStorage.getItem('dashboardSelectedAreaId');
        let initialArea = areasData[0]
        if (dashboardSelectedAreaId && !isNaN(parseInt(dashboardSelectedAreaId))) {
          const areaId = parseInt(dashboardSelectedAreaId);
          const areasSearch = areasData.filter(area => area.id === areaId);
          if (areasSearch.length)
            initialArea = areasSearch[0]
        }
        this.setState({ initialArea: initialArea });
        this.setState({ selectedArea: initialArea });
      }
      
      setTimeout(() => this.onFetchAreasOccupancyHourly(), 300);
    }
    dispatch(FloorPlansActions.fetchFloorPlans(0, 100));
    dispatch(MetricsActions.fetchAccessoriesMetric());
  }

  async onRefreshMetric(metricName) {
    const { dispatch } = this.props;
    this.setState({ refreshingMetric: metricName });
    await dispatch(MetricsActions.fetchMetricByName(metricName));
    this.setState({ refreshingMetric: null });
  }

  async onFetchAccessDataAndCharts(forceFetching = false) {
    const { dispatch } = this.props;
    const { selectedSubCompany } = this.state;
    try {
      this.setState({ isRefreshingAccessChartData: true });
      const { metrics: { accessChartData: { data: chartData, filters: { startDate, endDate } } } } = this.props;
      if (_.isEmpty(chartData) || forceFetching) {
        if (selectedSubCompany) {
          await dispatch(MetricsActions.fetchSubCompanyAccessEvents(startDate, endDate, selectedSubCompany));
        } else {
          await dispatch(MetricsActions.fetchAccessEvents(startDate, endDate));
        }
      }
      this.setState({ isRefreshingAccessChartData: false });
      const isCapacityManagementActive = dispatch(UtilsActions.isCapacityManagementActive());
      if (isCapacityManagementActive) {
        this.onFetchAreasOccupancy()
        this.onFetchAreasOccupancyHourly()
      }
    } catch (error) {
      this.setState({ isRefreshingAccessChartData: false });
    }
  }

  async onFetchAreasOccupancy() {
    const { dispatch, areas: { data: { content: areasData }} } = this.props;
    await dispatch(AreaActions.cancelFetchAllAreasOccupancy());
    const startDate = moment().subtract('1 day').startOf('day').valueOf();
    const endDate = moment().endOf('day').valueOf();
    try {
      await dispatch(AreaActions.setAreasOccupancyLoading(true));
      await dispatch(AreaActions.resetAreasOccupancy());
      await dispatch(MetricsActions.fetchAccessEvents(startDate, endDate, 0, 100000, true));
      await dispatch(AreaActions.fetchAllAreasOccupancy(areasData));
    } catch (error) {
      await dispatch(AreaActions.setAreasOccupancyLoading(false));
    }
  }

  async onFetchAreasOccupancyHourly() {
    const { dispatch } = this.props;
    const { selectedArea } = this.state;
    if (!selectedArea || !selectedArea.id)
      return;
    //const { areas: { occupancyHourly: { filters: { startTime: startDateCapacityManagement, endTime: endDateCapacityManagement } }} } = this.props;
    //if (!startDateCapacityManagement || !endDateCapacityManagement)
    //  await this.onResetDateFilters();
    try {
      this.setState({ isRefreshingOccupancyHourlyData: true });
      await dispatch(AreaActions.fetchAreaOccupancyHourly(selectedArea));
      this.setState({ isRefreshingOccupancyHourlyData: false });
    } catch (error) {
      this.setState({ isRefreshingOccupancyHourlyData: false });
    }
  }

  /*
  async onDateRangeSelectedCapacityManagement(startDate, endDate) {
    const { dispatch } = this.props;
    await dispatch(AreaActions.setAreaOccupancyHourlyFilter('startTime', moment(startDate).startOf('day').valueOf()));
    await dispatch(AreaActions.setAreaOccupancyHourlyFilter('endTime', moment(endDate).endOf('day').valueOf()));
    this.onFetchAreasOccupancyHourly();
  }

  async onResetDateFiltersCapacityManagement() {
    const { dispatch } = this.props;
    await this.onDateRangeSelectedCapacityManagement(moment(), moment());
  }
  */

  async onDateRangeSelected(startDate, endDate) {
    const { dispatch } = this.props;
    dispatch(MetricsActions.saveAccessChartFilter('startDate', moment(startDate).startOf('day').valueOf()));
    dispatch(MetricsActions.saveAccessChartFilter('endDate', moment(endDate).endOf('day').valueOf()));
    setTimeout(() => this.onFetchAccessDataAndCharts(true), 300);
  }

  async onResetDateFilters() {
    const { dispatch } = this.props;
    await this.onDateRangeSelected(moment(), moment());
  }

  async onFetchLocksBatteryChartData(forceFetching = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ isRefreshingBatteryData: true });
      const { metrics: { locksBatteryChart: { data: chartData } } } = this.props;
      if (_.isEmpty(chartData) || forceFetching) {
        await dispatch(MetricsActions.fetchSmartLocksBatteryLevelChartData());
      }
      this.setState({ isRefreshingBatteryData: false });
    } catch (error) {
      this.setState({ isRefreshingBatteryData: false });
    }
  }

  onLockClick(lockId) {
    const { dispatch } = this.props;
    dispatch(push(`/locks?lockId=${lockId}`));
  }

  onAdminEventsClick() {
    const { dispatch } = this.props;
    dispatch(push('/adminEvents'));
  }

  onSmartLocksEventsClick() {
    const { dispatch } = this.props;
    dispatch(push('/smartLockEvents'));
  }

  onGoToSmartLocksPage() {
    const { dispatch } = this.props;
    dispatch(push('/locks'));
  }

  onMetricClick(metricPath) {
    const { dispatch } = this.props;
    dispatch(push(metricPath));
  }

  onOpenAdvancedAnalytics() {
    const { dispatch } = this.props;
    const url = dispatch(UtilsActions.getAdvacedAnalyticsURL());
    window.open(url);
  }

  onShowGuestLogs(user) {
    this.setState({ selectedGuest: user, showGuestLogsDetails: true, showLockLogsDetails: false });
  }

  onShowSmartLockLogs(lockData) {
    this.setState({ selectedLock: lockData, showLockLogsDetails: true, showGuestLogsDetails: false });
  }

  onOpenGuestProfile(selectedGuest) {
    const { dispatch } = this.props;
    dispatch(push(`/guests?guestId=${selectedGuest.id}`));
  }

  onOpenLockDetails(lockId) {
    const { dispatch } = this.props;
    dispatch(push(`/locks?lockId=${lockId}`));
  }

  async onShowLockBatteryPerLevel(batteryPercentage) {
    const { dispatch } = this.props;
    const batteryLevel = getBatteryLevelFromPercentage(batteryPercentage);
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      dispatch(LockActions.setFilter('powerSourceType', 'BATTERY'));
      dispatch(LockActions.setFilter('batteryMin', batteryLevel));
      dispatch(LockActions.setFilter('batteryMax', batteryLevel));
      await dispatch(LockActions.fetchLocks());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showLocksList: true, selectedBatteryPercentage: batteryPercentage });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onExportActiveUsersList(format) {
    const { dispatch } = this.props;
    dispatch(MetricsActions.exportActiveUsers(format));
  }

  onExportActiveSmartLocksList(format) {
    const { dispatch } = this.props;
    dispatch(MetricsActions.exportActiveSmartLocks(format));
  }

  onShortcutClick(metricName) {
    const { dispatch } = this.props;
    switch (metricName) {
      case 'guests':
        return dispatch(push('/guests?newEntity=true'));
      case 'smartLocks':
        return dispatch(push('/locks'));
      case 'pins':
        return dispatch(push('/pins?newEntity=true'));
      case 'standardDevices':
        return dispatch(push('/cards?newEntity=true'));
      case 'smartPhoneRules':
        return dispatch(push('/credentials?newEntity=true'));
    case 'hyperKeys':
      return dispatch(push('/hyperKeys?newEntity=true'));
      default:
        return null;
    }
  }

  async onSelectSubcompany(subcompanyId) {
    this.setState({ selectedSubCompany : subcompanyId });
    setTimeout(() => this.onFetchAccessDataAndCharts(true), 300);
  }

  async onSelectArea(area) {
    this.setState({ selectedArea : area });
    localStorage.setItem('dashboardSelectedAreaId', area&&area.id?area.id:0)
    setTimeout(() => this.onFetchAreasOccupancyHourly(), 300);
  }

  onRemoteOpenRequest(smartLock) {
    if (smartLock && smartLock.configuration && smartLock.configuration.remoteOpenEnabled) {
      this.onOpenLockRemotely(smartLock);
    } else {
      this.onOpenLockByGateway(smartLock);
    }
  }

  async onOpenLockRemotely(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockActions.onOpenFullOnlineLockRemote(lock));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenFailed" /></h6>),
        },
      }));
    }
  }

  async onOpenLockByGateway(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewaysActions.getGatewayDetails(lock.gatewayId));
      dispatch(GatewaysActions.setSelectedGateway(gatewayDetailed));
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockOpenModal.open();
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onOpenCameraFeed(cameraId) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CamerasActions.fetchCameras());
      const updatedCamera = await dispatch(CamerasActions.fetchCameraAdditionalData(cameraId));
      if (updatedCamera.liveFeedURL) {
        dispatch(CamerasActions.setSelectedCamera(updatedCamera));
      } else {
        const liveFeedURL = await dispatch(CamerasActions.fetchCameraLiveFeed(cameraId));
        dispatch(CamerasActions.setSelectedCamera({ id: cameraId, ...updatedCamera, ...liveFeedURL }));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showCameraLiveFeed: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorGettingCameraLiveFeed" /></h6>),
        },
      }));
    }
  }

  onSubCompanyClicked(subcompany) {
    const { domains, dispatch } = this.props;
    const domainSelected = _.find(domains, domain => domain.hostName === subcompany.hostname)
    dispatch(UserActions.changeDomain(domainSelected, true));
  }



  render() {
    const { dispatch, subcompanies, floorPlansData, domains, cameras: { selectedCamera }, marketPlace: { bannersContent }, metrics: { data: metricsData, accessoriesChartData: { data: accessoriesChartData }, locksBatteryChart: { data: lockBatteryChartData, lastUpdate: locksBatteryLastUpdate }, accessChartDataCapacityManagement: { users: usersCapacityManagement, lastUpdateCapacityManagement }, accessChartData: { data: chartData, locksChartData, users: dailyActiveUsers, lastUpdate, filters: { startDate, endDate } } }, areas: { data: { content: areasData }, occupancy: { areas: areasOccupancy, loading: isRefreshingAreasOccupancy }, occupancyHourly: { hours: occupancyHourlyData,  lastUpdate: lastUpdateOccupancyHourly, filters: { startTime: startDateCapacityManagement, endTime: endDateCapacityManagement } }}, classes, themeName } = this.props;
    const { showLocksList, selectedBatteryPercentage, isRefreshingBatteryData, isRefreshingOccupancyHourlyData, initialArea, showCameraLiveFeed, showLockLogsDetails, selectedLock, refreshingMetric, isMetabaseEnabled, isRefreshingAccessChartData, isRefreshingCounters, showGuestLogsDetails, selectedGuest } = this.state;
    const textColor = getColorFromThemeName(themeName);
    // Ba logc for UI/UX purpose
    const canReadGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canReadCards = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadLogs = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const canReadCredentials = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const isMarketPlaceEnabled = dispatch(UserActions.isMarketPlaceEnabled());
    const isFakeCloudCredits = dispatch(UserActions.isFakeCloudCredits());
    const hasSharedLocks = dispatch(UtilsActions.hasSharedLocks());
    const isF9000PluginActive = dispatch(UserActions.isF9000AddonActive());
    const isHierarchyTreeEnabled = dispatch(UtilsActions.isHierarchyTreeEnabled());
    const isCapacityManagementActive = dispatch(UtilsActions.isCapacityManagementActive());
    return (
      <div className={classes.container}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 10 }}>
          {isMarketPlaceEnabled && !isFakeCloudCredits ? (
            <LuckeyStoreBanner
              bannerHTMLContent={bannersContent && _.first(bannersContent) && _.first(bannersContent).bannerHtmlContent}
              onPress={() => dispatch(push('/luckeyStore'))}
            />
          ) : null}
          <Grid container spacing={2} className={classes.chartsContainer}>
            {_.map(_.filter(metricsData, data => data && data.type === 'counter'), (metric) => {
              if (metric.name === 'guests' && !canReadGuest) return null;
              if (metric.name === 'smartLocks' && !canReadLocks) return null;
              if (metric.name === 'standardDevices' && !canReadCards) return null;
              if (metric.name === 'smartPhoneRules' && !canReadCredentials) return null;
              if (metric.name === 'pins' && !canReadCards) return null;
              if (metric.name === 'hyperKeys' && (!canReadCards || !isF9000PluginActive)) return null;
              return (
                <Grid item md={4} xs={12} className={classes.chartItem} key={metric.name}>
                  <BaseMetricChart
                    metricType={metric.name}
                    metric={metric}
                    themeName={themeName}
                    isRefreshing={refreshingMetric === metric.name || isRefreshingCounters}
                    onRefreshMetric={metricName => this.onRefreshMetric(metricName)}
                    onMetricClick={metricPath => this.onMetricClick(metricPath)}
                    onShortcutClick={() => this.onShortcutClick(metric.name)}
                  />
                </Grid>
              );
            })}
          </Grid>
          {accessoriesChartData && !_.isEmpty(accessoriesChartData) && canReadLocks ? (
            <div style={{ marginBottom: 10 }}>
              <BaseMetricChart
                metricType="accessoriesStatusSensor"
                textColor={textColor}
                accessoriesChartData={accessoriesChartData}
                lastUpdate={lastUpdate}
                isRefreshing={refreshingMetric === 'accessoriesStatusSensor'}
                onRefreshMetric={() => this.onRefreshMetric('accessoriesStatusSensor')}
                onOpenLockDetails={lockId => this.onOpenLockDetails(lockId)}
              />
            </div>
          ) : null}
          {isHierarchyTreeEnabled && subcompanies && !_.isEmpty(subcompanies) ? (
            <SubcompaniesOrganizationChart
              subcompanies={subcompanies}
              textColor={textColor}
              userDomains={domains}
              onTreeNodeClick={(subcompany) => this.onSubCompanyClicked(subcompany)}
            />
          ) : null}
          <AppBar position="sticky" color="default" style={{ padding: 10,  marginLeft: -20, width: '120%', zIndex: 100 }}>
            <DashboardFilters
              showSubcompanySelector={hasSharedLocks}
              subcompanies={subcompanies}
              startDate={startDate}
              endDate={endDate}
              onResetDateFilters={() => this.onResetDateFilters()}
              onDateRangeSelected={(startDateFilter, endDateFilter) => this.onDateRangeSelected(startDateFilter, endDateFilter)}
              onSelectSubcompany={subcompanyId => this.onSelectSubcompany(subcompanyId)}
            />
          </AppBar>
          {canReadLogs && canReadGuest && isCapacityManagementActive ? (
            <AreasListView
              textColor={textColor}
              users={usersCapacityManagement}
              areas={areasData}
              areasOccupancy={areasOccupancy}
              lastUpdate={lastUpdateCapacityManagement}
              isRefreshingAccessChartData={isRefreshingAccessChartData}
              onShowGuestLogs={user => this.onShowGuestLogs(user)}
              onRefreshMetric={() => this.onFetchAreasOccupancy()}
            />
          ) : null}
          <div style={{ display: 'flex', marginTop: 5 }}>
            {canReadLogs && canReadLocks ? (
              <BaseMetricChart
                metricType="accesses"
                textColor={textColor}
                accessChartData={chartData}
                lastUpdate={lastUpdate}
                fullWidth={lockBatteryChartData.isDataEmpty}
                isRefreshing={isRefreshingAccessChartData}
                onResetDateFilters={() => this.onResetDateFilters()}
                onGoToEventPage={() => this.onSmartLocksEventsClick()}
                onRefreshMetric={() => this.onFetchAccessDataAndCharts(true)}
              />
            ) : null}
            {canReadLocks && !lockBatteryChartData.isDataEmpty ? (
              <BaseMetricChart
                metricType="smartlocksBattery"
                textColor={textColor}
                batteryChartData={lockBatteryChartData}
                isRefreshing={isRefreshingBatteryData}
                onRefreshMetric={() => this.onFetchLocksBatteryChartData(true)}
                onDataClick={batteryPercentage => this.onShowLockBatteryPerLevel(batteryPercentage)}
              />) : null}
          </div>
          {isMetabaseEnabled ? (
            <Card style={{ backgroundColor: textColor, justifyContent: 'center', margin: 5, marginTop: 20, borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => this.onOpenAdvancedAnalytics()}>
              <h4 className={classes.titleText} style={{ color: 'white' || '#3f3f3f' }}><Entity entity="analyticsPortal" /></h4>
              <BarChartIcon style={{ color: 'white', marginLeft: 10  }} />
            </Card>
          ) : null}

          <DailyActiveEntityList
            users={dailyActiveUsers}
            locks={locksChartData && locksChartData.dataLocks ? locksChartData.dataLocks : []}
            textColor={textColor}
            isCapacityManagementActive={isCapacityManagementActive}
            areasOccupancy={areasOccupancy}
            areasData={areasData}
            isRefreshingAreasOccupancy={isRefreshingAreasOccupancy}
            accessesFilterEndDate={endDate}
            lastUpdate={lastUpdate}
            isRefreshingAccessChartData={isRefreshingAccessChartData}
            onShowGuestLogs={user => this.onShowGuestLogs(user)}
            onShowSmartLockLogs={lock => this.onShowSmartLockLogs(lock)}
            onExportActiveUsersList={format => this.onExportActiveUsersList(format)}
            onExportActiveSmartLocksList={format => this.onExportActiveSmartLocksList(format)}
            onRefreshMetric={() => this.onFetchAccessDataAndCharts(true)}
          />
          {canReadLogs && canReadLocks && isCapacityManagementActive ? (
            <BaseMetricChart
              metricType="areasHourlyOccupancy"
              textColor={textColor}
              occupancyHourlyData={occupancyHourlyData}
              lastUpdate={lastUpdateOccupancyHourly}
              fullWidth={true}
              isRefreshing={isRefreshingAccessChartData||isRefreshingOccupancyHourlyData}
              onRefreshMetric={() => this.onFetchAreasOccupancyHourly()}
              areas={areasData}
              initialArea={initialArea}
              onSelectArea={areaId => this.onSelectArea(areaId)}
            />
          ) : null}
          {canReadLogs && canReadLocks && floorPlansData && !_.isEmpty(floorPlansData) ? (
            <FloorPlanDashboardViewer
              locksChartData={locksChartData}
              onOpenSmartLocksEvents={smartLockData => this.onShowSmartLockLogs(smartLockData)}
              onRemoteOpenRequest={smartLock => this.onRemoteOpenRequest(smartLock)}
              onOpenCameraFeed={smartLock => this.onOpenCameraFeed(smartLock.cameraId)}
              onLockClick={lockId => this.onLockClick(lockId)}
              onRefreshFloorPlan={() => {
                dispatch(FloorPlansActions.fetchFloorPlans(0, 100));
                this.onRefreshMetric('smartLocksEvents');
              }}
            />
          ): null}
        </div>
        <OperationalView
          themeName={themeName}
          isVisible={showGuestLogsDetails}
          onClose={() => this.setState({ showGuestLogsDetails: false })}
          style={{ margin: 0, padding: 0, position: 'relative', overflow: 'hidden' }}
          operationaViewStyle={{ zIndex: 10000 }}
          title={<Entity entity="guestDailyEvents" />}
        >
          <GuestDashboardDetails
            guest={selectedGuest}
            onOpenGuestProfile={() => this.onOpenGuestProfile(selectedGuest)}
            onLockClick={(lockId) => this.onOpenLockDetails(lockId)}
          />
        </OperationalView>
        <OperationalView
          themeName={themeName}
          isVisible={showLockLogsDetails}
          onClose={() => this.setState({ showLockLogsDetails: false })}
          operationaViewStyle={{ zIndex: 10000 }}
          style={{ margin: 0, padding: 0, position: 'relative', overflow: 'hidden' }}
          title={<Entity entity="lockDailyEvents" />}
        >
          <LockDashboardDetails
            lockData={selectedLock}
            textColor={textColor}
            onLockClick={(lockId) => this.onOpenLockDetails(lockId)}
          />
        </OperationalView>
        <OperationalView
          themeName={themeName}
          isVisible={showLocksList}
          onClose={() => this.setState({ showLocksList: false })}
          style={{ margin: 0, padding: 0, position: 'relative' }}
          operationaViewStyle={{ zIndex: 10000 }}
          title={<Entity entity="lockDetailsBatteryLevel" data={{ batteryLevel: selectedBatteryPercentage }} />}
        >
          <LockDashboardList
            onLockClick={lockId => this.onOpenLockDetails(lockId)}
          />
        </OperationalView>
        <BasicModal
          ref={(m) => { this.lockOpenModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteOpenView
                onCloseModal={() => {
                  this.lockOpenModal.close();
                  dispatch(LockActions.selectLock({}));
                }}
              />
            </div>
          }
        />
        <Modal
          show={showCameraLiveFeed}
          className="alert-container full-height-modal"
          onHide={() => {
            this.setState({ showCameraLiveFeed: false });
            dispatch(CamerasActions.setSelectedCamera({}));
          }}
        >
          <CameraLiveFeedView
            camera={selectedCamera}
            onCloseModal={() => {
              this.setState({ showCameraLiveFeed: false });
              dispatch(CamerasActions.setSelectedCamera({}));
            }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  metrics: state.metrics,
  viewLoading: state.utils.viewLoading,
  language: state.settings.language,
  themeName: state.settings.items.theme.data.themeName,
  marketPlace: state.cloudCredits.marketPlace,
  subcompanies: state.settings.subcompanies,
  floorPlansData: state.floorPlans.data.content,
  cameras: state.cameras,
  domains: state.user.domains,
  areas: state.areas,
});

export default connect(mapStateToProps)(withStyles(styles)(DashboardView));

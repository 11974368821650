// @ts-nocheck
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CompanyIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/DeleteSweep';
import EditIcon from '@material-ui/icons/Edit';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import CloudIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import StarIcon from '@material-ui/icons/StarBorder';
import RoleIcon from '@material-ui/icons/VerifiedUser';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import UserCheckIconCustom from '../CustomIcons/UserCheckIconCustom.jsx';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Field, change, reduxForm } from 'redux-form';
import * as UserAction from '../../redux/actions/user.actions';
import * as AccessProfilesActions from '../../redux/actions/accessProfiles.actions';
import * as UtilsActions from '../../redux/actions/utils.actions.js'
import Tag from '../../components/Tag.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { BLUE, GREEN, MOBILE_UDID_STATES, PERMISSIONS, PERMISSION_ENTITIES, RED, SUBSCRIPTION_TYPES } from '../../_config/consts.js';
import { hasFormWritePermission } from '../../_config/utils.js';
import TagsSelectorField from '../forms/Fields/TagsSelectorField.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import Permission from '../Permission/Permission.jsx';
import AccessMethodCreateMenu from './AccessMethods/AccessMethodCreateMenu.jsx';
import AccessMethodRow from './AccessMethods/AccessMethodRow.jsx';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import SelectableField from '../forms/Fields/SelectableInput/SelectableField.jsx';
import DeviceRemoveIconCustom from '../CustomIcons/DeviceRemoveIconCustom.jsx';
import SmartPhoneKeyIconCustom from '../CustomIcons/SmartPhoneKeyIconCustom.jsx';

const GuestInfoSection = ({ title, body, bodyTextStyle, onShowInfo, actionsContainer, bodyColor, actionText, onClick, toolTipText, isActionDisabled }) =>
  (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: onShowInfo ? 0 : 10 }}>
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{title}</h4>
        {onShowInfo ? (
          <IconButton onClick={() => onShowInfo()}>
            <InfoIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
          </IconButton>
        ) : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'colum', marginBottom: 15 }}>
        <div>
          <h5 style={{ margin: 0, color: bodyColor, ...bodyTextStyle }}>{body}</h5>
        </div>
        {/* {!isActionDisabled && <h5 style={{ fontWeight: 'bold', margin: 0 }} onClick={() => onClick()} className="link-label">{actionText}</h5>} */}
      </div>
      {!isActionDisabled ? actionsContainer : null}
      {toolTipText && (
        <div style={{ display: 'flex', marginTop: 0, alignItems: 'center' }}>
          <InfoIcon style={{ fontSize: 18, marginRight: 5, color: '#3f3f3f' }} />
          <h5 style={{ margin: 0, marginBottom: 2, fontSize: 15, fontWeight: 200, color: '#3f3f3f' }}><Entity key={toolTipText} entity={toolTipText} /></h5>
        </div>
      )}
    </div>
  );

@reduxForm({ form: 'GuestProfileViewForm' })
@connect(state => ({ companyConfigurations: state.user.companyConfigurations }))
class GuestProfileView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      accessProfilesOptions: [],
    }
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isAccessProfileAddonActive = dispatch(UtilsActions.isAccessProfileAddonActive());
    if (isAccessProfileAddonActive) {
      const profiles = await dispatch(AccessProfilesActions.fetchAccessProfilesOptions({}));
      const formattedProfiles = _.map(profiles, (profile) => {
        return { value: String(profile.id), label: profile.name };
      });
      this.setState({ accessProfilesOptions: formattedProfiles });
    }
  }

  onAccessProfileSelected(profile) {
    const { dispatch } = this.props;
    if (profile && profile.value) {
      dispatch(change('GuestProfileViewForm', 'accessProfileId', profile.value));
    } else {
      dispatch(change('GuestProfileViewForm', 'accessProfileId', null));
    }
    // HERE EDIT
  }

  async fetchProfilesOptions(value) {
    const { dispatch } = this.props;
    if (value && value.length >= 2) {
      const profiles = await dispatch(AccessProfilesActions.fetchAccessProfilesOptions({ name: value }));
      const formattedProfiles = _.map(profiles, (profile) => {
        return { value: String(profile.id), label: profile.name };
      });
      this.setState({ accessProfilesOptions: formattedProfiles });
      return formattedProfiles;
    }
  }

  render() {
    const { dispatch, guest, companyConfigurations, onCreateNewAccessMethod, onLimitDeviceUUIDForUser, onGeneratePasswordRecoveryLink, onAllowAlwaysValid, onDisableGuest, onAccessMethodRowClick, availableTags, onEditTag, onShowUserTagsInfo, onShowDeviceInfo, onDeleteGuest, onNewTag, onResetPassword, onGuestFormSubmit, onEditTagDates, onGuestInputChange, onEnableGuest, onOpenGuestForm, onResetDevice } = this.props;
    const { accessProfilesOptions } = this.state;
    const isCovivioIntegrationEnabled = companyConfigurations && companyConfigurations.covivioConnectorEnabled;
    const cannotEditExternalUser = guest.extId && isCovivioIntegrationEnabled;
    const canEditGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    const canDeleteGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER) && !cannotEditExternalUser;
    const canCreateUserTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER_TAG) && !cannotEditExternalUser;
    const registrationDate = guest.registrationDate ? moment(guest.registrationDate).format('LL') : null;
    const isF9000PluginActive = dispatch(UserAction.isF9000AddonActive());
    const isObjectRegistryPluginActive = dispatch(UserAction.userHasSubscriptions(SUBSCRIPTION_TYPES.REGISTRY));
    const isAccessProfileAddonActive = dispatch(UtilsActions.isAccessProfileAddonActive());
    const mobileUUIDStatusColor = guest && (guest.mobileUuidState === MOBILE_UDID_STATES.ALWAYS_VALID || guest.mobileUuidState === MOBILE_UDID_STATES.SET) ? 'green' : RED;  
    const isDeviceManagementActive = dispatch(UtilsActions.isDeviceManagementActive());
    return (
      <div className="card card-noHover" style={{ backgroundColor: '#F8F9FA', position: 'relative', padding: 15, marginLeft: 15, marginRight: 20, marginTop: 10, marginBottom: 20 }}>
        <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.USER} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <div style={{ position: 'absolute', right: canDeleteGuest ? 70 : 10, top: 12 }}>
            <IconButton style={{ color: '#3f3f3f' }} onClick={() => onOpenGuestForm()}>
              <EditIcon style={{ fontSize: 23 }} />
            </IconButton>
          </div>
        </Permission>
        {canDeleteGuest ? (
          <div style={{ position: 'absolute', right: 10, top: 10 }}>
            <IconButton style={{ color: '#3f3f3f' }} onClick={() => onDeleteGuest(guest)}>
              <DeleteIcon style={{ fontSize: 28 }} />
            </IconButton>
          </div>
        ) : null}
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <h2 style={{ fontSize: 25, marginTop: 0, color: '#3f3f3f', marginBottom: 0, fontWeight: 'bold' }}>{`${guest.firstname} ${guest.lastname}`}</h2>
            {guest && guest.userTag && <div style={{ marginLeft: 10 }}><Tag tag={guest.userTag} /> </div>}
            {guest.extId ? <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('guestSyncFromSource')}><CloudIcon style={{ color: '#4DB6AC', marginTop: 10, marginRight: 5, marginLeft: 0, fontSize: 22 }} /></p> : null}
            {guest.isGuestManager ? <p style={{ margin: 0 }} data-tip={L20NContext.getSync('guestIsManager')}><RoleIcon style={{ color: 'orange', fontSize: 22, marginTop: 10 }} /></p> : null}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {guest.withoutEmail ? <RemoveEmailIconCustom style={{ color: '#3f3f3f', width: 18 }} /> : <MailIcon style={{ color: '#3f3f3f', fontSize: 18, marginTop: 2 }} />}
            <h4 style={{ fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, marginRight: 5, marginBottom: 2, color: '#3f3f3f', fontWeight: 200 }}>{guest.withoutEmail ? <Entity entity="noEmailSet" /> : guest.email}</h4>
            {guest.companyName ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10, marginBottom: 2 }}>
                |
                <CompanyIcon style={{ marginLeft: 10, fontSize: 18, color: '#3f3f3f' }} />
                <h4 style={{ fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, color: '#3f3f3f', fontWeight: 200 }}>{guest.companyName}</h4>
              </div>
            ) : null}
            {registrationDate ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10, marginBottom: 2 }}>
                |
                <CalendarIcon style={{ marginLeft: 10, marginTop: 2, color: '#3f3f3f', fontSize: 18 }} />
                <h4 style={{ fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, color: '#3f3f3f', fontWeight: 200 }}>
                  <Entity key={guest.id} entity="createdAt" data={{ date: registrationDate }} />
                </h4>
            </div>
        ) : null}
          </div>
          <CustomFieldsDisplayView
            containerStyle={{ marginTop: 10, marginBottom: 15 }}
            customFields={guest.customFields}
          />
          {canEditGuest && !cannotEditExternalUser && !guest.withoutEmail ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h5
                onClick={() => onResetPassword()}
                className="link-label"
                style={{ margin: 0, marginTop: 5 }}
              >
                <Entity entity="resetPassword" />
              </h5>
            <h5
              onClick={() => onGeneratePasswordRecoveryLink()}
              className="link-label"
              style={{ margin: 0, marginTop: 5, marginLeft: 15 }}
            >
              <Entity entity="generateRecoverPasswordLink" />
            </h5>
          </div>
          ) : null}
        </div>
        <div>
          <Divider style={{ marginTop: 5 }} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 0 }}>
            <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginTop: 10, marginBottom: 10 }}>{<Entity entity="accessMethods" />}</h4>
            <AccessMethodCreateMenu
              onCreateNewAccessMethod={accessMethodType => onCreateNewAccessMethod(accessMethodType)}
              iconStyle={{ color: GREEN, width: 20 }}
              titleContainerStyle={{ marginTop: -20, marginLeft: 5 }}
              canAddF9000={isF9000PluginActive}
              canAddObject={isObjectRegistryPluginActive}
              canAddSmartphone={!guest.withoutEmail}
            />
          </div>
          {guest.accessMethods && !_.isEmpty(guest.accessMethods) ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 5 }}>
              {_.map(guest.accessMethods, accessMethod => (
                <AccessMethodRow type={accessMethod.type} onClick={() => onAccessMethodRowClick(accessMethod.type)} />
              ))}
            </div>
        ) : null}
        </div>
        <div>
          <Divider style={{ marginTop: 0, marginBottom: 15 }} />
          <GuestInfoSection
            title={<Entity entity="userState" />}
            toolTipText={!guest.enabled && !guest.withoutEmail && 'guestInactiveStatusExplanation'}
            body={guest.enabled || guest.withoutEmail ? <Entity key="userEnabled" entity="userEnabled" /> : <Entity key="userDisabled" entity="userDisabled" />}
            bodyColor={guest.enabled || guest.withoutEmail? 'green' : 'red'}
            bodyTextStyle={{ fontWeight: 'bold' }}
            isActionDisabled={!canEditGuest || guest.isGuestManager || cannotEditExternalUser || guest.withoutEmail}
            actionsContainer={(
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                {!guest.enabled ? <UserCheckIconCustom style={{ width: 18, marginRight: 7, marginBottom: 1, color: BLUE }} /> : <UserDisableIconCustom style={{ width: 18, marginBottom: 1, marginRight: 7, color: BLUE }} />}
                {guest.enabled ? (
                  <h5
                    className="link-label"
                    style={{ textDecoration: 'underline', margin: 0, marginBottom: 2, color: BLUE }}
                    onClick={() => onDisableGuest()}
                  >
                    <Entity key="disableGuest" entity="disableGuest" />
                  </h5>
                ) : (
                  <h5
                    className="link-label"
                    style={{ textDecoration: 'underline', margin: 0, marginBottom: 2, color: BLUE }}
                    onClick={() => onEnableGuest()}
                  >
                    <Entity key="enableGuest" entity="enableGuest" />
                  </h5>
                )}
              </div>
            )}
          />
        </div>
        {guest.id && guest.mobileUuidState && 
          <div>
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <GuestInfoSection
              title={<Entity entity="deviceUUID" />}
              body={<Entity entity="deviceUUIDState" key={guest.mobileUuidState} data={{ state: guest.mobileUuidState }} />}
              bodyColor={mobileUUIDStatusColor}
              bodyTextStyle={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              isActionDisabled={!canEditGuest}
              onShowInfo={() => onShowDeviceInfo()}
              actionsContainer={(
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                    {guest.mobileUuidState === MOBILE_UDID_STATES.SET ? (
                      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
                        <DeviceRemoveIconCustom style={{ color: BLUE, width: 18, marginRight: 7, marginBottom: 5 }} />
                        <h5
                          style={{ textDecoration: 'underline', margin: 0, color: BLUE }}
                          onClick={() => onResetDevice()}
                          className="link-label"
                        >
                          <Entity entity="resetDevice" />
                        </h5>
                      </div>
                    ) : null}
                    {isDeviceManagementActive && (guest.mobileUuidState === MOBILE_UDID_STATES.SET || guest.mobileUuidState === MOBILE_UDID_STATES.RESET || guest.mobileUuidState === MOBILE_UDID_STATES.NEVER_SET) ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SmartPhoneKeyIconCustom style={{ color: BLUE, width: 18, marginRight: 7, marginBottom: 5 }} />
                        <h5
                          style={{ textDecoration: 'underline', margin: 0, color: BLUE }}
                          onClick={() => onAllowAlwaysValid()}
                          className="link-label"
                        >
                          <Entity entity="allowAlwaysValidDevice" />
                        </h5>
                      </div>
                    ) : null}
                  {guest.mobileUuidState === MOBILE_UDID_STATES.ALWAYS_VALID ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <SmartPhoneKeyIconCustom style={{ color: BLUE, width: 18, marginRight: 7, marginBottom: 5 }} />
                      <h5
                        style={{ textDecoration: 'underline', margin: 0, color: BLUE }}
                        onClick={() => onLimitDeviceUUIDForUser()}
                        className="link-label"
                      >
                        <Entity entity="limitDeviceUUIDToOneForUser" />
                      </h5>
                    </div>
                  ) : null}
                </div>
            </div>
            )}
          />
          </div>
        }
        {isAccessProfileAddonActive ? (
          <div>
          <Divider style={{ marginTop: 5 }} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="accessProfile" />}</h4>
              <IconButton onClick={() => onShowUserTagsInfo()}>
                <InfoIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
              </IconButton>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Field
                name="accessProfileId"
                component={SelectableField}
                options={accessProfilesOptions}
                placeholder={<Entity entity="insertRole" />}
                containerstyle={{ marginBottom: 15, marginTop: 5, width: '100%' }}
                onSelect={profile => this.onAccessProfileSelected(profile)}
                defaultOptions={accessProfilesOptions}
                onInputChange={async(name) => await this.fetchProfilesOptions(name)}
                loadOptions={async(name) => await this.fetchProfilesOptions(name)}
              />
              <MDButton
                disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, true) || cannotEditExternalUser}
                title={<Entity entity="save" />}
                style={{ height: 35, borderRadius: 5 }}
                containerStyle={{ margin: 10, marginTop: 0 }}
                onClick={() => onGuestFormSubmit()}
              />
            </div>
          </div>
        ): null}
        <Divider style={{ marginTop: 5, marginBottom: 10 }} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
          <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="guestSpecialTag" />}</h4>
          <IconButton onClick={() => onShowUserTagsInfo()}>
            <InfoIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
          </IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Entity
            componentClass={Field}
            name="tags"
            multi
            componentAttribute="placeholder"
            entity="insertGuestTags"
            component={TagsSelectorField}
            tagType="GUEST"
            onNewTag={canCreateUserTags ? onNewTag : null}
            onEditTag={onEditTag}
            isDisabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, true) || cannotEditExternalUser}
            onEditTagDates={onEditTagDates}
            tags={availableTags}
            onInputChange={onGuestInputChange}
            containerStyle={{ width: '100%', margin: 0 }}
          />
          <MDButton
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, true) || cannotEditExternalUser}
            title={<Entity entity="save" />}
            style={{ height: 35, borderRadius: 5 }}
            containerStyle={{ margin: 10, marginTop: 10 }}
            onClick={() => onGuestFormSubmit()}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
          <StarIcon style={{ fontSize: 17, color: '#3f3f3f', marginRight: 5 }} />
          <h5 style={{ color: '#3f3f3f', fontSize: 15, fontWeight: '200', margin: 0 }}><Entity entity="timedTagSuggestion" /></h5>
        </div>
        <ReactTooltip delayShow={200} effect="float" />
      </div>
    );
  }
} 

export default GuestProfileView;

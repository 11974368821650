// @ts-nocheck
import { CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Edit } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { BLUE } from '../../_config/consts';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import * as CamerasActions from '../../redux/actions/cameras.actions';
import Tag from '../Tag.jsx';
import { connect } from 'react-redux';

const styles = theme => ({
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    position: 'relative',
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  editIconContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

@connect(state => ({ }))
class CameraRow extends React.Component {
  async componentDidMount() {
    const { dispatch, camera } = this.props;
    await dispatch(CamerasActions.fetchCameraSmartLocks(camera.id));
    dispatch(CamerasActions.fetchCameraAdditionalData(camera.id));
  }
  
  render() {
    const { camera, classes, onRowClick, onEditCamera } = this.props;
    return (
      <Card className={`card card-rounded ${classes.rowContainer}`} onClick={() => onRowClick()}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CameraIconCustom style={{ width: 25, color: '#3f3f3f' }} />
            <h4 className={classes.rowTitle} style={{ marginLeft: 10 }}>{camera.name}</h4>
          </div>
          {camera.smartLocks && !_.isEmpty(camera.smartLocks) ? (
            <div className="tags-container" style={{ margin: 0, marginTop: 10 }}>
              {_.map(camera.smartLocks, lock => (
                <Tag
                  textStyle={{ fontSize: 12 }}
                  style={{ paddingTop: 4, paddingBottom: 4 }}
                  tag={{
                    color: '#ba68c8',
                    name: lock && lock.name ? lock.name : '',
                    type: 'lock',
                  }}
                />
                )
                )
              }
            </div>
          ) : null}
          {camera.isFakeCamera ? (
            <img alt="camera-preview" src={'/imgs/common/eagleEye/video_static.png'} height={250} style={{ marginTop: 20 }} />
          ) : (
            <>
            {camera.previewURL && _.size(camera.previewURL) ? (
              <img alt="camera-preview" src={camera.previewURL} height={250} style={{ marginTop: 20 }} />
              ) : (
                <div style={{ height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {!camera.previewURL ? <CircularProgress /> : null}
                  {camera.previewURL === '' ? <h4>{'NO PREVIEW'}</h4> : null}
                </div>
              )}
            </>
          )}
        </div>
        <IconButton className={classes.editIconContainer} onClick={(e) => { e.stopPropagation(); onEditCamera(); }}>
          <Edit style={{ fontSize: 20 }} />
        </IconButton>
    
      </Card>
    );
  }
}
export default withStyles(styles)(CameraRow);

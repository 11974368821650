import _ from 'lodash';
import qs from 'qs';
import {
  SAVE_CAMERAS,
  SAVE_ALL_CAMERAS,
  UPDATE_CAMERA,
  SAVE_CAMERAS_PAGINATION_DATA,
  SET_SELECTED_CAMERA,
  RESET_CAMERA_DATA,
  SET_CAMERA_FILTER,
  RESET_CAMERA_FILTERS,
  SAVE_SMART_LOCKS_WITH_CAMERAS,
} from './actionTypes/cameras';
import * as CamerasAPI from '../../_config/camerasAPI';
import * as LockActions from './lock.actions';
import * as MetricsActions from './metrics.actions';
import { CAMERAS_PAGE_SIZE } from '../../_config/consts';
import { isDemoSystem, removeAccessTokenFromURL } from '../../_config/utils';

export function saveCameras(cameras) {
  return {
    type: SAVE_CAMERAS,
    cameras,
  };
}

export function saveAllCameras(cameras) {
  return {
    type: SAVE_ALL_CAMERAS,
    cameras,
  };
}

export function saveCamerasPagination(pagination) {
  return {
    type: SAVE_CAMERAS_PAGINATION_DATA,
    pagination,
  };
}
export function updateCamera(camera) {
  return {
    type: UPDATE_CAMERA,
    camera,
  };
}

export function setSelectedCamera(camera) {
  return {
    type: SET_SELECTED_CAMERA,
    camera,
  };
}

export function resetCamerasData() {
  return {
    type: RESET_CAMERA_DATA,
  };
}

export function setCameraFilter(field, value) {
  return {
    type: SET_CAMERA_FILTER,
    field,
    value,
  };
}

export function resetCameraFilters() {
  return {
    type: RESET_CAMERA_FILTERS,
  };
}


export function saveLocksWithCameras(locks) {
  return {
    type: SAVE_SMART_LOCKS_WITH_CAMERAS,
    locks,
  };
}


export function fetchCameras(page = 0) {
  return async (dispatch, getState) => {
    try {
      const cameras = getState().cameras.data.content;
      const allCameras = getState().cameras.allCameras;
      let stateCameras = allCameras;
      if (_.isEmpty(allCameras)) {
        const response = await CamerasAPI.fetchCameras({}, 'EAGLE_EYE');
        if (response.data) {
          stateCameras = response.data;
          if (isDemoSystem()) {
            stateCameras = [{..._.first(stateCameras), isFakeCamera: true, name: 'Office 101' }];
          }
          dispatch(saveAllCameras(stateCameras));
        }
      }
      if (stateCameras) {
        const totalPages = Math.ceil(_.size(stateCameras) / CAMERAS_PAGE_SIZE);
        const currentPage = page;
        const pagination = {
          totalPages,
          currentPage,
        };
        let camerasToSave = [...cameras, ..._.slice(stateCameras, _.size(cameras), _.size(cameras) + CAMERAS_PAGE_SIZE + 1)];
        if (isDemoSystem()) {
          camerasToSave = [{..._.first(camerasToSave), isFakeCamera: true, name: 'Office 101' }];
        }
        dispatch(saveCameras(camerasToSave));
        dispatch(saveCamerasPagination(pagination));
        return stateCameras;
      }
      throw new Error();
    } catch (error) {
      if (error && error.response.data && error.response.data.code === 10805) {
        throw new Error('ACCOUNT_NOT_LINKED');
      }
      throw new Error();
    }
  };
}


export function fetchCameraSmartLocks(cameraId) {
  return async (dispatch, getState) => {
    const cameras = getState().cameras.data.content;
    const camera = _.find(cameras, stateCamera => stateCamera.id === cameraId);
    try {
      const customFields = getState().customFields.locks.content;
      const locksWithCameras = getState().cameras.locksWithCameras;
      const customAttributeEagleEye = _.find(customFields, field => field.type === 'INTEGRATION_ID' && field.name.toLowerCase().includes('eagle'));
      const eagleEyeKeyValue = customAttributeEagleEye.keyValue;
      const cameraLocks = _.filter(locksWithCameras, lock => lock.customAttributes[eagleEyeKeyValue] === camera.id);
      if (cameraLocks && !_.isEmpty(cameraLocks)) {
        const cameraToUpdate = { ...camera, smartLocks: cameraLocks };
        dispatch(updateCamera(cameraToUpdate));
      }
    } catch (error) {
      const cameraToUpdate = { ...camera, smartLocks: [] };
      dispatch(updateCamera(cameraToUpdate));
    }
  };
}

export function fetchLocksWithCameras() {
  return async (dispatch, getState) => {
    try {
      const customFields = getState().customFields.locks.content;
      const customAttributesTerm = '*';
      const customAttributeEagleEye = _.find(customFields, field => field.type === 'INTEGRATION_ID' && field.name.toLowerCase().includes('eagle'));
      const customAttributesFields = [customAttributeEagleEye.keyValue];
      dispatch(LockActions.setFilter('customAttributesTerm', customAttributesTerm));
      dispatch(LockActions.setFilter('customAttributesFields', customAttributesFields));
      const smartLocks = await dispatch(LockActions.fetchLocks());
      dispatch(LockActions.resetLocksFilters());
      const eagleEyeKeyValue = customAttributeEagleEye.keyValue;
      const cameraLocks = _.filter(smartLocks, lock => lock.customAttributes[eagleEyeKeyValue]);
      dispatch(saveLocksWithCameras(cameraLocks));
    } catch (error) {
    }
  };
}

export function fetchCameraLiveFeedsURL(cameraId) {
  return async (dispatch, getState) => {
    let previewURL;
    let liveFeedURL;
    try {
      const response = await CamerasAPI.fetchCameraLiveFeed(cameraId, 'EAGLE_EYE');
      if (response.data) {
        const cameraPreviewData = _.find(response.data, data => data.type === 'preview' && data.mediaType === 'video');
        if (cameraPreviewData && cameraPreviewData.multipartUrl) {
          previewURL = cameraPreviewData.multipartUrl;
        }
        const cameraLiveFeedData = _.find(response.data, data => data.type === 'main' && data.mediaType === 'video');
        if (cameraLiveFeedData && cameraLiveFeedData.hlsUrl) {
          liveFeedURL = cameraLiveFeedData.hlsUrl;
        }
        // SET BASE URL FOR EAGLE EYE API
        CamerasAPI.setEagleEyeAccessToken(cameraLiveFeedData.accessToken);
        return {
          previewURL,
          liveFeedURL,
          accessToken: cameraLiveFeedData.accessToken,
          baseUrl: cameraLiveFeedData.baseUrl,
        }
      }
      return {
        previewURL,
        liveFeedURL,
      };
    } catch (error) {
      return {
        previewURL,
        liveFeedURL,
      };
    }
  };
}


export function setEagleEyeMediaCookieSession() {
  return async (dispatch, getState) => {
    try {
      const { baseUrl } = await dispatch(getEagleEyeAccessTokenAndBaseUrl());
      const setCookieResponse = await CamerasAPI.fetchMediaCookieUrl(baseUrl);
      await CamerasAPI.setMediaCookie(setCookieResponse.data.url);
      return true;
    } catch (error) {
      throw error;
    }
  }
}

export function fetchCameraAdditionalData(cameraId) {
  return async (dispatch, getState) => {
    try {
      const cameras = getState().cameras.data.content;
      let cameraToUpdate = {};
      if (!_.isEmpty(cameras)) {
        const camera = _.find(cameras, stateCamera => stateCamera.id === cameraId);
        try {
          const { previewURL, liveFeedURL, accessToken } = await dispatch(fetchCameraLiveFeedsURL(camera.id));
          cameraToUpdate = { ...camera, previewURL, liveFeedURL, accessToken };
        } catch (error) {
          cameraToUpdate = { ...camera, previewURL: '', liveFeedURL: '' };
        }
        dispatch(updateCamera(cameraToUpdate));
        return cameraToUpdate;
      }
      return {};
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function getEagleEyeAccessTokenAndBaseUrl() {
  return async (dispatch, getState) => {
    const response = await CamerasAPI.fetchCameras({}, 'EAGLE_EYE');
    if (response.data && !_.isEmpty(response.data)) {
      const cameras = response.data;
      const selectedCamera = _.first(cameras);
      const { accessToken, baseUrl } = await dispatch(fetchCameraLiveFeedsURL(selectedCamera.id));
      CamerasAPI.setEagleEyeAccessToken(accessToken);
      return { accessToken, baseUrl };
    }
  }
}

export function fetchCameraMedias(cameraId, fromDate, toDate) {
  return async (dispatch, getState) => {
    const filters = {
      fromDate,
      toDate,
    };
    try {
      const response = await CamerasAPI.fetchCameraMedia(cameraId, 'EAGLE_EYE', filters);
      if (response.data && response.data) {
        const cameraMedias = _.map(response.data, media => ({
          ...media,
          mp4Url: removeAccessTokenFromURL(media.mp4Url)
        }
        ));
        return cameraMedias;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchCameraLiveFeed(cameraId) {
  return async (dispatch, getState) => {
    try {
      const response = await CamerasAPI.fetchCameraLiveFeed(cameraId, 'EAGLE_EYE');
      if (response.data) {
        const cameraFeedData = _.first(response.data);
        if (cameraFeedData && cameraFeedData.mediaType === 'video' && cameraFeedData.type === 'main' && cameraFeedData.hlsUrl) {
          return cameraFeedData.hlsUrl;
        }
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchCameraPreview(cameraId) {
  return async (dispatch, getState) => {
    try {
      const response = await CamerasAPI.fetchCameraLiveFeed(cameraId, 'EAGLE_EYE');
      if (response.data) {
        const cameraFeedData = _.find(response.data, data => data.type === 'preview');
        if (cameraFeedData && cameraFeedData.mediaType === 'video' && cameraFeedData.type === 'preview' && cameraFeedData.multipartUrl) {
          return cameraFeedData.multipartUrl;
        }
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function lockHasCameraAssociatedCheck(lockId) {
  return (dispatch, getState) => {
    const locksWithCameras = getState().cameras.locksWithCameras;
    return _.find(locksWithCameras, lockWithCamera => lockWithCamera.id === lockId);
  };
}

export function findCameraIdFromAssociatedLock(lockId) {
  return (dispatch, getState) => {
    const customFields = getState().customFields.locks.content;
    const locksWithCameras = getState().cameras.locksWithCameras;
    const customAttributeEagleEye = _.find(customFields, field => field.type === 'INTEGRATION_ID' && field.name.toLowerCase().includes('eagle'));
    const eagleEyeKeyValue = customAttributeEagleEye.keyValue;
    const lock = _.find(locksWithCameras, lockWithCamera => lockWithCamera.id === lockId);
    if (lock && lock.customAttributes) {
      return lock.customAttributes[eagleEyeKeyValue];
    }
    return null;
  };
}

// @ts-nocheck
import { CircularProgress, createTheme, withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import React from 'react';
import MDButton from '../../components/MDButton/MDButton.jsx';
import * as CamerasActions from '../../redux/actions/cameras.actions';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import CameraRow from './CameraRow.jsx';
import { connect } from 'react-redux';

const styles = theme => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
});


const EmptyCameraView = ({ showLinkAccountButton, onLinkEagleEyeAccount }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
    <CameraIconCustom style={{ color: '#bdbdbd', width: 50, alignSelf: 'center' }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 0 }}><Entity entity="noCameraFoundTitle" /></h3>
    <h4 style={{ color: '#bdbdbd', fontWeight: 'normal', lineHeight: '1.3em', textAlign: 'center', marginTop: 0 }}>
      <Entity key={showLinkAccountButton} entity={showLinkAccountButton ? 'noCameraFoundDescriptionNoAccount' : 'noCameraFoundDescription'} />
      </h4>
    {showLinkAccountButton ? (
      <MDButton
        title={<Entity entity="linkYourAccount" />}
        onClick={() => onLinkEagleEyeAccount()}
      />
    ) : null}
  </div>
);

@connect(state => ({ }))
class CamerasGridView extends React.Component {
  onAppendVisibleCameras(page) {
    const { dispatch } = this.props;
    dispatch(CamerasActions.fetchCameras(page));
  }

  render() {
    const { camerasData, pagination, onRowClick, onEditCamera, viewLoading, classes, showLinkAccountButton, onLinkEagleEyeAccount } = this.props;
    return (
      <div className="list-view details-section" style={{ top: 140, paddingBottom: 140, width: '87%' }}>
        {viewLoading ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
            <CameraIconCustom style={{ color: '#bdbdbd', width: 40, alignSelf: 'center' }} />
            <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}><Entity entity="loadingCameras" /></h3>
            <CircularProgress />
          </div>
        ) : (
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => this.onAppendVisibleCameras(pagination.currentPage + 1)}
            hasMore={pagination.currentPage + 1 < pagination.totalPages}
            useWindow={false}
            loader={
              <div style={{ marginTop: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
                <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingCameras" /></h4>
                <CircularProgress className={classes.circularProgress} />
              </div>
            }
          >
            <div className={classes.gridContainer}>
              {!_.isEmpty(camerasData) && !viewLoading ?
                _.map(camerasData, camera =>
                  <CameraRow
                    key={camera.id}
                    camera={camera}
                    onRowClick={() => onRowClick(camera)}
                    onEditCamera={() => onEditCamera(camera)}
                  />)
                  : (
                    <EmptyCameraView showLinkAccountButton={showLinkAccountButton} onLinkEagleEyeAccount={() => onLinkEagleEyeAccount()} />
                  )
                }
            </div>
          </InfiniteScroll>
        )}
      </div>
    );
  }

}
export default withStyles(styles)(CamerasGridView);

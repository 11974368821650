import _ from 'lodash';
import moment from 'moment';
import {
  SAVE_INVITATIONS,
  APPEND_INVITATIONS,
  SELECT_INVITATION,
  SET_INVITATIONS_PAGINATION_DATA,
  SET_INVITATION_OPERATIONAL_MODE,
  SET_INVITATION_FILTER,
  RESET_INVITATIONS_FILTERS,
  RESET_INVITATIONS_DATA,
  UPDATE_INVITATION,
  SAVE_INVITATION,
  SAVE_INVITATION_REDEEM_EVENTS_DATA,
  APPEND_INVITATION_REDEEM_EVENTS_DATA,
  SAVE_INVITATION_REDEEM_EVENTS_PAGINATION,
  RESET_INVITATION_REDEEM_EVENTS_DATA
} from '../actions/actionTypes/invitations';
import * as InvitationsAPI from '../../_config/invitationsAPI';
import { saveDataToLocalStorage } from '../../_config/utils';
import * as formatter from '../../_config/formatter';

export function saveInvitations(invitations) {
  return {
    type: SAVE_INVITATIONS,
    invitations,
  };
}

export function saveInvitation(invitation) {
  return {
    type: SAVE_INVITATION,
    invitation,
  };
}

export function appendInvitations(invitations) {
  return {
    type: APPEND_INVITATIONS,
    invitations,
  };
}

export function saveInvitationRedeemEvents(events) {
  return {
    type: SAVE_INVITATION_REDEEM_EVENTS_DATA,
    events,
  };
}

export function appendInvitationRedeemEventsInState(events) {
  return {
    type: APPEND_INVITATION_REDEEM_EVENTS_DATA,
    events,
  };
}

export function saveInvitationRedeemEventsPagination(pagination) {
  return {
    type: SAVE_INVITATION_REDEEM_EVENTS_PAGINATION,
    pagination,
  };
}

export function resetInvitationRedeemEvents() {
  return {
    type: RESET_INVITATION_REDEEM_EVENTS_DATA,
  };
}

export function setInvitationsPaginationData(pagination) {
  return {
    type: SET_INVITATIONS_PAGINATION_DATA,
    pagination,
  };
}

export function setSelectedInvitation(invitation) {
  return {
    type: SELECT_INVITATION,
    invitation,
  };
}

export function updateInvitation(invitation) {
  return {
    type: UPDATE_INVITATION,
    invitation,
  };
}

export function setInvitationsOperationalMode(operationalModeActive) {
  return {
    type: SET_INVITATION_OPERATIONAL_MODE,
    value: operationalModeActive,
  };
}

export function setInvitationsFilter(field, value) {
  return {
    type: SET_INVITATION_FILTER,
    field,
    value,
  };
}

export function resetInvitationFilter() {
  return { type: RESET_INVITATIONS_FILTERS };
}

export function resetInvitationsData() {
  return { type: RESET_INVITATIONS_DATA };
}

export function fetchInvitationDevicesNumber(invitationId, page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const response = await InvitationsAPI.fetchInvitationDevices(invitationId, { page, pageSize });
      if (response.data) {
        return response.data.totalElements;
      }
    } catch (error) {
    }
  }
}

export function fetchInvitations(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().invitations.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await InvitationsAPI.fetchInvitations(params);
      if (response.data && response.data.content) {
        const formattedInvitations = [];
        for (let invitation of response.data.content) {
          try {
            const numberOfRedeems = await dispatch(fetchInvitationDevicesNumber(invitation.id));
            formattedInvitations.push({
              ...formatter.formatInputData(formatter.INVITATION, invitation),
              numberOfRedeems,
            });
          } catch (error) {
            formattedInvitations.push({
              ...formatter.formatInputData(formatter.INVITATION, invitation),
            });
          }
        }
        dispatch(saveInvitations(formattedInvitations));
        dispatch(setInvitationsPaginationData(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAndAppendInvitations(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().invitations.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await InvitationsAPI.fetchInvitations(params);
      if (response.data && response.data.content) {
        const formattedInvitations = [];
        for (let invitation of response.data.content) {
          try {
            const numberOfRedeems = await dispatch(fetchInvitationDevicesNumber(invitation.id));
            formattedInvitations.push({
              ...formatter.formatInputData(formatter.INVITATION, invitation),
              numberOfRedeems,
            });
          } catch (error) {
            formattedInvitations.push({
              ...formatter.formatInputData(formatter.INVITATION, invitation),
            });
          }
        }
        dispatch(appendInvitations(formattedInvitations));
        dispatch(setInvitationsPaginationData(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setInvitationsViewMode(viewMode) {
  saveDataToLocalStorage('invitationsViewMode', viewMode);
}


export function createNewInvitation(invitationDTO) {
  return async (dispatch, getState) => {
    try {
      const formattedInvitation = formatter.formatOutputData(formatter.INVITATION, invitationDTO);
      const response = await InvitationsAPI.createInvitation(formattedInvitation);
      if (response.data && response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchInvitationRedeemEvents(invitationId, page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const response = await InvitationsAPI.fetchInvitationDevices(invitationId, { page, pageSize });
      if (response.data && response.data.content) {
        const events = response.data.content;
        const pagination = _.omit(response.data, 'content');
        dispatch(saveInvitationRedeemEvents(events));
        dispatch(saveInvitationRedeemEventsPagination(pagination));
        return {
          events,
          pagination,
        };
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendInvitationRedeemEvents(invitationId, page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const response = await InvitationsAPI.fetchInvitationDevices(invitationId, { page, pageSize });
      if (response.data && response.data.content) {
        const events = response.data.content;
        const pagination = _.omit(response.data, 'content');
        dispatch(appendInvitationRedeemEventsInState(events));
        dispatch(saveInvitationRedeemEventsPagination(pagination));
        return {
          events,
          pagination,
        };
      }
    } catch (error) {
      throw error;
    }
  };
}

export function deleteInvitation(invitationId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await InvitationsAPI.deleteInvitation(invitationId);
      if (deleteResponse.data) {
        return deleteResponse;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

// @ts-nocheck
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import * as XLSX from 'xlsx';
import { CARD_TYPES, EXPORT_FORMATS } from '../../_config/consts';
import * as EventsAPI from '../../_config/eventsAPI';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import * as AccessoriesActions from './accessories.actions';
import {
  RESET_ACCESS_CHART_FILTER,
  RESET_METRICS,
  SAVE_ACCESSORIES_CHART_DATA,
  SAVE_ACCESS_CHART_DATA,
  SAVE_ACCESS_CHART_DATA_CAPACITY_MANAGEMENT,
  SAVE_ACCESS_CHART_FILTER,
  SAVE_ACCESS_CHART_LOCKS,
  SAVE_ACCESS_CHART_USERS,
  SAVE_ACCESS_CHART_USERS_CAPACITY_MANAGEMENT,
  SAVE_LOCKS_BATTERY_CHART_DATA,
  SAVE_METRIC,
  SAVE_BOOKEY_ANALYTICS_CARDS,
  SAVE_BOOKEY_ANALYTICS_RESOURCES,
  SAVE_BOOKEY_ANALYTICS_USERS,
  SAVE_BOOKEY_ANALYTICS_RESERVATIONS,
  SAVE_BOOKEY_USERS,
  FETCH_BOOKEY_USERS,
  CANCEL_FETCH_BOOKEY_USERS,
  RESET_BOOKEY_USERS,
  SET_BOOKEY_USERS_LOADING,
} from './actionTypes/metrics';
import * as ModalActions from './modal.actions';

export function saveMetric(metricName, metricValue) {
  return {
    type: SAVE_METRIC,
    metricName,
    metricValue,
  };
}

export function saveAccessChartData(chartData) {
  return {
    type: SAVE_ACCESS_CHART_DATA,
    chartData,
    lastUpdate: moment().valueOf(),
  };
}

export function saveAccessChartDataCapacityManagement(chartData) {
  return {
    type: SAVE_ACCESS_CHART_DATA_CAPACITY_MANAGEMENT,
    chartData,
    lastUpdate: moment().valueOf(),
  };
}

export function resetAccessChartFilters() {
  return { type: RESET_ACCESS_CHART_FILTER };
}

export function saveAccessChartUsers(users) {
  return {
    type: SAVE_ACCESS_CHART_USERS,
    users,
  };
}

export function saveAccessChartUsersCapacityManagement(users) {
  return {
    type: SAVE_ACCESS_CHART_USERS_CAPACITY_MANAGEMENT,
    users,
  };
}

export function saveAccessChartFilter(field, value) {
  return {
    type: SAVE_ACCESS_CHART_FILTER,
    field,
    value,
  };
}

export function saveAccessChartLocks(locks) {
  return {
    type: SAVE_ACCESS_CHART_LOCKS,
    locks,
  };
}
export function saveAccessoriesChartData(data) {
  return {
    type: SAVE_ACCESSORIES_CHART_DATA,
    data,
  };
}

export function saveSmartLocksBatteryChartData(batteryChartData) {
  return {
    type: SAVE_LOCKS_BATTERY_CHART_DATA,
    batteryChartData,
    lastUpdate: moment().valueOf(),
  };
}

export function resetMetricsData() {
  return { type: RESET_METRICS };
}

export function saveBookeyAnalyticsCards(data) {
  return {
    type: SAVE_BOOKEY_ANALYTICS_CARDS,
    data,
  };
}
export function saveBookeyAnalyticsResources(data) {
  return {
    type: SAVE_BOOKEY_ANALYTICS_RESOURCES,
    data,
  };
}
export function saveBookeyAnalyticsUsers(data) {
  return {
    type: SAVE_BOOKEY_ANALYTICS_USERS,
    data,
  };
}
export function saveBookeyAnalyticsReservations(data) {
  return {
    type: SAVE_BOOKEY_ANALYTICS_RESERVATIONS,
    data,
  };
}

export function resetBookeyUsers() {
  return {
    type: RESET_BOOKEY_USERS,
  };
}

export function saveBookeyUsers(users) {
  return {
    type: SAVE_BOOKEY_USERS,
    users
  };
}

export function cancelFetchBookeyUsers() {
  return {
    type: CANCEL_FETCH_BOOKEY_USERS,
  };
}

export function fetchBookeyUsers(userIds) {
  return {
    type: FETCH_BOOKEY_USERS,
    userIds,
  };
}

export function setBookeyUsersLoading(loading) {
  return {
    type: SET_BOOKEY_USERS_LOADING,
    loading,
  };
}




export function fetchCustomersMetric(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        roleIds: [5],
      };
      const response = await RestService.fetchUsersMetrics(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        dispatch(saveMetric('guests', { name: 'guests', count: totalElements, type: 'counter' }));
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchLocksMetric(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const response = await RestService.fetchLocksMetrics(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        dispatch(saveMetric('smartLocks', { name: 'smartLocks', count: totalElements, type: 'counter' }));
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchStandardDevicesMetric(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        types: [
          CARD_TYPES.GENERIC_CARD,
          CARD_TYPES.GENERIC_CARD_DESFIRE,
          CARD_TYPES.ISEO_CARD,
          CARD_TYPES.ISEO_CARD_DESFIRE,
        ],
      };
      const response = await RestService.fetchStandardDevicesMetric(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        dispatch(saveMetric('standardDevices', { name: 'standardDevices', count: totalElements, type: 'counter' }));
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchPinsMetric(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        types: [
          CARD_TYPES.ISEO_PIN,
        ],
      };
      const response = await RestService.fetchStandardDevicesMetric(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        dispatch(saveMetric('pins', { name: 'pins', count: totalElements, type: 'counter' }));
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchF9000Metric(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        type: 'F9000',
      };
      const response = await RestService.fetchStandardDevicesMetric(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        dispatch(saveMetric('hyperKeys', { name: 'hyperKeys', count: totalElements, type: 'counter' }));
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchSmartphoneRulesMetric(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const response = await RestService.fetchCredentialRules(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        dispatch(saveMetric('smartPhoneRules', { name: 'smartPhoneRules', count: totalElements, type: 'counter' }));
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchMetricByName(metricName) {
  return async (dispatch, getState) => {
    switch (metricName) {
      case 'guests': return dispatch(fetchCustomersMetric());
      case 'smartLocks': return dispatch(fetchLocksMetric());
      case 'standardDevices': return dispatch(fetchStandardDevicesMetric());
      case 'smartLocksEvents': return dispatch(fetchAccessEvents());
      case 'smartPhoneRules': return dispatch(fetchSmartphoneRulesMetric());
      case 'accessoriesStatusSensor': return dispatch(fetchAccessoriesMetric());
      case 'hyperKeys': return dispatch(fetchF9000Metric());
      default: return null;
    }
  };
}

export function fetchLastAdminEvents(page = 0, pageSize = 5) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const response = await EventsAPI.fetchAdminEvents(params);
      if (response.data && response.data.content) {
        dispatch(saveMetric('adminEvents', { type: 'logs', name: 'adminEvents', data: response.data.content, pagination: _.omit(response.data, 'content') }));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAccessEvents(fromDate = null, toDate = null, page = 0, pageSize = 100000, forCapacityManagement = false, areas = []) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        eventTypes: ['JAGO_3', 'JAGO_5', 'JAGO_7', 'JAGO_0_PASSAGE_MODE_ON'],
        fromDate: fromDate || moment().startOf('day').valueOf(),
        toDate: toDate || moment().endOf('day').valueOf(),
      };
      const response = await EventsAPI.fetchSmartLockEvents(params);
      if (response.data && response.data.content) {
        let analyticsData = response.data.content;
        try {
          const params2 = {
            page,
            pageSize,
            eventType: 'JAGO_MOBILE_0',
            eventOutcomes: ['JAGO_MOBILE_0_0'],
            fromDate: fromDate || moment().startOf('day').valueOf(),
            toDate: toDate || moment().endOf('day').valueOf(),
          };
          const offlineEventaResponse = await EventsAPI.fetchSmartLockEvents(params2);
          if (offlineEventaResponse && offlineEventaResponse.data.content) {
            analyticsData = [...analyticsData, ...offlineEventaResponse.data.content];
            analyticsData = _.orderBy(analyticsData, event => event.timestamp, 'desc');
          }
        } catch (error) {
        }
        try {
          const params2 = {
            page,
            pageSize,
            eventTypes: ['JAGO_11', 'JAGO_9'],
            eventOutcomes: ['JAGO_GATEWAY_0_0'],
            fromDate: fromDate || moment().startOf('day').valueOf(),
            toDate: toDate || moment().endOf('day').valueOf(),
          };
          const remoteOpenHGResponse = await EventsAPI.fetchSmartLockEvents(params2);
          if (remoteOpenHGResponse && remoteOpenHGResponse.data.content) {
            analyticsData = [...analyticsData, ...remoteOpenHGResponse.data.content];
            analyticsData = _.orderBy(analyticsData, event => event.timestamp, 'desc');
          }
        } catch (error) {
        }
        try {
          const params2 = {
            page,
            pageSize,
            eventType: 'JAGO_0',
            eventOutcomes: ['JAGO_MOBILE_0_0', 'VEGA_MOBILE_1_0', 'VEGA_RFID_1_0', 'VEGA_F9000_1_0'],
            fromDate: fromDate || moment().startOf('day').valueOf(),
            toDate: toDate || moment().endOf('day').valueOf(),
          };
          const offlineEventaResponse = await EventsAPI.fetchSmartLockEvents(params2);
          if (offlineEventaResponse && offlineEventaResponse.data.content) {
            analyticsData = [...analyticsData, ...offlineEventaResponse.data.content];
            analyticsData = _.orderBy(analyticsData, event => event.timestamp, 'desc');
          }
        } catch (error) {
        }
        //ARGO RFID OPEN
        try {
          const params3 = {
            page,
            pageSize,
            eventType: 'ISEO_ARGO_8',
            eventOutcomes: ['JAGO_RFID_0_0', 'JAGO_PIN_0_0'],
            fromDate: fromDate || moment().startOf('day').valueOf(),
            toDate: toDate || moment().endOf('day').valueOf(),
          };
          const argoRFIDEventaResponse = await EventsAPI.fetchSmartLockEvents(params3);
          if (argoRFIDEventaResponse && argoRFIDEventaResponse.data.content) {
            analyticsData = [...analyticsData, ...argoRFIDEventaResponse.data.content];
            analyticsData = _.orderBy(analyticsData, event => event.timestamp, 'desc');
          }
        } catch (error) {
        }

        if (_.isEmpty(analyticsData)) {
          if (forCapacityManagement) {
            dispatch(saveAccessChartDataCapacityManagement([]));
            dispatch(saveAccessChartUsersCapacityManagement([]));
          }
          else {
            dispatch(saveAccessChartData([]));
            dispatch(saveAccessChartUsers([]));
            dispatch(saveAccessChartLocks([]));
          }
          return { analyticsData: [], chartData: [] };
        }
        const orderedSmartLocksEvents = _.groupBy(analyticsData, event => moment(event.timestamp).hours());
        const data = _.reduce(_.keys(orderedSmartLocksEvents), (acc, curr) => {
          acc = {
            ...acc,
            [curr]: _.size(orderedSmartLocksEvents[curr]),
          };
          return acc;
          
        }, {});
        const chartData = {
          labels: _.times(24, n => moment().hours(n).minutes(0).format('LT')),
          datasets: [
            {
              label: L20NContext.getSync('todayAccesses'),
              backgroundColor: 'rgba(27,151,194,0.2)',
              borderColor: 'rgba(27,151,194,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(27,151,194,0.4)',
              hoverBorderColor: 'rgba(27,151,194,1)',
              data: _.times(24, n => data[n] || 0),
            },
          ],
        };
        if (forCapacityManagement)
          dispatch(saveAccessChartDataCapacityManagement(chartData));
        else
          dispatch(saveAccessChartData(chartData));
        try {
          const filteredAnalyticsData = _.filter(analyticsData, event => event && event.data && event.data.actor);
          const orderedSmartLocksEventsUsers = _.map(_.uniqBy(filteredAnalyticsData, event => event.actorId), (openEvent) => {
            const guestEvents = _.filter(analyticsData, event => event.actorId === openEvent.data.actor.id);
            const firstAccessTimestamp = _.last(guestEvents) && _.last(guestEvents).timestamp;
            const lastAccessTimestamp = _.first(guestEvents) && _.first(guestEvents).timestamp;
            const lastOpenDoor = _.first(guestEvents) && _.first(guestEvents).data && _.first(guestEvents).data.lock;
            return {
              ...openEvent.data.actor,
              guestEvents,
              firstAccessTimestamp,
              lastAccessTimestamp,
              lastOpenDoor,
            };
          });
          if (forCapacityManagement)
            dispatch(saveAccessChartUsersCapacityManagement(orderedSmartLocksEventsUsers));
          else
            dispatch(saveAccessChartUsers(orderedSmartLocksEventsUsers));
        } catch (error) {}
        if (forCapacityManagement)
          return { analyticsData, chartData };  
        try {
          const orderedSmartLocksEventsLocks = _.groupBy(analyticsData, event => event.lockId);
          const dataLocks = _.reduce(_.keys(orderedSmartLocksEventsLocks), (acc, curr) => {
            const lockEvent = _.find(analyticsData, event => event.lockId == curr);
            const lock = lockEvent && lockEvent.data && lockEvent.data.lock ? lockEvent.data.lock : null;
            const users = _.map(_.uniqBy(orderedSmartLocksEventsLocks[curr], event => event.actorId), (userEvent) => userEvent && userEvent.data && userEvent.data.actor ? userEvent.data.actor : null);
            const firstAccessTimestamp = _.last(orderedSmartLocksEventsLocks[curr]) && _.last(orderedSmartLocksEventsLocks[curr]).timestamp;
            const lastAccessTimestamp = _.first(orderedSmartLocksEventsLocks[curr]) && _.first(orderedSmartLocksEventsLocks[curr]).timestamp;
            const lastActiveUser = _.last(users);
            acc = [
              ...acc,
              {
                lockId: parseInt(curr, 10),
                ...lock,
                eventCount: _.size(orderedSmartLocksEventsLocks[curr]),
                events: orderedSmartLocksEventsLocks[curr],
                firstAccessTimestamp,
                lastAccessTimestamp,
                lastActiveUser,
                users,
              },
            ];
            return acc;
          }, []);
          const lockData = {
            labels: _.map(dataLocks, dataLock => dataLock.name),
            dataLocks,
            datasets: [
              {
                label: L20NContext.getSync('events'),
                backgroundColor: 'rgba(0,150,136,0.2)',
                borderColor: 'rgba(0,150,136,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,150,136,0.4)',
                hoverBorderColor: 'rgba(0,150,136,1)',
                data: _.map(dataLocks, lockName => lockName.eventCount),
              },
              {
                label: L20NContext.getSync('dailyUniqueUsersLock'),
                backgroundColor: 'rgba(150, 62, 208,0.2)',
                borderColor: 'rgba(150, 62, 208,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(150, 62, 208,0.4)',
                hoverBorderColor: 'rgba(150, 62, 208,1)',
                data: _.map(dataLocks, lock => _.size(lock.users)),
              },
            ],
          };
          dispatch(saveAccessChartLocks(lockData));
        } catch (error) {}
        return { analyticsData, chartData };
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchSubCompanyAccessEvents(fromDate = null, toDate = null, companyId = null, page = 0, pageSize = 100000) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        companyId,
        eventTypes: ['JAGO_3', 'JAGO_5', 'JAGO_7', 'JAGO_0_PASSAGE_MODE_ON'],
        fromDate: fromDate || moment().startOf('day').valueOf(),
        toDate: toDate || moment().endOf('day').valueOf(),
      };
      const response = await EventsAPI.fetchSubcompaniesSmartLockEvents(params);
      if (response.data && response.data.content) {
        let analyticsData = response.data.content;
        try {
          const params2 = {
            page,
            pageSize,
            companyId,
            eventType: 'JAGO_MOBILE_0',
            eventOutcomes: ['JAGO_MOBILE_0_0'],
            fromDate: fromDate || moment().startOf('day').valueOf(),
            toDate: toDate || moment().endOf('day').valueOf(),
          };
          const offlineEventaResponse = await EventsAPI.fetchSubcompaniesSmartLockEvents(params2);
          if (offlineEventaResponse && offlineEventaResponse.data.content) {
            analyticsData = [...analyticsData, ...offlineEventaResponse.data.content];
            analyticsData = _.orderBy(analyticsData, event => event.timestamp, 'desc');
          }
        } catch (error) {
        }
        try {
          const params2 = {
            page,
            pageSize,
            companyId,
            eventType: 'JAGO_0',
            eventOutcomes: ['JAGO_MOBILE_0_0', 'VEGA_MOBILE_1_0', 'VEGA_RFID_1_0', 'VEGA_F9000_1_0'],
            fromDate: fromDate || moment().startOf('day').valueOf(),
            toDate: toDate || moment().endOf('day').valueOf(),
          };
          const offlineEventaResponse = await EventsAPI.fetchSubcompaniesSmartLockEvents(params2);
          if (offlineEventaResponse && offlineEventaResponse.data.content) {
            analyticsData = [...analyticsData, ...offlineEventaResponse.data.content];
            analyticsData = _.orderBy(analyticsData, event => event.timestamp, 'desc');
          }
        } catch (error) {
        }

        if (_.isEmpty(analyticsData)) {
          dispatch(saveAccessChartData([]));
          dispatch(saveAccessChartUsers([]));
          dispatch(saveAccessChartLocks([]));
          return { analyticsData: [], chartData: [] };
        }
        const orderedSmartLocksEvents = _.groupBy(analyticsData, event => moment(event.timestamp).hours());
        const data = _.reduce(_.keys(orderedSmartLocksEvents), (acc, curr) => {
          acc = {
            ...acc,
            [curr]: _.size(orderedSmartLocksEvents[curr]),
          };
          return acc;
          
        }, {});
        const chartData = {
          labels: _.times(24, n => moment().hours(n).minutes(0).format('LT')),
          datasets: [
            {
              label: L20NContext.getSync('todayAccesses'),
              backgroundColor: 'rgba(27,151,194,0.2)',
              borderColor: 'rgba(27,151,194,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(27,151,194,0.4)',
              hoverBorderColor: 'rgba(27,151,194,1)',
              data: _.times(24, n => data[n] || 0),
            },
          ],
        };
        dispatch(saveAccessChartData(chartData));
        try {
          const orderedSmartLocksEventsUsers = _.map(_.uniqBy(analyticsData, event => event.actorId), (openEvent) => {
            const guestEvents = _.filter(analyticsData, event => event.actorId === openEvent.data.actor.id);
            const firstAccessTimestamp = _.last(guestEvents) && _.last(guestEvents).timestamp;
            const lastAccessTimestamp = _.first(guestEvents) && _.first(guestEvents).timestamp;
            const lastOpenDoor = _.first(guestEvents) && _.first(guestEvents).data && _.first(guestEvents).data.lock;
            return {
              ...openEvent.data.actor,
              guestEvents,
              firstAccessTimestamp,
              lastAccessTimestamp,
              lastOpenDoor,
            };
          });
          dispatch(saveAccessChartUsers(orderedSmartLocksEventsUsers));
        } catch (error) {}
        try {
          const orderedSmartLocksEventsLocks = _.groupBy(analyticsData, event => event.lockId);
          const dataLocks = _.reduce(_.keys(orderedSmartLocksEventsLocks), (acc, curr) => {
            const lockEvent = _.find(analyticsData, event => event.lockId == curr);
            const lock = lockEvent && lockEvent.data && lockEvent.data.lock ? lockEvent.data.lock : null;
            const users = _.map(_.uniqBy(orderedSmartLocksEventsLocks[curr], event => event.actorId), (userEvent) => userEvent && userEvent.data && userEvent.data.actor ? userEvent.data.actor : null);
            const firstAccessTimestamp = _.last(orderedSmartLocksEventsLocks[curr]) && _.last(orderedSmartLocksEventsLocks[curr]).timestamp;
            const lastAccessTimestamp = _.first(orderedSmartLocksEventsLocks[curr]) && _.first(orderedSmartLocksEventsLocks[curr]).timestamp;
            const lastActiveUser = _.last(users);
            acc = [
              ...acc,
              {
                lockId: parseInt(curr, 10),
                ...lock,
                eventCount: _.size(orderedSmartLocksEventsLocks[curr]),
                events: orderedSmartLocksEventsLocks[curr],
                firstAccessTimestamp,
                lastAccessTimestamp,
                lastActiveUser,
                users,
              },
            ];
            return acc;
          }, []);
          const lockData = {
            labels: _.map(dataLocks, dataLock => dataLock.name),
            dataLocks,
            datasets: [
              {
                label: L20NContext.getSync('events'),
                backgroundColor: 'rgba(0,150,136,0.2)',
                borderColor: 'rgba(0,150,136,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,150,136,0.4)',
                hoverBorderColor: 'rgba(0,150,136,1)',
                data: _.map(dataLocks, lockName => lockName.eventCount),
              },
              {
                label: L20NContext.getSync('dailyUniqueUsersLock'),
                backgroundColor: 'rgba(150, 62, 208,0.2)',
                borderColor: 'rgba(150, 62, 208,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(150, 62, 208,0.4)',
                hoverBorderColor: 'rgba(150, 62, 208,1)',
                data: _.map(dataLocks, lock => _.size(lock.users)),
              },
            ],
          };
          dispatch(saveAccessChartLocks(lockData));
        } catch (error) {}
        return { analyticsData, chartData };
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}



export function filterAccessLocksData(text, accessData) {
  const { dataLocks } = accessData;
  const lock = _.filter(dataLocks, lockData => lockData.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
  if (lock) {
    return {
      labels: _.map(lock, dataLock => dataLock.name),
      dataLocks: lock,
      datasets: [
        {
          label: L20NContext.getSync('events'),
          backgroundColor: 'rgba(0,150,136,0.2)',
          borderColor: 'rgba(0,150,136,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0,150,136,0.4)',
          hoverBorderColor: 'rgba(0,150,136,1)',
          data: _.map(lock, lockName => lockName.eventCount),
        },
        {
          label: L20NContext.getSync('dailyUniqueUsersLock'),
          backgroundColor: 'rgba(150, 62, 208,0.2)',
          borderColor: 'rgba(150, 62, 208,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(150, 62, 208,0.4)',
          hoverBorderColor: 'rgba(150, 62, 208,1)',
          data: _.map(lock, lockData => _.size(lockData.users)),
        },
      ],
    };
  }
  return [];
}

export function fetchSmartLocksForBatteryLevel(batteryMin, batteryMax) {
  return async (dispatch) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
        batteryMax,
        batteryMin,
        powerSourceType: 'BATTERY',
      };
      const response = await RestService.fetchLocks(params);
      if (response.data && response.data.content) {
        return response.data.totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchSmartLocksBatteryLevelChartData() {
  return async (dispatch) => {
    try {
      const noBatteryLocks = await dispatch(fetchSmartLocksForBatteryLevel(0, 0));
      const lowestLocks = await dispatch(fetchSmartLocksForBatteryLevel(25, 25));
      const mediumLocks = await dispatch(fetchSmartLocksForBatteryLevel(50, 50));
      const mediumHighLocks = await dispatch(fetchSmartLocksForBatteryLevel(75, 75));
      const fullBatteryLocks = await dispatch(fetchSmartLocksForBatteryLevel(100, 100));
      const lockData = {
        labels: ['0%', '25%', '50%', '75%', '100%'],
        isDataEmpty: noBatteryLocks === 0 && lowestLocks === 0 && mediumLocks === 0 && fullBatteryLocks === 0,
        hasEmptyBatteryLocks: noBatteryLocks !== 0,
        hasLowBatteryLocks: lowestLocks !== 0,
        datasets: [
          {
            data: [noBatteryLocks, lowestLocks, mediumLocks, mediumHighLocks, fullBatteryLocks],
            backgroundColor: [
              'rgba(0, 0, 0, 0.2)', // black
              'rgba(241, 130, 141,0.2)', // red
              'rgba(244, 179, 80, 0.2)', // orange
              'rgba(92,178,188, 0.2)',
              'rgba(74,143,150,0.2)', // green
            ],
            borderColor: [
              'rgba(0, 0, 0, 1)',
              'rgba(241, 130, 141, 1)',
              'rgba(244, 179, 80, 1)',
              'rgba(92,178,188, 1)',
              'rgba(74,143,150, 1)',
            ],
            hoverBackgroundColor: [
              'rgba(0, 0, 0, 0.4)',
              'rgba(241, 130, 141, 0.4)',
              'rgba(244, 179, 80, 0.4)',
              'rgba(92,178,188, 0.4)',
              'rgba(74,143,150,0.4)',
            ],
          },
        ],
      };
      dispatch(saveSmartLocksBatteryChartData(lockData));
    } catch (error) {
      throw new Error();
    }
  };
}

export function fetchAllCounterMetrics() {
  return async (dispatch, getState) => {
    try {
      const guestsMetrics = getState().metrics.data.guests;
      if (!guestsMetrics || _.isEmpty(guestsMetrics)) await dispatch(fetchCustomersMetric());
    } catch (error) {}
    try {
      const locksMetrics = getState().metrics.data.smartLocks;
      if (!locksMetrics || _.isEmpty(locksMetrics)) await dispatch(fetchLocksMetric());
    } catch (error) {}
    try {
      const standardDevicesMetrics = getState().metrics.data.standardDevices;
      if (!standardDevicesMetrics || _.isEmpty(standardDevicesMetrics)) await dispatch(fetchStandardDevicesMetric());
    } catch (error) {}
    try {
      const pinsDevicesMetrics = getState().metrics.data.pins;
      if (!pinsDevicesMetrics || _.isEmpty(pinsDevicesMetrics)) await dispatch(fetchPinsMetric());
    } catch (error) {}
    try {
      const smartPhoneRulesMetrics = getState().metrics.data.smartPhoneRules;
      if (!smartPhoneRulesMetrics || _.isEmpty(smartPhoneRulesMetrics)) await dispatch(fetchSmartphoneRulesMetric());
    } catch (error) {}
    try {
      const hyperKeysMetrics = getState().metrics.data.hyperKeys;
      if (!hyperKeysMetrics || _.isEmpty(hyperKeysMetrics)) await dispatch(fetchF9000Metric());
    } catch (error) {}
  };
}


export function exportActiveUsers(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const guestsMetrics = getState().metrics.accessChartData.users;
      const exportData = [];
      _.each(guestsMetrics, (guest) => {
        exportData.push({
          [`${L20NContext.getSync('name')}`]: guest.firstname,
          [`${L20NContext.getSync('surname')}`]: guest.lastname,
          Email: guest.email,
          [`${L20NContext.getSync('firstAccess')}`]: moment(guest.firstAccessTimestamp).format('LLL'),
          [`${L20NContext.getSync('lastAccess')}`]: moment(guest.lastAccessTimestamp).format('LLL'),
          [`${L20NContext.getSync('lastAccessLock')}`]: guest.lastOpenDoor ? guest.lastOpenDoor.name : '---',
          [`${L20NContext.getSync('eventsNumber')}`]: _.size(guest.guestEvents),
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = format.toLowerCase();
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { smartlocks: ws }, SheetNames: ['smartlocks'] };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `ActiveUsers_Export.${fileExtension}`);
      return guestsMetrics;
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorCreatingCSV" /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportActiveSmartLocks(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { locksChartData } = getState().metrics.accessChartData;
      if (locksChartData && locksChartData.dataLocks) {
        const exportData = [];
        _.each(locksChartData.dataLocks, (lock) => {
          exportData.push({
            [`${L20NContext.getSync('name')}`]: lock.name,
            [`${L20NContext.getSync('serialNumber')}`]: lock.serialNumber,
            [`${L20NContext.getSync('firstAccess')}`]: moment(lock.firstAccessTimestamp).format('LLL'),
            [`${L20NContext.getSync('lastAccess')}`]: moment(lock.lastAccessTimestamp).format('LLL'),
            [`${L20NContext.getSync('lastActiveUser')}`]: lock.lastActiveUser ? `${lock.lastActiveUser.firstname} ${lock.lastActiveUser.name} ${lock.lastActiveUser.email} ` : '---',
            [`${L20NContext.getSync('eventsNumber')}`]: _.size(lock.events),
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { smartlocks: ws }, SheetNames: ['smartlocks'] };
        const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Used_SmartLocks_Export.${fileExtension}`);
        return locksChartData;
      }
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorCreatingCSV" /></h6>),
        },
      }));
      throw new Error(error);
    }
  }
}


export function fetchAccessoriesMetric() {
  return async (dispatch, getState) => {
    try {
      // fetch accessories of type DOOR_STATUS_SENSOR
      // fetch lock entity ID
      const doorStatusSensors = await dispatch(AccessoriesActions.fetchStatusSensorAccessories());
      if (doorStatusSensors && !_.isEmpty(doorStatusSensors)) {
        const accessories = getState().accessories.data.content;
        const smartLocksWithSensors = [];
        for (const sensor of doorStatusSensors) {
          if (sensor.gateway && !_.isEmpty(sensor.gateway)) {
            const lockId = sensor.entityId;
            if (lockId) {
              const lockDetails = await RestService.getLockDetails(lockId);
              if (lockDetails && lockDetails.data) {
                smartLocksWithSensors.push({
                  lock: formatter.formatInputData(formatter.LOCK, { ...lockDetails.data, accessories }),
                  sensor,
                });
              }
            } else {
              smartLocksWithSensors.push({
                sensor,
              });
            }
          }
        }
        dispatch(saveAccessoriesChartData(smartLocksWithSensors));
      }
    } catch (error) {
    }
  }
}

export function setBookeyUsersEpic(users,lastUserID) {
  return (dispatch, getState) => {
    dispatch(saveBookeyUsers(users));
    if (!users||!users.length||lastUserID===users[users.length-1].id.toString())
      dispatch(setBookeyUsersLoading(false));
  };
} 

const isReservationNotDeleted = (status) => !status.startsWith("DELETED");
const isReservationPenalty = (status) => status.endsWith("WITH_PENALTY");
const isReservationLost = (status) => status==="LOST";

export function fetchBookeyAnalyticsCards(reservations, reservationsPrev, resources) {
  return async (dispatch, getState) => {
    try {
      const totalReservations = _.filter(reservations, (res) => isReservationNotDeleted(res.state)||isReservationPenalty(res.state)).length;
      const totalReservationsPrev = _.filter(reservationsPrev, (res) => isReservationNotDeleted(res.state)||isReservationPenalty(res.state)).length;
      const totalReservationsPrevPerc = totalReservationsPrev?
        Math.round(10*100*(totalReservations-totalReservationsPrev)/totalReservationsPrev)/10:"-"

      const totalHoursSuccessful = _.filter(reservations, (res) => isReservationNotDeleted(res.state) && !isReservationPenalty(res.state) && !isReservationLost(res.state))
        .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'hours');
            return total + duration;
        }, 0);
      const totalHoursSuccessfulPrev = _.filter(reservationsPrev, (res) => isReservationNotDeleted(res.state) && !isReservationPenalty(res.state) && !isReservationLost(res.state))
        .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'hours');
            return total + duration;
        }, 0);
      const totalHoursSuccessfulPrevPerc = totalHoursSuccessfulPrev?
        Math.round(10*100*(totalHoursSuccessful-totalHoursSuccessfulPrev)/totalHoursSuccessfulPrev)/10:"-"

      const totalHoursWithPenalty = _.filter(reservations, (res) => isReservationPenalty(res.state))
        .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'hours');
            return total + duration;
        }, 0);
      const totalHoursWithPenaltyPrev = _.filter(reservationsPrev, (res) => isReservationPenalty(res.state))
        .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'hours');
            return total + duration;
        }, 0);
      const totalHoursWithPenaltyPrevPerc = totalHoursWithPenaltyPrev?
        Math.round(10*100*(totalHoursWithPenalty-totalHoursWithPenaltyPrev)/totalHoursWithPenaltyPrev)/10:"-"

      const totalDeletedReservations = _.filter(reservations, (res) => !isReservationNotDeleted(res.state)).length;
      const totalDeletedReservationsPrev = _.filter(reservationsPrev, (res) => !isReservationNotDeleted(res.state)).length;
      const totalDeletedReservationsPrevPerc = totalDeletedReservationsPrev?
        Math.round(10*100*(totalDeletedReservations-totalDeletedReservationsPrev)/totalDeletedReservationsPrev)/10:"-"

      const totalHoursLost = _.filter(reservations, (res) => isReservationNotDeleted(res.state)&&isReservationLost(res.state))
        .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'hours');
            return total + duration;
        }, 0);
      const totalHoursLostPrev = _.filter(reservationsPrev, (res) => isReservationNotDeleted(res.state)&&isReservationLost(res.state))
        .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'hours');
            return total + duration;
        }, 0);
      const totalHoursLostPrevPerc = totalHoursLostPrev?
        Math.round(10*100*(totalHoursLost-totalHoursLostPrev)/totalHoursLostPrev)/10:"-"

      dispatch(saveBookeyAnalyticsCards({
        totalReservations: totalReservations,
        totalHoursSuccessful: totalHoursSuccessful,
        totalHoursWithPenalty: totalHoursWithPenalty,
        totalDeletedReservations: totalDeletedReservations,
        totalHoursLost: totalHoursLost,
        totalReservationsPrev: totalReservationsPrev,
        totalHoursSuccessfulPrev: totalHoursSuccessfulPrev,
        totalHoursWithPenaltyPrev: totalHoursWithPenaltyPrev,
        totalDeletedReservationsPrev: totalDeletedReservationsPrev,
        totalHoursLostPrev: totalHoursLostPrev,
        totalReservationsPrevPerc: totalReservationsPrevPerc,
        totalHoursSuccessfulPrevPerc: totalHoursSuccessfulPrevPerc,
        totalHoursWithPenaltyPrevPerc: totalHoursWithPenaltyPrevPerc,
        totalDeletedReservationsPrevPerc: totalDeletedReservationsPrevPerc,
        totalHoursLostPrevPerc: totalHoursLostPrevPerc,
      }));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchBookeyAnalyticsUsers(validReservations) {
  return async (dispatch, getState) => {
    try {
      const userReservations = _.groupBy(validReservations, 'userId');
      const usersParsed = _.map(userReservations, (reservations, userId) => {
        const totalReservations = reservations.length;
        const totalMinutes = reservations
          .filter(reservation => isReservationNotDeleted(reservation.state) && !isReservationPenalty(reservation.state) && !isReservationLost(reservation.state))
          .reduce((total, reservation) => {
          const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
          return total + duration;
        }, 0);
        const totalPenaltyMinutes = reservations
          .filter(reservation => isReservationPenalty(reservation.state))
          .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
            return total + duration;
          }, 0);
        const totalLostMinutes = reservations
          .filter(reservation => isReservationLost(reservation.state))
          .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
            return total + duration;
          }, 0);

        return {
          userId,
          totalReservations,
          totalMinutes,
          totalLostMinutes,
          totalPenaltyMinutes
        };
      });

      const topUsers = _.orderBy(usersParsed, ['totalReservations'], ['desc']).slice(0, 20);

      dispatch(saveBookeyAnalyticsUsers({
        topUsers: topUsers,
      }));
      
      return topUsers;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchBookeyAnalyticsResources(validReservations, validReservationsPrev, resources, fromDate, toDate) {
  return async (dispatch, getState) => {
    try {
      const totalReservationsAllRes = validReservations.length?validReservations.length:1;
      const totalReservationsAllResPrev = validReservationsPrev.length?validReservationsPrev.length:1;
      
      const calculateTotalReservedTime = (reservations, resourceId) => {
        return _.filter(reservations, { resource: { id: resourceId } })
          .reduce((total, reservation) => {
            const duration = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
            return total + duration;
          }, 0);
      };
      const calculateTotalReservations = (reservations, resourceId) => {
        return _.filter(reservations, { resource: { id: resourceId } }).length;
      };

      const resourcesParsed = resources.map(resource => {
        const totalReservedMinutes = calculateTotalReservedTime(validReservations, resource.id);
        const totalReservations = calculateTotalReservations(validReservations, resource.id);
        const allReservations = _.filter(validReservations, reservation => reservation.resource.id === resource.id);
        const totalReservationTime = _.sumBy(allReservations, reservation =>
          moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes')
        );
        const avgReservationTime = allReservations.length ? totalReservationTime / allReservations.length : 0;
      
        return {
          ...resource,
          totalReservations,
          totalReservedMinutes,
          utilizationPercentage: Math.min(100,((totalReservations / totalReservationsAllRes) * 100)),
          avgReservationTime,
        };
      });
      const resourcesParsedPrev = resources.map(resource => {
        const totalReservedMinutes = calculateTotalReservedTime(validReservationsPrev, resource.id);
        const totalReservations = calculateTotalReservations(validReservationsPrev, resource.id);
  
        const allReservations = _.filter(validReservationsPrev, reservation => reservation.resource.id === resource.id);
        const totalReservationTime = _.sumBy(allReservations, reservation =>
          moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes')
        );
        const avgReservationTime = allReservations.length ? totalReservationTime / allReservations.length : 0;
      
        return {
          ...resource,
          totalReservations,
          totalReservedMinutes,
          utilizationPercentage: Math.min(100,((totalReservations / totalReservationsAllResPrev) * 100)),
          avgReservationTime,
        };
      });
  
      const topResources = _.orderBy(resourcesParsed, ['utilizationPercentage'], ['desc']);
      const topResourcesPrev = _.orderBy(resourcesParsedPrev, ['utilizationPercentage'], ['desc']);
  
      const resourcesUsage1 = resourcesParsed.filter(e=>e.utilizationPercentage<25).length
      const resourcesUsage2 = resourcesParsed.filter(e=>e.utilizationPercentage>=25&&e.utilizationPercentage<50).length
      const resourcesUsage3 = resourcesParsed.filter(e=>e.utilizationPercentage>=50&&e.utilizationPercentage<70).length
      const resourcesUsage4 = resourcesParsed.filter(e=>e.utilizationPercentage>=75).length
      
      const resourcesUsagePieChartData = {
        labels: ['0-25%', '25-50%', '50-75%', '75-100%'],
        isDataEmpty: resourcesUsage1 === 0 && resourcesUsage2 === 0 && resourcesUsage3 === 0 && resourcesUsage4 === 0,
        datasets: [
          {
            data: [resourcesUsage1, resourcesUsage2, resourcesUsage3, resourcesUsage4],
            backgroundColor: [
              'rgba(241, 130, 141,0.2)', // red
              'rgba(244, 179, 80, 0.2)', // orange
              'rgba(92,178,188, 0.2)',
              'rgba(74,143,150,0.2)', // green
            ],
            borderColor: [
              'rgba(241, 130, 141, 1)',
              'rgba(244, 179, 80, 1)',
              'rgba(92,178,188, 1)',
              'rgba(74,143,150, 1)',
            ],
            hoverBackgroundColor: [
              'rgba(241, 130, 141, 0.4)',
              'rgba(244, 179, 80, 0.4)',
              'rgba(92,178,188, 0.4)',
              'rgba(74,143,150,0.4)',
            ],
          },
        ],
      };
  
      const resourcesByType = _.groupBy(_.sortBy(resourcesParsed, resource => resource.type.name.toLowerCase()), resource => resource.type.id);
      const resourcesByTypePrev = _.groupBy(_.sortBy(resourcesParsedPrev, resource => resource.type.name.toLowerCase()), resource => resource.type.id);

      const resourceTypesParsed = _.map(resourcesByType, (resources, typeId) => {
        const typeName = resources[0].type.name;
        const totalResources = resources.length;
  
        const allReservations = _.flatMap(resources, resource => 
          _.filter(validReservations, reservation => reservation.resource.id === resource.id)
        );
        const totalReservationTime = _.sumBy(allReservations, reservation => 
          moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes')
        );
        const avgReservationTime = allReservations.length ? totalReservationTime / allReservations.length : 0;
  
        return {
          typeId,
          typeName,
          totalResources,
          utilizationPercentage: Math.min(100,((allReservations.length / totalReservationsAllRes) * 100)),
          avgReservationTime
        };
      });
      const resourceTypesParsedPrev = _.map(resourcesByTypePrev, (resources, typeId) => {
        const typeName = resources[0].type.name;
        const totalResources = resources.length;
  
        const allReservations = _.flatMap(resources, resource => 
          _.filter(validReservationsPrev, reservation => reservation.resource.id === resource.id)
        );
        const totalReservationTime = _.sumBy(allReservations, reservation => 
          moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes')
        );
        const avgReservationTime = allReservations.length ? totalReservationTime / allReservations.length : 0;
  
        return {
          typeId,
          typeName,
          totalResources,
          utilizationPercentage: Math.min(100,((allReservations.length / totalReservationsAllResPrev) * 100)),
          avgReservationTime
        };
      });
  
      const resourceTypesReservationTimeChartData = {
        labels: _.map(resourceTypesParsed,e=>{return e.typeName}),
        datasets: [
          {
            label: L20NContext.getSync('averageReservationTimeLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(resourceTypesParsed,e=>{return Math.round(10*e.avgReservationTime/60)/10})
          }
        ]
      };
      const resourceTypesUtilizationChartData = {
        labels: _.map(resourceTypesParsed,e=>{return e.typeName}),
        datasets: [
          {
            label: L20NContext.getSync('utilizationLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(resourceTypesParsed,e=>{return Math.round(Math.min(100,e.utilizationPercentage)*100)/100})
          }
        ]
      };
      const resourceTypesReservationTimeChartDataPrev = {
        labels: _.map(resourceTypesParsed,e=>{return e.typeName}),
        datasets: [
          {
            label: L20NContext.getSync('averageReservationTimeLabelPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: _.map(resourceTypesParsedPrev,e=>{return Math.round(10*e.avgReservationTime/60)/10})
          },
          {
            label: L20NContext.getSync('averageReservationTimeLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(resourceTypesParsed,e=>{return Math.round(10*e.avgReservationTime/60)/10})
          },
        ]
      };
      const resourceTypesUtilizationChartDataPrev = {
        labels: _.map(resourceTypesParsed,e=>{return e.typeName}),
        datasets: [
          {
            label: L20NContext.getSync('utilizationLabelPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: _.map(resourceTypesParsedPrev,e=>{return Math.round(Math.min(100,e.utilizationPercentage)*100)/100})
          },
          {
            label: L20NContext.getSync('utilizationLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(resourceTypesParsed,e=>{return Math.round(Math.min(100,e.utilizationPercentage)*100)/100})
          },
        ]
      };

      const topResourcesSliced = topResources.slice(0, 20)
      const topResourcesSlicedPrev = topResourcesPrev.slice(0, 20)
  
      const resourceReservationTimeChartData = {
        labels: _.map(topResourcesSliced,e=>{return e.name}),
        datasets: [
          {
            label: L20NContext.getSync('averageReservationTimeLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(topResourcesSliced,e=>{return Math.round(10*e.avgReservationTime/60)/10})
          }
        ]
      };
      const resourceUtilizationChartData = {
        labels: _.map(topResourcesSliced,e=>{return e.name}),
        datasets: [
          {
            label: L20NContext.getSync('utilizationLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(topResourcesSliced,e=>{return Math.round(Math.min(100,e.utilizationPercentage)*100)/100})
          }
        ]
      };
      const resourceReservationTimeChartDataPrev = {
        labels: _.map(topResourcesSliced,e=>{return e.name}),
        datasets: [
          {
            label: L20NContext.getSync('averageReservationTimeLabelPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: _.map(topResourcesSlicedPrev,e=>{return Math.round(10*e.avgReservationTime/60)/10})
          },
          {
            label: L20NContext.getSync('averageReservationTimeLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(topResourcesSliced,e=>{return Math.round(10*e.avgReservationTime/60)/10})
          },
        ]
      };
      const resourceUtilizationChartDataPrev = {
        labels: _.map(topResourcesSliced,e=>{return e.name}),
        datasets: [
          {
            label: L20NContext.getSync('utilizationLabelPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: _.map(topResourcesSlicedPrev,e=>{return Math.round(Math.min(100,e.utilizationPercentage)*100)/100})
          },
          {
            label: L20NContext.getSync('utilizationLabel'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: _.map(topResourcesSliced,e=>{return Math.round(Math.min(100,e.utilizationPercentage)*100)/100})
          },
        ]
      };

      dispatch(saveBookeyAnalyticsResources({
        resourcesUsagePieChartData: resourcesUsagePieChartData,

        resourceTypesParsed: resourceTypesParsed,
        resourceTypesParsedPrev: resourceTypesParsedPrev,

        topResources: topResources,
        topResourcesPrev: topResourcesPrev,
        
        resourceTypesReservationTimeChartData: resourceTypesReservationTimeChartData,
        resourceTypesUtilizationChartData: resourceTypesUtilizationChartData,
        resourceTypesReservationTimeChartDataPrev: resourceTypesReservationTimeChartDataPrev,
        resourceTypesUtilizationChartDataPrev: resourceTypesUtilizationChartDataPrev,

        resourceReservationTimeChartData: resourceReservationTimeChartData,
        resourceUtilizationChartData: resourceUtilizationChartData,
        resourceReservationTimeChartDataPrev: resourceReservationTimeChartDataPrev,
        resourceUtilizationChartDataPrev: resourceUtilizationChartDataPrev,
      }));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchBookeyAnalyticsReservations(validReservations, validReservationsPrev, fromDate, toDate) {
  return async (dispatch, getState) => {
    try {
      const reservationsByDayOfWeek = _.groupBy(validReservations, res => moment(res.fromDate).isoWeekday());
      const reservationsPieChartData = {
        labels: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(e=>{return L20NContext.getSync(e)}),
        datasets: [
          {
            data: [
              reservationsByDayOfWeek[1] ? reservationsByDayOfWeek[1].length : 0,
              reservationsByDayOfWeek[2] ? reservationsByDayOfWeek[2].length : 0,
              reservationsByDayOfWeek[3] ? reservationsByDayOfWeek[3].length : 0,
              reservationsByDayOfWeek[4] ? reservationsByDayOfWeek[4].length : 0,
              reservationsByDayOfWeek[5] ? reservationsByDayOfWeek[5].length : 0,
              reservationsByDayOfWeek[6] ? reservationsByDayOfWeek[6].length : 0,
              reservationsByDayOfWeek[7] ? reservationsByDayOfWeek[7].length : 0,
            ],
            backgroundColor: [
              'rgba(75,192,192,0.2)', // aqua
              'rgba(54,162,235,0.2)', // blue
              'rgba(255,206,86,0.2)', // yellow
              'rgba(0,128,128,0.2)', // green
              'rgba(153,102,255,0.2)', // purple
              'rgba(255,159,64,0.2)', // orange
              'rgba(255,99,132,0.2)', // red
            ],
            borderColor: [
              'rgba(75,192,192,1)',
              'rgba(54,162,235,1)',
              'rgba(255,206,86,1)',
              'rgba(0,128,128,1)',
              'rgba(153,102,255,1)',
              'rgba(255,159,64,1)',
              'rgba(255,99,132,1)',
            ],
            hoverBackgroundColor: [
              'rgba(75,192,192,0.4)',
              'rgba(54,162,235,0.4)',
              'rgba(255,206,86,0.4)',
              'rgba(0,128,128,0.4)',
              'rgba(153,102,255,0.4)',
              'rgba(255,159,64,0.4)',
              'rgba(255,99,132,0.4)',
            ],
          },
        ],
      };

      // PER ORARIO
      const hoursLabels = _.times(24, n => moment().hours(n).minutes(0).format('LT'));

      const reservationsNormal = new Array(24).fill(0);
      const reservationsPenalty = new Array(24).fill(0);
      const reservationsLost = new Array(24).fill(0);
      const hoursNormal = new Array(24).fill(0);
      const hoursPenalty = new Array(24).fill(0);
      const hoursLost = new Array(24).fill(0);
      const reservationsDataNormal = Array.from({ length: 24 }, () => []);
      const reservationsDataPenalty = Array.from({ length: 24 }, () => []);
      const reservationsDataLost = Array.from({ length: 24 }, () => []);

      const reservationsNormalPrev = new Array(24).fill(0);
      const reservationsPenaltyPrev = new Array(24).fill(0);
      const reservationsLostPrev = new Array(24).fill(0);
      const hoursNormalPrev = new Array(24).fill(0);
      const hoursPenaltyPrev = new Array(24).fill(0);
      const hoursLostPrev = new Array(24).fill(0);

      validReservations.forEach(reservation => {
        const fromHour = moment(reservation.fromDate).hours();
        const toHour = moment(reservation.toDate).hours();
        
        for (let hour = fromHour; hour <= toHour; hour++) {
          if (isReservationPenalty(reservation.state)) {
            reservationsPenalty[hour]++;
            reservationsDataPenalty[hour].push(reservation)
          } 
          else if (isReservationLost(reservation.state)) {
            reservationsLost[hour]++;
            reservationsDataLost[hour].push(reservation)
          } else {
            reservationsNormal[hour]++;
            reservationsDataNormal[hour].push(reservation)
          }
        }
      });

      validReservationsPrev.forEach(reservation => {
        const fromHour = moment(reservation.fromDate).hours();
        const toHour = moment(reservation.toDate).hours();
        
        for (let hour = fromHour; hour <= toHour; hour++) {
          if (isReservationPenalty(reservation.state)) {
            reservationsPenaltyPrev[hour]++;
          } 
          else if (isReservationLost(reservation.state)) {
            reservationsLostPrev[hour]++;
          } else {
            reservationsNormalPrev[hour]++;
          }
        }
      });

      validReservations.forEach(reservation => {
        const fromHour = moment(reservation.fromDate).hours();
        const fromMinute = moment(reservation.fromDate).minutes();
        const toHour = moment(reservation.toDate).hours();
        const toMinute = moment(reservation.toDate).minutes();
      
        for (let hour = fromHour; hour <= toHour; hour++) {
          if (hour === fromHour && hour === toHour) {
            const minutes = toMinute - fromMinute;
            if (isReservationPenalty(reservation.state)) {
              hoursPenalty[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLost[hour] += minutes/60;
            }
            else {
              hoursNormal[hour] += minutes/60;
            }
          } else if (hour === fromHour) {
            const minutes = 60 - fromMinute;
            if (isReservationPenalty(reservation.state)) {
              hoursPenalty[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLost[hour] += minutes/60;
            }
            else {
              hoursNormal[hour] += minutes/60;
            }
          } else if (hour === toHour) {
            const minutes = toMinute;
            if (isReservationPenalty(reservation.state)) {
              hoursPenalty[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLost[hour] += minutes/60;
            }
            else {
              hoursNormal[hour] += minutes/60;
            }
          } else {
            const minutes = 60;
            if (isReservationPenalty(reservation.state)) {
              hoursPenalty[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLost[hour] += minutes/60;
            }
            else {
              hoursNormal[hour] += minutes/60;
            }
          }
        }
      });

      validReservationsPrev.forEach(reservation => {
        const fromHour = moment(reservation.fromDate).hours();
        const fromMinute = moment(reservation.fromDate).minutes();
        const toHour = moment(reservation.toDate).hours();
        const toMinute = moment(reservation.toDate).minutes();
      
        for (let hour = fromHour; hour <= toHour; hour++) {
          if (hour === fromHour && hour === toHour) {
            const minutes = toMinute - fromMinute;
            if (isReservationPenalty(reservation.state)) {
              hoursPenaltyPrev[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLostPrev[hour] += minutes/60;
            }
            else {
              hoursNormalPrev[hour] += minutes/60;
            }
          } else if (hour === fromHour) {
            const minutes = 60 - fromMinute;
            if (isReservationPenalty(reservation.state)) {
              hoursPenaltyPrev[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLostPrev[hour] += minutes/60;
            }
            else {
              hoursNormalPrev[hour] += minutes/60;
            }
          } else if (hour === toHour) {
            const minutes = toMinute;
            if (isReservationPenalty(reservation.state)) {
              hoursPenaltyPrev[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLostPrev[hour] += minutes/60;
            }
            else {
              hoursNormalPrev[hour] += minutes/60;
            }
          } else {
            const minutes = 60;
            if (isReservationPenalty(reservation.state)) {
              hoursPenaltyPrev[hour] += minutes/60;
            } 
            else if (isReservationLost(reservation.state)) {
              hoursLostPrev[hour] += minutes/60;
            }
            else {
              hoursNormalPrev[hour] += minutes/60;
            }
          }
        }
      });

      const reservationsHourlyChartData = {
        labels: hoursLabels,
        datasets: [
          {
            label: L20NContext.getSync('activeReservations'),
            backgroundColor: 'rgba(77,182,172,0.2)',
            borderColor: 'rgba(77,182,172,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(77,182,172,0.4)',
            hoverBorderColor: 'rgba(77,182,172,1)',
            data: reservationsNormal,
            reservations: reservationsDataNormal,
          },
          {
            label: L20NContext.getSync('reservationsLost'),
            backgroundColor: 'rgba(241, 130, 141,0.2)',
            borderColor: 'rgba(241, 130, 141,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(241, 130, 141,0.4)',
            hoverBorderColor: 'rgba(241, 130, 141,1)',
            data: reservationsLost,
            reservations: reservationsDataLost,
          },
          {
            label: L20NContext.getSync('reservationsPenalty'),
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderColor: 'rgba(255,167,38,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,167,38,0.4)',
            hoverBorderColor: 'rgba(255,167,38,1)',
            data: reservationsPenalty,
            reservations: reservationsDataPenalty,
          },
        ]
      };
      const hoursHourlyChartData = {
        labels: hoursLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsHoursUsed'),
            backgroundColor: 'rgba(77,182,172,0.2)',
            borderColor: 'rgba(77,182,172,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(77,182,172,0.4)',
            hoverBorderColor: 'rgba(77,182,172,1)',
            data: hoursNormal.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsDataNormal,
          },
          {
            label: L20NContext.getSync('reservationsHoursLost'),
            backgroundColor: 'rgba(241, 130, 141,0.2)',
            borderColor: 'rgba(241, 130, 141,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(241, 130, 141,0.4)',
            hoverBorderColor: 'rgba(241, 130, 141,1)',
            data: hoursLost.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsDataLost,
          },
          {
            label: L20NContext.getSync('reservationsHoursPenalty'),
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderColor: 'rgba(255,167,38,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,167,38,0.4)',
            hoverBorderColor: 'rgba(255,167,38,1)',
            data: hoursPenalty.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsDataPenalty,
          },
        ]
      };

      const totalReservations = reservationsNormal.map((value, index) => value + reservationsPenalty[index] + reservationsLost[index]);
      const totalHours = hoursNormal.map((value, index) => value + hoursPenalty[index] + hoursLost[index]);
      const totalReservationsData = Array.from({ length: 24 }, (_, index) => {
        return [
          ...reservationsDataNormal[index],
          ...reservationsDataLost[index],
          ...reservationsDataPenalty[index]
        ];
      });

      const totalReservationsPrev = reservationsNormalPrev.map((value, index) => value + reservationsPenaltyPrev[index] + reservationsLostPrev[index]);
      const totalHoursPrev = hoursNormalPrev.map((value, index) => value + hoursPenaltyPrev[index] + hoursLostPrev[index]);

      const reservationsHourlyChartDataPrev = {
        labels: hoursLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: totalReservationsPrev,
            reservations: [],
          },
          {
            label: L20NContext.getSync('reservations'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: totalReservations,
            reservations: totalReservationsData,
          },
        ]
      };
      const hoursHourlyChartDataPrev = {
        labels: hoursLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsHoursTotalPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: totalHoursPrev.map(e=>{return Math.round(10*e)/10}),
            reservations: [],
          },
          {
            label: L20NContext.getSync('reservationsHoursTotal'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: totalHours.map(e=>{return Math.round(10*e)/10}),
            reservations: totalReservationsData,
          },
        ]
      };

      const daysBetween = moment(toDate).diff(moment(fromDate), 'days') + 1;
      
      const reservationsHourlyChartData_avg = _.cloneDeep(reservationsHourlyChartData)
      reservationsHourlyChartData_avg.datasets[0].data = _.map(reservationsNormal,e=>{return Math.round(100*e/daysBetween)/100})
      reservationsHourlyChartData_avg.datasets[1].data = _.map(reservationsLost,e=>{return Math.round(100*e/daysBetween)/100})
      reservationsHourlyChartData_avg.datasets[2].data = _.map(reservationsPenalty,e=>{return Math.round(100*e/daysBetween)/100})
      const hoursHourlyChartData_avg = _.cloneDeep(hoursHourlyChartData)
      hoursHourlyChartData_avg.datasets[0].data = _.map(hoursNormal,e=>{return Math.round(100*e/daysBetween)/100})
      hoursHourlyChartData_avg.datasets[1].data = _.map(hoursLost,e=>{return Math.round(100*e/daysBetween)/100})
      hoursHourlyChartData_avg.datasets[2].data = _.map(hoursPenalty,e=>{return Math.round(100*e/daysBetween)/100})
      
      const reservationsHourlyChartData_avgPrev = _.cloneDeep(reservationsHourlyChartDataPrev)
      reservationsHourlyChartData_avgPrev.datasets[0].data = _.map(totalReservationsPrev,e=>{return Math.round(100*e/daysBetween)/100})
      reservationsHourlyChartData_avgPrev.datasets[1].data = _.map(totalReservations,e=>{return Math.round(100*e/daysBetween)/100})
      const hoursHourlyChartData_avgPrev = _.cloneDeep(hoursHourlyChartDataPrev)
      hoursHourlyChartData_avgPrev.datasets[0].data = _.map(totalHoursPrev,e=>{return Math.round(100*e/daysBetween)/100})
      hoursHourlyChartData_avgPrev.datasets[1].data = _.map(totalHours,e=>{return Math.round(100*e/daysBetween)/100})
      

      // PER GIORNO DELLA SETTIMANA
      const dayLabels = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(e=>{return L20NContext.getSync(e)});

      const reservationsDayNormal = new Array(7).fill(0);
      const reservationsDayPenalty = new Array(7).fill(0);
      const reservationsDayLost = new Array(7).fill(0);
      const hoursDayNormal = new Array(7).fill(0);
      const hoursDayPenalty = new Array(7).fill(0);
      const hoursDayLost = new Array(7).fill(0);
      const reservationsDayDataNormal = Array.from({ length: 7 }, () => []);
      const reservationsDayDataPenalty = Array.from({ length: 7 }, () => []);
      const reservationsDayDataLost = Array.from({ length: 7 }, () => []);

      const reservationsDayNormalPrev = new Array(7).fill(0);
      const reservationsDayPenaltyPrev = new Array(7).fill(0);
      const reservationsDayLostPrev = new Array(7).fill(0);
      const hoursDayNormalPrev = new Array(7).fill(0);
      const hoursDayPenaltyPrev = new Array(7).fill(0);
      const hoursDayLostPrev = new Array(7).fill(0);

      validReservations.forEach(reservation => {
        const day = moment(reservation.fromDate).isoWeekday() - 1;
        const minutes = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
        
        if (isReservationPenalty(reservation.state)) {
          reservationsDayPenalty[day]++;
          hoursDayPenalty[day] += minutes/60;
          reservationsDayDataPenalty[day].push(reservation);
        } else if (isReservationLost(reservation.state)) {
          reservationsDayLost[day]++;
          hoursDayLost[day] += minutes/60;
          reservationsDayDataLost[day].push(reservation);
        } else {
          reservationsDayNormal[day]++;
          hoursDayNormal[day] += minutes/60;
          reservationsDayDataNormal[day].push(reservation);
        }
      });

      validReservationsPrev.forEach(reservation => {
        const day = moment(reservation.fromDate).isoWeekday() - 1;
        const minutes = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
        
        if (isReservationPenalty(reservation.state)) {
          reservationsDayPenaltyPrev[day]++;
          hoursDayPenaltyPrev[day] += minutes/60;
        } else if (isReservationLost(reservation.state)) {
          reservationsDayLostPrev[day]++;
          hoursDayLostPrev[day] += minutes/60;
        } else {
          reservationsDayNormalPrev[day]++;
          hoursDayNormalPrev[day] += minutes/60;
        }
      });

      const reservationsDayChartData = {
        labels: dayLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservations'),
            backgroundColor: 'rgba(77,182,172,0.2)',
            borderColor: 'rgba(77,182,172,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(77,182,172,0.4)',
            hoverBorderColor: 'rgba(77,182,172,1)',
            data: reservationsDayNormal,
            reservations: reservationsDayDataNormal,
          },
          {
            label: L20NContext.getSync('reservationsLost'),
            backgroundColor: 'rgba(241, 130, 141,0.2)',
            borderColor: 'rgba(241, 130, 141,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(241, 130, 141,0.4)',
            hoverBorderColor: 'rgba(241, 130, 141,1)',
            data: reservationsDayLost,
            reservations: reservationsDayDataLost,
          },
          {
            label: L20NContext.getSync('reservationsPenalty'),
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderColor: 'rgba(255,167,38,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,167,38,0.4)',
            hoverBorderColor: 'rgba(255,167,38,1)',
            data: reservationsDayPenalty,
            reservations: reservationsDayDataPenalty,
          },
        ]
      };

      const hoursDayChartData = {
        labels: dayLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsHoursUsed'),
            backgroundColor: 'rgba(77,182,172,0.2)',
            borderColor: 'rgba(77,182,172,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(77,182,172,0.4)',
            hoverBorderColor: 'rgba(77,182,172,1)',
            data: hoursDayNormal.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsDayDataNormal,
          },
          {
            label: L20NContext.getSync('reservationsHoursLost'),
            backgroundColor: 'rgba(241, 130, 141,0.2)',
            borderColor: 'rgba(241, 130, 141,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(241, 130, 141,0.4)',
            hoverBorderColor: 'rgba(241, 130, 141,1)',
            data: hoursDayLost.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsDayDataLost,
          },
          {
            label: L20NContext.getSync('reservationsHoursPenalty'),
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderColor: 'rgba(255,167,38,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,167,38,0.4)',
            hoverBorderColor: 'rgba(255,167,38,1)',
            data: hoursDayPenalty.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsDayDataPenalty,
          },
        ]
      };

      const totalReservationsDay = reservationsDayNormal.map((value, index) => value + reservationsDayPenalty[index] + reservationsDayLost[index]);
      const totalHoursDay = hoursDayNormal.map((value, index) => value + hoursDayPenalty[index] + hoursDayLost[index]);
      const totalReservationsDayData = Array.from({ length: 7 }, (_, index) => {
        return [
          ...reservationsDayDataNormal[index],
          ...reservationsDayDataLost[index],
          ...reservationsDayDataPenalty[index]
        ];
      });

      const totalReservationsDayPrev = reservationsDayNormalPrev.map((value, index) => value + reservationsDayPenaltyPrev[index] + reservationsDayLostPrev[index]);
      const totalHoursDayPrev = hoursDayNormalPrev.map((value, index) => value + hoursDayPenaltyPrev[index] + hoursDayLostPrev[index]);

      const reservationsDayChartDataPrev = {
        labels: dayLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: totalReservationsDayPrev,
            reservations: [],
          },
          {
            label: L20NContext.getSync('reservations'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: totalReservationsDay,
            reservations: totalReservationsDayData,
          },
        ]
      };
      const hoursDayChartDataPrev = {
        labels: dayLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsHoursTotalPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: totalHoursDayPrev.map(e=>{return Math.round(10*e)/10}),
            reservations: [],
          },
          {
            label: L20NContext.getSync('reservationsHoursTotal'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: totalHoursDay.map(e=>{return Math.round(10*e)/10}),
            reservations: totalReservationsDayData,
          },
        ]
      };

      const weeksBetween = Math.floor(daysBetween/7) || 1;
      
      const reservationsDayChartData_avg = _.cloneDeep(reservationsDayChartData)
      reservationsDayChartData_avg.datasets[0].data = _.map(reservationsDayNormal,e=>{return Math.round(100*e/weeksBetween)/100})
      reservationsDayChartData_avg.datasets[1].data = _.map(reservationsDayLost,e=>{return Math.round(100*e/weeksBetween)/100})
      reservationsDayChartData_avg.datasets[2].data = _.map(reservationsDayPenalty,e=>{return Math.round(100*e/weeksBetween)/100})
      const hoursDayChartData_avg = _.cloneDeep(hoursDayChartData)
      hoursDayChartData_avg.datasets[0].data = _.map(hoursDayNormal,e=>{return Math.round(100*e/weeksBetween)/100})
      hoursDayChartData_avg.datasets[1].data = _.map(hoursDayLost,e=>{return Math.round(100*e/weeksBetween)/100})
      hoursDayChartData_avg.datasets[2].data = _.map(hoursDayPenalty,e=>{return Math.round(100*e/weeksBetween)/100})
      
      const reservationsDayChartData_avgPrev = _.cloneDeep(reservationsDayChartDataPrev)
      reservationsDayChartData_avgPrev.datasets[0].data = _.map(totalReservationsDayPrev,e=>{return Math.round(100*e/weeksBetween)/100})
      reservationsDayChartData_avgPrev.datasets[1].data = _.map(totalReservationsDay,e=>{return Math.round(100*e/weeksBetween)/100})
      const hoursDayChartData_avgPrev = _.cloneDeep(hoursDayChartDataPrev)
      hoursDayChartData_avgPrev.datasets[0].data = _.map(totalHoursDayPrev,e=>{return Math.round(100*e/weeksBetween)/100})
      hoursDayChartData_avgPrev.datasets[1].data = _.map(totalHoursDay,e=>{return Math.round(100*e/weeksBetween)/100})


      // PER MESE DELL'ANNO
      
      const monthLabels = [
        'january', 'february', 'march', 'april', 'may', 'june', 
        'july', 'august', 'september', 'october', 'november', 'december'
      ].map(e=>{return L20NContext.getSync(e)})
      
      const reservationsMonthNormal = new Array(12).fill(0);
      const reservationsMonthPenalty = new Array(12).fill(0);
      const reservationsMonthLost = new Array(12).fill(0);
      const hoursMonthNormal = new Array(12).fill(0);
      const hoursMonthPenalty = new Array(12).fill(0);
      const hoursMonthLost = new Array(12).fill(0);
      const reservationsMonthDataNormal = Array.from({ length: 12 }, () => []);
      const reservationsMonthDataPenalty = Array.from({ length: 12 }, () => []);
      const reservationsMonthDataLost = Array.from({ length: 12 }, () => []);
      
      const reservationsMonthNormalPrev = new Array(12).fill(0);
      const reservationsMonthPenaltyPrev = new Array(12).fill(0);
      const reservationsMonthLostPrev = new Array(12).fill(0);
      const hoursMonthNormalPrev = new Array(12).fill(0);
      const hoursMonthPenaltyPrev = new Array(12).fill(0);
      const hoursMonthLostPrev = new Array(12).fill(0);
      
      validReservations.forEach(reservation => {
        const month = moment(reservation.fromDate).month();
        
        if (isReservationPenalty(reservation.state)) {
          reservationsMonthPenalty[month]++;
          reservationsMonthDataPenalty[month].push(reservation);
        } else if (isReservationLost(reservation.state)) {
          reservationsMonthLost[month]++;
          reservationsMonthDataLost[month].push(reservation);
        } else {
          reservationsMonthNormal[month]++;
          reservationsMonthDataNormal[month].push(reservation);
        }
      
        const minutes = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
        if (isReservationPenalty(reservation.state)) {
          hoursMonthPenalty[month] += minutes/60;
        } else if (isReservationLost(reservation.state)) {
          hoursMonthLost[month] += minutes/60;
        } else {
          hoursMonthNormal[month] += minutes/60;
        }
      });
      
      validReservationsPrev.forEach(reservation => {
        const month = moment(reservation.fromDate).month();
        
        if (isReservationPenalty(reservation.state)) {
          reservationsMonthPenaltyPrev[month]++;
        } else if (isReservationLost(reservation.state)) {
          reservationsMonthLostPrev[month]++;
        } else {
          reservationsMonthNormalPrev[month]++;
        }
      
        const minutes = moment(reservation.toDate).diff(moment(reservation.fromDate), 'minutes');
        if (isReservationPenalty(reservation.state)) {
          hoursMonthPenaltyPrev[month] += minutes/60;
        } else if (isReservationLost(reservation.state)) {
          hoursMonthLostPrev[month] += minutes/60;
        } else {
          hoursMonthNormalPrev[month] += minutes/60;
        }
      });
      
      const reservationsMonthChartData = {
        labels: monthLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservations'),
            backgroundColor: 'rgba(77,182,172,0.2)',
            borderColor: 'rgba(77,182,172,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(77,182,172,0.4)',
            hoverBorderColor: 'rgba(77,182,172,1)',
            data: reservationsMonthNormal,
            reservations: reservationsMonthDataNormal,
          },
          {
            label: L20NContext.getSync('reservationsLost'),
            backgroundColor: 'rgba(241, 130, 141,0.2)',
            borderColor: 'rgba(241, 130, 141,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(241, 130, 141,0.4)',
            hoverBorderColor: 'rgba(241, 130, 141,1)',
            data: reservationsMonthLost,
            reservations: reservationsMonthDataLost,
          },
          {
            label: L20NContext.getSync('reservationsPenalty'),
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderColor: 'rgba(255,167,38,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,167,38,0.4)',
            hoverBorderColor: 'rgba(255,167,38,1)',
            data: reservationsMonthPenalty,
            reservations: reservationsMonthDataPenalty,
          },
        ]
      };
      const hoursMonthChartData = {
        labels: monthLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsHoursUsed'),
            backgroundColor: 'rgba(77,182,172,0.2)',
            borderColor: 'rgba(77,182,172,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(77,182,172,0.4)',
            hoverBorderColor: 'rgba(77,182,172,1)',
            data: hoursMonthNormal.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsMonthDataNormal,
          },
          {
            label: L20NContext.getSync('reservationsHoursLost'),
            backgroundColor: 'rgba(241, 130, 141,0.2)',
            borderColor: 'rgba(241, 130, 141,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(241, 130, 141,0.4)',
            hoverBorderColor: 'rgba(241, 130, 141,1)',
            data: hoursMonthLost.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsMonthDataLost,
          },
          {
            label: L20NContext.getSync('reservationsHoursPenalty'),
            backgroundColor: 'rgba(255,167,38,0.2)',
            borderColor: 'rgba(255,167,38,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,167,38,0.4)',
            hoverBorderColor: 'rgba(255,167,38,1)',
            data: hoursMonthPenalty.map(e=>{return Math.round(10*e)/10}),
            reservations: reservationsMonthDataPenalty,
          },
        ]
      };
      
      const totalReservationsMonth = reservationsMonthNormal.map((value, index) => value + reservationsMonthPenalty[index] + reservationsMonthLost[index]);
      const totalHoursMonth = hoursMonthNormal.map((value, index) => value + hoursMonthPenalty[index] + hoursMonthLost[index]);
      const totalReservationsMonthData = Array.from({ length: 12 }, (_, index) => {
        return [
          ...reservationsMonthDataNormal[index],
          ...reservationsMonthDataLost[index],
          ...reservationsMonthDataPenalty[index]
        ];
      });
      
      const totalReservationsMonthPrev = reservationsMonthNormalPrev.map((value, index) => value + reservationsMonthPenaltyPrev[index] + reservationsMonthLostPrev[index]);
      const totalHoursMonthPrev = hoursMonthNormalPrev.map((value, index) => value + hoursMonthPenaltyPrev[index] + hoursMonthLostPrev[index]);
      
      const reservationsMonthChartDataPrev = {
        labels: monthLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: totalReservationsMonthPrev,
            reservations: [],
          },
          {
            label: L20NContext.getSync('reservations'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: totalReservationsMonth,
            reservations: totalReservationsMonthData,
          },
        ]
      };
      const hoursMonthChartDataPrev = {
        labels: monthLabels,
        datasets: [
          {
            label: L20NContext.getSync('reservationsHoursTotalPrev'),
            backgroundColor: 'rgba(127,127,127,0.2)',
            borderColor: 'rgba(127,127,127,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(127,127,127,0.4)',
            hoverBorderColor: 'rgba(127,127,127,1)',
            data: totalHoursMonthPrev.map(e=>{return Math.round(10*e)/10}),
            reservations: [],
          },
          {
            label: L20NContext.getSync('reservationsHoursTotal'),
            backgroundColor: 'rgba(27,151,194,0.2)',
            borderColor: 'rgba(27,151,194,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(27,151,194,0.4)',
            hoverBorderColor: 'rgba(27,151,194,1)',
            data: totalHoursMonth.map(e=>{return Math.round(10*e)/10}),
            reservations: totalReservationsMonthData,
          },
        ]
      };

      const yearsBetween = Math.floor(daysBetween/365) || 1;
      
      const reservationsMonthChartData_avg = _.cloneDeep(reservationsMonthChartData)
      reservationsMonthChartData_avg.datasets[0].data = _.map(reservationsMonthNormal,e=>{return Math.round(100*e/yearsBetween)/100})
      reservationsMonthChartData_avg.datasets[1].data = _.map(reservationsMonthLost,e=>{return Math.round(100*e/yearsBetween)/100})
      reservationsMonthChartData_avg.datasets[2].data = _.map(reservationsMonthPenalty,e=>{return Math.round(100*e/yearsBetween)/100})
      const hoursMonthChartData_avg = _.cloneDeep(hoursMonthChartData)
      hoursMonthChartData_avg.datasets[0].data = _.map(hoursMonthNormal,e=>{return Math.round(100*e/yearsBetween)/100})
      hoursMonthChartData_avg.datasets[1].data = _.map(hoursMonthLost,e=>{return Math.round(100*e/yearsBetween)/100})
      hoursMonthChartData_avg.datasets[2].data = _.map(hoursMonthPenalty,e=>{return Math.round(100*e/yearsBetween)/100})
      
      const reservationsMonthChartData_avgPrev = _.cloneDeep(reservationsMonthChartDataPrev)
      reservationsMonthChartData_avgPrev.datasets[0].data = _.map(totalReservationsMonthPrev,e=>{return Math.round(100*e/yearsBetween)/100})
      reservationsMonthChartData_avgPrev.datasets[1].data = _.map(totalReservationsMonth,e=>{return Math.round(100*e/yearsBetween)/100})
      const hoursMonthChartData_avgPrev = _.cloneDeep(hoursMonthChartDataPrev)
      hoursMonthChartData_avgPrev.datasets[0].data = _.map(totalHoursMonthPrev,e=>{return Math.round(100*e/yearsBetween)/100})
      hoursMonthChartData_avgPrev.datasets[1].data = _.map(totalHoursMonth,e=>{return Math.round(100*e/yearsBetween)/100})

      // FINE
      dispatch(saveBookeyAnalyticsReservations({
        reservationsData: validReservations,
        weeklyWarningON: daysBetween%7!==0 && daysBetween<7*7,
        reservationsHourlyChartData: reservationsHourlyChartData,
        hoursHourlyChartData: hoursHourlyChartData,
        reservationsHourlyChartData_avg: reservationsHourlyChartData_avg,
        hoursHourlyChartData_avg: hoursHourlyChartData_avg,
    
        reservationsHourlyChartDataPrev: reservationsHourlyChartDataPrev,
        hoursHourlyChartDataPrev: hoursHourlyChartDataPrev,
        reservationsHourlyChartData_avgPrev: reservationsHourlyChartData_avgPrev,
        hoursHourlyChartData_avgPrev: hoursHourlyChartData_avgPrev,

        reservationsDayChartData: reservationsDayChartData,
        hoursDayChartData: hoursDayChartData,
        reservationsDayChartData_avg: reservationsDayChartData_avg,
        hoursDayChartData_avg: hoursDayChartData_avg,

        reservationsDayChartDataPrev: reservationsDayChartDataPrev,
        hoursDayChartDataPrev: hoursDayChartDataPrev,
        reservationsDayChartData_avgPrev: reservationsDayChartData_avgPrev,
        hoursDayChartData_avgPrev: hoursDayChartData_avgPrev,

        reservationsMonthChartData: reservationsMonthChartData,
        hoursMonthChartData: hoursMonthChartData,
        reservationsMonthChartData_avg: reservationsMonthChartData_avg,
        hoursMonthChartData_avg: hoursMonthChartData_avg,

        reservationsMonthChartDataPrev: reservationsMonthChartDataPrev,
        hoursMonthChartDataPrev: hoursMonthChartDataPrev,
        reservationsMonthChartData_avgPrev: reservationsMonthChartData_avgPrev,
        hoursMonthChartData_avgPrev: hoursMonthChartData_avgPrev,
        
        reservationsPieChartData: reservationsPieChartData,
      }));
    } catch (error) {
      throw error;
    }
  };
}
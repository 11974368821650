import { IconButton, RadioGroup, withStyles } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import Warning from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { RED } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';
import MDRadioButtonView from '../forms/Elements/MDRadioButtonsGroup/MDRadioButtonView.js';


const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 18,
    lineHeight: 1.4,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  warningTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: -20,
  },
  guestCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestDataText: {
    fontWeight: 'bold',
    margin: 0,
    color: '#3f3f3f',
    marginTop: 2,
  },
});

class GuestDeleteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oblivionSelected: false,
    }
  }
  render() {
    const { classes, guest, onDeleteGuestConfirm, onCloseModal } = this.props;
    const { oblivionSelected } = this.state;
    return (
      <div style={{ marginLeft: 13, marginBottom: 30, padding: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }} />
          <IconButton onClick={() => onCloseModal()}>
            <Cancel style={{ color: '#e95841', fontSize: 35 }} />
          </IconButton>
        </div>
        <div className={classes.warningTitleContainer}>
          <Warning style={{ color: 'orange', fontSize: 30 }} />
          <h2 className={classes.titleText} style={{ margin: 0, marginLeft: 10, fontSize: 25 }}><Entity entity="warning" /></h2>
        </div>
        {guest && guest.firstname && guest.lastname && guest.email ? (
          <div className={classes.guestCardContainer}>
            <h2 className={classes.sectionText} style={{ color: '#3f3f3f' }}><Entity entity="guestToDelete" /></h2>
            <h4 className={classes.guestDataText}>{`${guest.firstname} ${guest.lastname} (${guest.email})`}</h4>
          </div>
        ) : null}
        <h2 className={classes.sectionText} style={{ marginTop: 20 }}><Entity entity="deleteUserWarningTemporary" />.</h2>
        <h2 className={classes.sectionText} style={{ marginTop: 20, fontSize: 17 }}><Entity entity="deleteOption" />:</h2>
        <div>
          <RadioGroup
            aria-label="oblivion-group"
            name="oblivion"
            value={oblivionSelected}
            style={{ flexDirection: 'column', marginTop: 5 }}
          >
            <MDRadioButtonView
              key={0}
              checked={!oblivionSelected}
              handleChange={e => this.setState({ oblivionSelected: false })}
              labelStyle={{ color: '#3f3f3f', fontSize: 16 }}
              label={<Entity entity="deleteGuestPartial" />}
            />
            <MDRadioButtonView
              key={1}
              checked={oblivionSelected}
              handleChange={e => this.setState({ oblivionSelected: true })}
              label={<Entity entity="deleteGuestFull" />}
              labelStyle={{ color: '#3f3f3f', fontSize: 16 }}
            />
        </RadioGroup>
        </div>
          <h2 className={classes.sectionText} style={{ marginTop: 10 }}><Entity entity="withTheSelectedOption" />:</h2>
        {oblivionSelected ? (
          <div>
            <ul>
              <li><h2 style={{ marginTop: 10 }} className={classes.sectionText}>•<Entity key={oblivionSelected} entity="deleteUserWarningDotTwo" style={{ marginLeft: 10 }} /></h2></li>
              <li><h2 className={classes.sectionText}>•<Entity key={oblivionSelected} entity="deleteUserWarningDotThree" style={{ marginLeft: 10 }} /></h2></li>
            </ul>
            </div>
        ) : <div><ul><li><h2 style={{ marginTop: 10 }} className={classes.sectionText}>•<Entity key={oblivionSelected} entity="deleteUserWarningTemporaryTwo" style={{ marginLeft: 10 }} /></h2></li></ul></div>}
        <h2 className={classes.sectionText} style={{ marginTop: 10 }}><Entity entity="deactivateUserSuggestion" />.</h2>
        <MDButton
          title={<Entity entity="confirmDeleteUser" />}
          backgroundColor={RED}
          onClick={() => onDeleteGuestConfirm(oblivionSelected)}
        />
      </div>
    )
  }
}
export default withStyles(styles)(GuestDeleteView);

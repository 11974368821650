/* eslint-disable no-use-before-define */
// @ts-nocheck
import * as amplitude from '@amplitude/analytics-browser';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { push } from 'react-router-redux';
import { v4 as uuidv4 } from 'uuid';
import * as BookeyAPI from '../../_config/bookeyAPI';
import * as conf from '../../_config/conf';
import { FRESHDESK_DEMO_REQUEST_GROUP_ID, INTEGRATIONS_VALUES_MAP, LICENSES, LICENSE_TYPES, ROLES, STORE_ITEMS, SUBSCRIPTION_TYPES, WALLET_STATUSES, forbiddenManagerEmailAddresses } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as FreshDeskAPI from '../../_config/freshdeskAPI';
import * as IntegrationsAPI from '../../_config/integrationsAPI';
import * as RestService from '../../_config/rest';
import { calculateNextRechargeDate, createHostNameFromPlantName, elaborateWarningThresholds, formatClusterForZenDeskPortal, formatDomainHostNameToFriendlyName, getIseoZendeskParameters, getNotificationTypeStatus, getPlantName, isValidLuckeyDomain, saveDataToLocalStorage } from '../../_config/utils';
import * as ZendeskAPI from '../../_config/zendeskAPI';
import * as InvitationsAPI from '../../_config/invitationsAPI'
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import PermissionsParserV0 from '../../permissionsUtils/PermissionsParserV0';
import * as AmplitudeService from '../../services/AmplitudeService';
import {
  LOGOUT,
  RESET_SET_PASSWORD_FIELDS,
  SAVE_COMPANY_CONFIGURATIONS,
  SAVE_COMPANY_INFO,
  SAVE_CURRENT_ACTIVE_INTEGRATIONS,
  SAVE_DOMAIN_LICENSE, SAVE_SET_PASSWORD_FIELD, SAVE_STORE_ITEMS,
  SAVE_SYSTEM_HAS_ATLAS,
  SAVE_USER_DATA, SAVE_USER_DOMAINS,
  SET_CHAINELS_AUTH_PARAMETERS,
  SET_LOGIN_FIELD,
  SET_NEXUDUS_CLIENT_ID,
  SET_USER_AUTHENTICATION,
  SET_USER_TOKEN_INFO,
  SET_OCTORATE_ACTIVATION_PARAMETERS,
} from './actionTypes/user';
import * as CreditsActions from './cloudCredits.actions';
import * as CredentialsActions from './credential.actions';
import * as GuestActions from './guest.actions';
import * as MetricsActions from './metrics.actions';
import * as ModalActions from './modal.actions';
import * as RolesActions from './roles.actions';
import * as SettingActions from './setting.actions';
import * as SettingsActions from './setting.actions';
import * as UtilsActions from './utils.actions';


export function editField(field, value) {
  return {
    type: SET_LOGIN_FIELD,
    payload: {
      field,
      value,
    },
  };
}

export function saveSetPasswordField(field, value) {
  return {
    type: SAVE_SET_PASSWORD_FIELD,
    field,
    value,
  };
}

export function resetSetPasswordFields() {
  return { type: RESET_SET_PASSWORD_FIELDS };
}

export function saveTokenInfo(tokenInfo) {
  return {
    type: SET_USER_TOKEN_INFO,
    tokenInfo,
  };
}

export function saveCompanyConfigurations(configurations) {
  return {
    type: SAVE_COMPANY_CONFIGURATIONS,
    configurations,
  };
}

export function saveUserDomains(domains) {
  return {
    type: SAVE_USER_DOMAINS,
    domains,
  };
}

export function saveDomainLicenseInfo(licenseInfo) {
  return {
    type: SAVE_DOMAIN_LICENSE,
    licenseInfo,
  };
}

export function systemHasAtlasGateways(hasAtlas) {
  return {
    type: SAVE_SYSTEM_HAS_ATLAS,
    hasAtlas,
  };
}

export function saveStoreItemsInState(items) {
  return {
    type: SAVE_STORE_ITEMS,
    items,
  };
}

export function saveCompanyInfo(companyInfo) {
  return {
    type: SAVE_COMPANY_INFO,
    companyInfo,
  }
}

export function saveActiveIntegrations(integrations) {
  return {
    type: SAVE_CURRENT_ACTIVE_INTEGRATIONS,
    integrations,
  };
}

export function setNexudusClientId(clientId) {
  return {
    type: SET_NEXUDUS_CLIENT_ID,
    clientId,
  };
}

export function setChainelsAuthParameters(authParameters) {
  return {
    type: SET_CHAINELS_AUTH_PARAMETERS,
    authParameters,
  };
}

export function setOctorateActivationParameters(activationParameters) {
  return {
    type: SET_OCTORATE_ACTIVATION_PARAMETERS,
    activationParameters,
  };
}


function saveUserInfo(userData, saveToLocalStorage = false) {
  return async (dispatch, getState) => {
    if (saveToLocalStorage) saveDataToLocalStorage('user', jwt.sign(userData, conf.JWT_ENCRIPTION_KEY));
    dispatch(SettingActions.setLanguage(userData.languageType));
    dispatch({
      type: SAVE_USER_DATA,
      userData,
    });
  };
}

export function fetchCurrentUser() {
  return async (dispatch) => {
    try {
      const userResponse = await RestService.fetchCurrentUser();
      if (userResponse && userResponse.data) {
        dispatch(saveUserInfo(userResponse.data, true));
        return userResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function setUserAuthenticated(value, saveToLocalStorage = true) {
  if (saveToLocalStorage) saveDataToLocalStorage('isAuthenticated', value);
  return {
    type: SET_USER_AUTHENTICATION,
    authenticated: value,
  };
}

export function applicationStartup(accessToken) {
  return async (dispatch, getState) => {
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      RestService.interceptorEjectRequest();
      BookeyAPI.interceptorEjectRequest();
      dispatch(processAndStoreAccessToken(accessToken));
      const decodedToken = jwt.decode(accessToken);
      const permissions = decodedToken.jago.permissions;
      const isLuckeyEngine = AbilityProvider.getAbilityHelper().hasLicenseType(LICENSE_TYPES.ENGINE);
      const formattedPermissions = _.map(permissions, (k, v) => ({ id: k, name: v }));
      await dispatch(RolesActions.fetchPermissions());
      const allPermissions = getState().roles.permissionsParsed;
      const abilities = PermissionsParserV0.parseArrayWithAll(formattedPermissions, allPermissions);
      if (isLuckeyEngine) {
        const formattedAbilities = _.map(abilities, ability => ({...ability, action: 'READ' }));
        AbilityProvider.getAbilityHelper().updateAbilities(formattedAbilities);
      } else {
        AbilityProvider.getAbilityHelper().updateAbilities(abilities);
      }
      await dispatch(fetchCompanyConfiguration());
      await dispatch(fetchCompanyInfo());
      dispatch(MetricsActions.saveAccessChartFilter('startDate', moment().startOf('day').valueOf()));
      dispatch(MetricsActions.saveAccessChartFilter('endDate', moment().endOf('day').valueOf()));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(push('/'));
      dispatch(editField('password', ''));
    } catch (reason) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(editField('password', ''));
      if (reason.response && reason.response.data && reason.response.data.error === 'invalid_grant') {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'BAD_CREDENTIALS' },
        }));
      } else {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'UNEXPECTED_ERROR' },
        }));
      }
    }
  };
}

export function processAndStoreAccessToken(accessToken) {
  return (dispatch) => {
    RestService.interceptorEjectRequest();
    BookeyAPI.interceptorEjectRequest();
    dispatch(setUserAuthenticated(true));
    saveDataToLocalStorage('token', accessToken);
    RestService.setRequestInterceptor(accessToken);
    BookeyAPI.setRequestInterceptor(accessToken);
  };
}

export function refreshTokenAndStoreAccessToken(refreshToken) {
  return async (dispatch) => {
    const response = await RestService.refreshToken(refreshToken);
    const { access_token } = response.data;
    dispatch(processAndStoreAccessToken(access_token));
  };
}

export function getTokensFromSAMLCode(code) {
  return async (dispatch) => {
    const response = await RestService.getTokensFromSAMLCode(code);
    const { access_token } = response.data;
    await dispatch(processAndStoreAccessToken(access_token));
    const user = await dispatch(fetchCurrentUser());
    const decodedJWT = jwt.decode(response.data.access_token);
    if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_INSTALLER') {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="guestLoginNotAllowed" /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } else if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_GUEST') {
      dispatch(processAndStoreAccessToken(response.data.access_token));
      dispatch(push('/remoteOpen'));
    } else if (user && user.using2FA) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(push('/login'));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="ssoTwoFactorError" /> },
      }));
    }
  };
}

export function verifyUser(username, password) {
  return async (dispatch, getState) => {
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      RestService.interceptorEjectRequest();
      BookeyAPI.interceptorEjectRequest();
      const response = await RestService.authenticate(username, password);
      RestService.setRequestInterceptor(response.data.access_token);
      const decodedJWT = jwt.decode(response.data.access_token);
      const user = await dispatch(fetchCurrentUser());
      if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_INSTALLER') {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="guestLoginNotAllowed" /> },
        }));
        dispatch(push('/login'));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } else if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_GUEST') {
        dispatch(processAndStoreAccessToken(response.data.access_token));
        dispatch(push('/remoteOpen'));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } else if (user && user.using2FA) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(push('/loginTwoFactor'));
        dispatch(editField('password', ''));
      } else {
        amplitude.setUserId(String(user.id));
        return dispatch(applicationStartup(response.data.access_token));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(editField('password', ''));
      if (error.response && error.response.data && error.response.data.error === 'invalid_grant') {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'BAD_CREDENTIALS' },
        }));
      } else {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'UNEXPECTED_ERROR' },
        }));
      }
    }
  };
}


export function verifyOTPCode(userId, otpCode) {
  return async (dispatch, getState) => {
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const otpVerifyResponse = await RestService.verifyOTPCode(userId, otpCode);
      dispatch(UtilsActions.setSpinnerVisibile(false));
      if (otpVerifyResponse && otpVerifyResponse.data && otpVerifyResponse.data.access_token) {
        dispatch(applicationStartup(otpVerifyResponse.data.access_token));
        return otpVerifyResponse.access_token;
      }
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', message: 'errorOtpVerify' },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', message: 'errorOtpVerify' },
      }));
    }
  };
}

export function setUserInfoFromLocalStorage() {
  return async (dispatch, getState) => {
    const user = getState().user;
    const decodedJWT = jwt.decode(localStorage.user, conf.JWT_ENCRIPTION_KEY);
    if (decodedJWT) {
      dispatch(saveUserInfo(decodedJWT, false));
      if (localStorage.isAuthenticated) {
        const currentPlant = getPlantName();
        amplitude.setUserId(String(decodedJWT.id));
        dispatch(setUserAuthenticated(JSON.parse(localStorage.isAuthenticated), false));
      }
    }
  };
}

export function setLicenseInfoFromLocalStorage() {
  return (dispatch, getState) => {
    const license = getState().settings.items.license;
    if (_.keys(license.data).length <= 0) {
      const decodedJWT = jwt.decode(localStorage.license, conf.JWT_ENCRIPTION_KEY);
      if (decodedJWT) {
        dispatch(SettingActions.saveSetting('license', decodedJWT));
      }
    }
    return Promise.resolve();
  };
}

export function setTokenInfoFromLocalStorage() {
  return (dispatch, getState) => {
    const tokenInfo = getState().user.token;
    if (_.keys(tokenInfo).length <= 0) {
      const decodedJWT = jwt.decode(localStorage.token);
      if (decodedJWT) {
        const plantName = decodedJWT.hostname ? formatDomainHostNameToFriendlyName(decodedJWT.hostname) : null;
        dispatch(saveTokenInfo( { ...decodedJWT, plantName }));
      }
    }
    return Promise.resolve();
  };
}

export function setInfoFromLocalStorage() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(setUserInfoFromLocalStorage()), dispatch(setTokenInfoFromLocalStorage())]);
  };
}

export function confirmPassword(userId, token, values) {
  return async (dispatch, getState) => {
    try {
      const formattedValues = {
        password: values.password,
        token,
      };

      const response = await RestService.confirmPassword(userId, formattedValues)
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let appUrl = '';
      if (response.data) {
        if (/windows phone/i.test(userAgent)) {
          appUrl = 'https://www.apple.com/iphone/';
        } else if (/android/i.test(userAgent)) {
          appUrl = response.data.jagoAppDownloadLinkAndroid;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          appUrl = response.data.jagoAppDownloadLinkIOS;
        }
        if (response.data.jagoAppDownloadLinkAndroid) dispatch(saveSetPasswordField('android', response.data.jagoAppDownloadLinkAndroid));
        if (response.data.jagoAppDownloadLinkIOS) dispatch(saveSetPasswordField('ios', response.data.jagoAppDownloadLinkIOS));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function disableUser(userId) {
  return async (dispatch, getState) => {
    try {
      if (userId === getState().user.data.id) {
        return dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'SELF_DISABLE' },
        }));
      }
      const userData = await RestService.disableUser(userId);
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'userDisabled' }} /></h6>),
        },
      }));
      if (userData && userData.data) return userData.data;
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteUser(userId, oblivionSelected) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteUser(userId, oblivionSelected);
      if (response && response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function saveLanguage(language) {
  return (dispatch, getState) => {
    return RestService.setUserLanguage(language)
      .then((response) => {
        if (response.data) {
          const saveToLocalStorage = true;
          dispatch(saveUserInfo(response.data, saveToLocalStorage));
        }
      })
      .catch(() => { });
  };
}

export function resetPassword(userId) {
  return async (dispatch, getState) => {
    try {
      await RestService.resetPassword(userId)
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'userPasswordRestore' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="resetPasswordError" /></h6>),
        },
      }));
      throw error;
    }
  };
}

export function generatePasswordRecoveryLink(userId) {
  return async (dispatch, getState) => {
    try {
      const recoverLinkData = await RestService.generatePasswordRecoveryLink(userId);
      if (recoverLinkData && recoverLinkData.data && recoverLinkData.data.url) {
        return recoverLinkData.data.url;
      }
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="resetPasswordError" /></h6>),
        },
      }));
      throw error;
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="resetPasswordError" /></h6>),
        },
      }));
      throw error;
    }
  };
}

export function activateUser(userId) {
  return (dispatch, getState) => {
    return RestService.resetPassword(userId)
      .then(() => {
        dispatch(ModalActions.showModal({
          modalType: 'USER_PASSWORD_RESET_MODAL',
        }));
      })
      .catch(() => {
      });
  };
}

export function forgotPassword(email) {
  return async (dispatch, getState) => {
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await RestService.forgotPassword({ email });
      dispatch(ModalActions.showModal({
        modalType: 'MANAGER_PASSWORD_RESET_MODAL',
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (reason) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({ modalType: 'MANAGER_PASSWORD_RESET_MODAL' }));
    }
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
    dispatch(setUserAuthenticated(false));
    dispatch(UtilsActions.setSpinnerVisibile(false));
    dispatch(GuestActions.resetGuestsData());
    dispatch(GuestActions.resetGuestsFilters());
    dispatch(CredentialsActions.resetCredentialsData());
    dispatch(MetricsActions.resetAccessChartFilters());
    dispatch(MetricsActions.resetMetricsData());
    AmplitudeService.reset();
    RestService.interceptorEjectRequest();
    BookeyAPI.interceptorEjectRequest();
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    dispatch(push('/login'));
  };
}

export function fetchUserDomains() {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.fetchUserDomains();
      const plantsToWhitelist = getState().user.companyConfigurations.plants;
      if (response && response.data) {
        const domains = response.data;
        // filter plants where user is not guest
        const filteredDomainsByRoles = _.filter(domains, domain => domain.roles && _.find(domain.roles, role => role.name !== ROLES.GUEST && role.name !== ROLES.INSTALLER));
        const formattedDomains = _.map(filteredDomainsByRoles, domain => formatter.formatInputData('DOMAIN', domain));
        let filteredDomainWhiteListed = formattedDomains;
        if (plantsToWhitelist) {
          // show only domains that are in the current company domains
          filteredDomainWhiteListed = _.filter(formattedDomains, domain => _.find(plantsToWhitelist, { name: domain.domainName }));
        }
        const userData = getState().user;
        // hide current plant name
        if (userData && userData.token && userData.token.hostname) {
          filteredDomainWhiteListed = _.filter(filteredDomainWhiteListed, domain => domain.hostName !== userData.token.hostname);
        }
        dispatch(saveUserDomains(filteredDomainWhiteListed));
        return filteredDomainWhiteListed;
      }
    } catch (error) { }
  };
}

export function fetchCompanyConfiguration() {
  return async (dispatch, getState) => {
    try {
      const isDomainValid = isValidLuckeyDomain();
      if (!isDomainValid) {
        dispatch(push('/serviceNotFound'));
        return;
      }
      const userData = getState().user.data;
      let configJSON = conf.getDefaultCompanyConfiguration();
      dispatch(saveCompanyConfigurations(configJSON));
      const configurationResponse = await RestService.fetchCompanyConfiguration();
      if (configurationResponse && configurationResponse.data && configurationResponse.data.data) {
        try {
          const companyConfigJSON = JSON.parse(configurationResponse.data.data);
          configJSON = { ...configJSON, ...companyConfigJSON };
        } catch (error) {
          // Ignore error
        }
        dispatch(saveCompanyConfigurations(configJSON));
        // Avoid fetch user domain for switch if email address is forbidden
      }
      const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
      if (userData && _.indexOf(forbiddenManagerEmailAddresses, _.trim(userData.email)) === -1 && !isLuckeyLite) {
        dispatch(fetchUserDomains());
      }
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 10801) {
        dispatch(push('/serviceNotFound'));
      }
      // Ignore error, go on with cached data
    }
  };
}

export function isF9000AddonActive() {
  return (dispatch, getState) => {
    return dispatch(userHasSubscriptions(SUBSCRIPTION_TYPES.F9000)) || dispatch(userHasSubscriptions(SUBSCRIPTION_TYPES.V364));
  };
}

export function userHasSubscriptions(licenseName) {
  return (dispatch, getState) => {
    const { user: { license } } = getState();
    if (!licenseName) return true;
    const subscriptions = license && license.subscriptions;
    if (!_.isEmpty(subscriptions)) {
      if (_.find(license.subscriptions, subscription => subscription.resourceType === licenseName)) {
        return true;
      }
      return false;
    }
    return false;
  };
}

export function userHasIntegrationActive(integrationName) {
  return (dispatch, getState) => {
    const { user: { activeIntegrations } } = getState();
    const integrationServiceName = INTEGRATIONS_VALUES_MAP[integrationName] && INTEGRATIONS_VALUES_MAP[integrationName].serviceName;
    if (!integrationName || !integrationServiceName) return false;
    if (_.find(activeIntegrations, integration => integration.serviceName === integrationServiceName)) {
      return true;
    }
    return false;
  };
}

export function getIntegrationAdditionalInfo(integrationName) {
  return (dispatch, getState) => {
    const { user: { activeIntegrations } } = getState();
    const integrationServiceName = INTEGRATIONS_VALUES_MAP[integrationName] && INTEGRATIONS_VALUES_MAP[integrationName].serviceName;
    if (!integrationName || !integrationServiceName) return null;
    return _.find(activeIntegrations, integration => integration.serviceName === integrationServiceName);
  };
}

export function getActiveIntegration(integrationName) {
  return (dispatch, getState) => {
    const { user: { activeIntegrations } } = getState();
    const integrationServiceName = INTEGRATIONS_VALUES_MAP[integrationName] && INTEGRATIONS_VALUES_MAP[integrationName].serviceName;
    return _.find(activeIntegrations, integration => integration.serviceName === integrationServiceName);
  };
}

export function isMarketPlaceEnabled() {
  return (dispatch, getState) => {
    const { user: { companyInfo } } = getState();
    if (!companyInfo || !companyInfo.app) return false;
    return companyInfo.app.cloudCredits;
  };
}

export function isFakeCloudCredits() {
  return (dispatch, getState) => {
    const { user: { companyInfo } } = getState();
    if (!companyInfo || !companyInfo.app) return false;
    return companyInfo.app.hybridCloudCredits;
  };
}

export function isWalletPendingActivationOrReadyToActivate() {
  return (dispatch, getState) => {
    const { wallet} = getState().cloudCredits;
    if (!wallet || !wallet.info || !wallet.info.walletStatus) return false;
    return wallet.info.walletStatus === WALLET_STATUSES.PENDING || wallet.info.walletStatus === WALLET_STATUSES.READY_TO_ACTIVATE;
  };
}

export function systemHasAtlas() {
  return (dispatch, getState) => {
    const { gateways: { wired: { content: atlasData} } } = getState();
    return _.isEmpty(atlasData);
  };
}


export function saveStoreItems() {
  return (dispatch) => {
    const storeItems = STORE_ITEMS;
    const formattedItems = _.map(storeItems, item => ({
      ...item,
      isActive: dispatch(userHasSubscriptions(item.licenseName)),
    }));
    dispatch(saveStoreItemsInState(formattedItems));
  };
}

export function fetchLicenseInfo() {
  return async (dispatch, getState) => {
    try {
      const subscriptionResponse = await RestService.fetchSubscriptionSummary();
      if (subscriptionResponse.data && subscriptionResponse.data) {
        const domainSubscription = _.find(subscriptionResponse.data.subscriptions, subscription => subscription.resourceType === 'DOMAIN');
        const isLiteDomain = domainSubscription && _.includes(domainSubscription.sku, '018S');
        const isEngineDomain = domainSubscription && _.includes(domainSubscription.sku, '024S');
        const isRFIDDomain = domainSubscription && _.includes(domainSubscription.sku, '025S');
        if (isLiteDomain) {
          AbilityProvider.getAbilityHelper().setLicenseType(LICENSE_TYPES.LITE);
        } else if (isEngineDomain) {
          AbilityProvider.getAbilityHelper().setLicenseType(LICENSE_TYPES.ENGINE);
        } else if (isRFIDDomain) {
          AbilityProvider.getAbilityHelper().setLicenseType(LICENSE_TYPES.RFID);
        } else {
          AbilityProvider.getAbilityHelper().setLicenseType(LICENSE_TYPES.ENTERPRISE);
        }
        const formattedLicense = {
          ...subscriptionResponse.data,
          mainTier: domainSubscription && domainSubscription.id,
          isEnterprise: subscriptionResponse.data && subscriptionResponse.data.domainProductType === LICENSES.ENTERPRISE,
          isUnknown: subscriptionResponse.data && subscriptionResponse.data.domainProductType === LICENSES.UNKNOWN,
          isProfessional: subscriptionResponse.data && subscriptionResponse.data.domainProductType === LICENSES.PROFESSIONAL,
          isLite: isLiteDomain,
          isEngine: isEngineDomain,
        };
        dispatch(saveDomainLicenseInfo(formattedLicense));
        dispatch(SettingsActions.saveSetting('license', subscriptionResponse.data.subscriptions));
        dispatch(saveStoreItems());
        if (dispatch(isFakeCloudCredits())) {
          dispatch(CreditsActions.elaborateFakeLicenseFromWalletSubscriptions());
        }
        return formattedLicense;
      }
      throw new Error();
    } catch (error) {
      
      throw error;
    }
  };
}


export function checkExpiringCredits() {
  return (dispatch, getState) => {
    try {
      const { wallet } = getState().cloudCredits;
      const { companyInfo } = getState().user;
      let severity = '';
      let warningEntityString = '';
      let isDomainExpiring = false;
      let isDomainUsersExpiring = false;
      let isDomainLocksExpiring = false;
      let isDomainUsersAndLocksExpiring = false;
      const isFakeLicense = dispatch(isFakeCloudCredits());
      const isWalletPendingOrReady = dispatch(isWalletPendingActivationOrReadyToActivate());
      // WALLET CONTROL
      if (isWalletPendingOrReady) {
        const walletStatus = wallet.info && wallet.info.walletStatus;
        //const errorMessage = walletStatus === WALLET_STATUSES.PENDING ? 'walletIsPendingActivation' : 'walletIsReadyToBeActivated';
        const errorMessage = 'walletIsPendingActivation';
        dispatch(ModalActions.showModal({
          modalType: 'WARNING_ALERT',
          modalProps: {
            autoHideDuration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h6 className="snack-title" style={{ margin: 0 }}><Entity entity={errorMessage} /></h6>
                <h6
                  className="link-label snack-title"
                  style={{ color: '#3f3f3f', margin: 0, marginLeft: 15, fontWeight: 'bold', textDecoration: 'underline' }}
                  onClick={() => dispatch(push('/settings?showWallet=true'))}
                >
                  <Entity entity="settings" />
                </h6>
              </div>
            ),
          },
        }));
      } else {
        // DOMAIN CONTROL
        const numOfUsers = wallet && wallet.info && wallet.info.numOfUsers;
        const numOfLocks = wallet && wallet.info && wallet.info.numOfLocks;
        const nextRechargeDate = calculateNextRechargeDate(wallet.availableCredits, wallet.burnRateDaily);
        const monthDifferenceFromNow = moment(moment(nextRechargeDate)).diff(moment(), 'months');
        let isExpiringWarning = monthDifferenceFromNow > 1 && monthDifferenceFromNow <= 3;
        let isExpiringError = monthDifferenceFromNow <= 1;
        severity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
        isDomainExpiring = isExpiringError || isExpiringWarning;
        
        warningEntityString = isFakeLicense ? 'domainIsExpiringWarning' : L20NContext.getSync('creditsExpiringErrorWarning', { data: moment(nextRechargeDate).fromNow() });

        const mainSubscription = _.find(wallet.subscriptions.content, walletSub => walletSub.subscription && walletSub.subscription.resource === 'DOMAIN');
        // USER CONTROL
        if (mainSubscription && mainSubscription.subscription && mainSubscription.subscription.maxUsers) {
          const residualAmount = mainSubscription.subscription.maxUsers - numOfUsers;
          const { warningThreshold, errorThreshold } = elaborateWarningThresholds(mainSubscription.subscription.maxUsers);
          isExpiringWarning = residualAmount > errorThreshold && residualAmount <= warningThreshold;
          isExpiringError = residualAmount <= errorThreshold;
          isDomainUsersExpiring = isExpiringError || isExpiringWarning;
          const alertSeverity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
          severity = isDomainUsersExpiring ? alertSeverity : severity;
          warningEntityString = isDomainUsersExpiring ? 'licenseUserExpiring' : warningEntityString;
        }
        // LOCK CONTROL
        if (mainSubscription && mainSubscription.subscription && mainSubscription.subscription.maxLocks) {
          const residualAmount = mainSubscription.subscription.maxLocks - numOfLocks;
          const { warningThreshold, errorThreshold } = elaborateWarningThresholds(mainSubscription.subscription.maxLocks);
          isExpiringWarning = residualAmount > errorThreshold && residualAmount <= warningThreshold;
          isExpiringError = residualAmount <= errorThreshold;
          isDomainLocksExpiring = isExpiringError || isExpiringWarning;
          const alertSeverity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
          severity = isDomainLocksExpiring ? alertSeverity : severity;
          warningEntityString = isDomainLocksExpiring ? 'licenseLockExpiring' : warningEntityString;
        }
        // LOCK CONTROL
        if (mainSubscription && mainSubscription.subscription && mainSubscription.subscription.maxElements) {
          const residualAmount = mainSubscription.subscription.maxElements - numOfLocks - numOfUsers;
          const { warningThreshold, errorThreshold } = elaborateWarningThresholds(mainSubscription.subscription.maxElements);
          isExpiringWarning = residualAmount > errorThreshold && residualAmount <= warningThreshold;
          isExpiringError = residualAmount <= errorThreshold;
          isDomainUsersAndLocksExpiring = isExpiringError || isExpiringWarning;
          const alertSeverity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
          severity = isDomainUsersAndLocksExpiring ? alertSeverity : severity;
          warningEntityString = isDomainUsersAndLocksExpiring ? 'licenseUsersAndLockExpiring' : warningEntityString;
        }

        if (isDomainLocksExpiring || isDomainUsersExpiring || isDomainExpiring || isDomainUsersAndLocksExpiring) {
          
          const targetPage = isFakeLicense ? '/settings?showLicense=true' : '/settings?showWallet=true';
          dispatch(ModalActions.showModal({
            modalType: severity,
            modalProps: {
              autoHideDuration: 20000,
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              message: (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h6 className="snack-title" style={{ margin: 0 }}><Entity entity={warningEntityString} /></h6>
                  <h6
                    className="link-label snack-title"
                    style={{ color: '#3f3f3f', margin: 0, marginLeft: 15, fontWeight: 'bold', textDecoration: 'underline' }}
                    onClick={() => dispatch(push(targetPage))}
                  >
                    <Entity entity="settings" />
                  </h6>
                </div>
              ),
            },
          }));
        }
      }
    } catch (error) {
    }
  }
}

export function checkExpiringDomainSubscriptions() {
  return (dispatch, getState) => {
    const license = getState().user.license;
    const domainSubscription = _.find(license.subscriptions, subscription => subscription.resourceType === 'DOMAIN');
    const usersSubscription = _.find(license.subscriptions, subscription => subscription.resourceType === 'USERS');
    const locksSubscription = _.find(license.subscriptions, subscription => subscription.resourceType === 'LOCKS');
    const enterpriseWithLimitSubscription = _.find(license.subscriptions, subscription => subscription.resourceType === 'USERS+LOCKS');
    if (license.domainProductType === LICENSES.PROFESSIONAL || enterpriseWithLimitSubscription) {
      let isDomainExpiring = false;
      let isDomainUsersExpiring = false;
      let isDomainLocksExpiring = false;
      let isDomainUsersAndLocksExpiring = false;
      let severity = '';
      let warningEntityString = '';
      const isUserWarningModalDisabled = getNotificationTypeStatus('isUserWarningModalDisabled');
      const isLockWarningModalDisabled = getNotificationTypeStatus('isLockWarningModalDisabled');
      const isUserPlusLockWarningModalDisabled = getNotificationTypeStatus('isUserPlusLockWarningModalDisabled');
      // DOMAIN CONTROL
      if (domainSubscription && domainSubscription.expirationDate) {
        const monthDifferenceFromNow = moment(moment(domainSubscription.expirationDate)).diff(moment(), 'months');
        const isExpiringWarning = monthDifferenceFromNow > 1 && monthDifferenceFromNow <= 3;
        const isExpiringError = monthDifferenceFromNow <= 1;
        isDomainExpiring = isExpiringError || isExpiringWarning;
        severity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
        warningEntityString = isDomainExpiring ? 'domainIsExpiringWarning' : warningEntityString;
      }
      // USER CONTROL
      if (usersSubscription && usersSubscription.quantity && !isUserWarningModalDisabled) {
        const residualAmount = usersSubscription.quantity - usersSubscription.used;
        const { warningThreshold, errorThreshold } = elaborateWarningThresholds(usersSubscription.quantity);
        const isExpiringWarning = residualAmount > errorThreshold && residualAmount <= warningThreshold;
        const isExpiringError = residualAmount <= errorThreshold;
        isDomainUsersExpiring = isExpiringError || isExpiringWarning;
        const alertSeverity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
        severity = isDomainUsersExpiring ? alertSeverity : severity;
        warningEntityString = isDomainUsersExpiring ? 'licenseUserExpiring' : warningEntityString;
      }
      // LOCK CONTROL
      if (locksSubscription && locksSubscription.quantity && !isLockWarningModalDisabled) {
        const residualAmount = locksSubscription.quantity - locksSubscription.used;
        const { warningThreshold, errorThreshold } = elaborateWarningThresholds(locksSubscription.quantity);
        const isExpiringWarning = residualAmount > errorThreshold && residualAmount <= warningThreshold;
        const isExpiringError = residualAmount <= errorThreshold;
        isDomainLocksExpiring = isExpiringError || isExpiringWarning;
        const alertSeverity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
        severity = isDomainLocksExpiring ? alertSeverity : severity;
        warningEntityString = isDomainLocksExpiring ? 'licenseLockExpiring' : warningEntityString;
      }
      // LOCK CONTROL
      if (enterpriseWithLimitSubscription && enterpriseWithLimitSubscription.quantity && !isUserPlusLockWarningModalDisabled) {
        const residualAmount = enterpriseWithLimitSubscription.quantity - enterpriseWithLimitSubscription.used;
        const { warningThreshold, errorThreshold } = elaborateWarningThresholds(enterpriseWithLimitSubscription.quantity);
        const isExpiringWarning = residualAmount > errorThreshold && residualAmount <= warningThreshold;
        const isExpiringError = residualAmount <= errorThreshold;
        isDomainUsersAndLocksExpiring = isExpiringError || isExpiringWarning;
        const alertSeverity = isExpiringError ? 'ERROR_ALERT' : 'WARNING_ALERT';
        severity = isDomainUsersAndLocksExpiring ? alertSeverity : severity;
        warningEntityString = isDomainUsersAndLocksExpiring ? 'licenseUsersAndLockExpiring' : warningEntityString;
      }
      if (isDomainLocksExpiring || isDomainUsersExpiring || isDomainExpiring || isDomainUsersAndLocksExpiring) {
        dispatch(ModalActions.showModal({
          modalType: severity,
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h6 className="snack-title" style={{ margin: 0 }}><Entity entity={warningEntityString} /></h6>
                <h6
                  className="link-label snack-title"
                  style={{ color: '#3f3f3f', margin: 0, marginLeft: 5, fontWeight: 'bold', textDecoration: 'underline' }}
                  onClick={() => dispatch(push('/settings?showLicense=true'))}
                >
                  <Entity entity="settings" />
                </h6>
              </div>
            ),
          },
        }));
      }
    }
  };
}

export function changeDomain(domain, newWindow = false) {
  return async (dispatch) => {
    try {
      const authResponse = await RestService.exchangeDomainToken({ hostname: domain.hostName });
      if (authResponse.data) {
        const { accessToken } = authResponse.data;
        const target = newWindow ? '_blank' : '_self';
        window.open(`${createHostNameFromPlantName(domain.domainName)}?p=${accessToken}`, target);
        return true;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCurrentActiveIntegrations() {
  return async (dispatch, getState) => {
    try {
      const currentIntegrations = await RestService.fetchCurrentActiveIntegrations();
      if (currentIntegrations && currentIntegrations.data && currentIntegrations.data.content) {
        dispatch(saveActiveIntegrations(currentIntegrations.data.content));
      }
    } catch (error) {
      throw error;
    }
  };
}

export function requestPluginDemo(pluginName) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: `<h4>Demo Request for extension ${pluginName} for domain ${plantName}. Requester ${firstName} ${lastName} ${email}</h4>`,
        email,
        subject: `DEMO REQUEST - PLUGIN ${pluginName} - PLANT ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  };
}

export function requestPluginQuotation(pluginName) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: `<h4>Quotation Request for extension ${pluginName} for domain ${plantName}. Requester ${firstName} ${lastName} ${email}</h4>`,
        email,
        subject: `QUOTATION REQUESTED - EXTENSION ${pluginName} - DOMAIN ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  };
}

export function requestSemiAutomaticActivation(pluginName) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: `<h4>Activation Request for extension ${pluginName} for domain ${plantName}. Requester ${firstName} ${lastName} ${email}. This request comes from a semi automatic provisiong, customer has already payed the activation cost of the extension.</h4>`,
        email,
        subject: `ACTIVATION REQUESTED (SEMI AUTOMATIC PROVISIONING) - EXTENSION ${pluginName} - DOMAIN ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  };
}

export function requestPluginActivation(pluginName) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: `<h4>Activation Request for extension ${pluginName} for domain ${plantName}. Requester ${firstName} ${lastName} ${email}</h4>`,
        email,
        subject: `REQUEST ACTIVATION EXTENSION ${pluginName} - PLANT ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCompanyInfo() {
  return async (dispatch, getState) => {
    try {
      const companyInfoResponse = await RestService.fetchCompanyInfo();
      if (companyInfoResponse && companyInfoResponse.data) {
        dispatch(saveCompanyInfo(companyInfoResponse.data));
      }
    } catch (error) {}
  };
}

export function authorizationCodeExchangeEagleEye(authCode) {
  return async (dispatch, getState) => {
    try {
      const authCodeExchangeResponse = await RestService.authorizationCodeExchangeEagleEye({ code: authCode });
      if (authCodeExchangeResponse) {
        return authCodeExchangeResponse;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function authorizationCodeExchangeCobot(authCode) {
  return async (dispatch, getState) => {
    try {
      const authCodeExchangeResponse = await RestService.authorizationCodeExchangeCobot(authCode);
      await dispatch(loginWithOauthProvider('COBOT', authCodeExchangeResponse.data.accessToken));
    } catch (error) {
      throw error;
    }
  };
}

export function getCobotResourceCategories(spaceName, token) {
  return async (dispatch, getState) => {
    try {
      const formattedSpaceName = `https://${spaceName}.cobot.me`;
      const cobotResourceTypesResponse = await IntegrationsAPI.fetchCobotResourceCategories({ hostname: formattedSpaceName, token });
      if (cobotResourceTypesResponse && cobotResourceTypesResponse.data && !_.isEmpty(cobotResourceTypesResponse.data)) {
        return cobotResourceTypesResponse.data;
      }
      throw new Error('COBOT_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function activateCobotIntegration(authCode, spaceName, clientId, clientSecret, syncAllUsers, coldStartResources, resourceCategoryIds) {
  return async (dispatch, getState) => {
    try {
      const formattedSpaceName = `https://${spaceName}.cobot.me`;
      const authCodeExchangeResponse = await RestService.activateCobotIntegration({ token: authCode, hostname: formattedSpaceName, clientId, clientSecret, syncAllUsers, coldStartResources, resourceCategoryIds });
      if (authCodeExchangeResponse) {
        return authCodeExchangeResponse;
      }
    } catch (error) {
      throw error;
    }
  };
}


export function activateOfficeRndIntegration(orgSlug, clientId, clientSecret) {
  return async (dispatch, getState) => {
    try {
      const authCodeExchangeResponse = await RestService.activateOfficeRndIntegration({ orgSlug, clientId, clientSecret });
      if (authCodeExchangeResponse) {
        return authCodeExchangeResponse;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithNexudus(username, password) {
  return async (dispatch, getState) => {
    try {
      const loginResponse = await IntegrationsAPI.loginWithNexudus(username, password);
      if (loginResponse) {
        const { access_token, refresh_token } = loginResponse.data;
        return {
          accessToken: access_token,
          refreshToken: refresh_token,
        };
      }
      throw new Error('OFFICERND_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithOfficeRnd(username, password) {
  return async (dispatch, getState) => {
    try {
      const loginResponse = await IntegrationsAPI.loginWithOfficeRnd(username, password);
      if (loginResponse) {
        const { token } = loginResponse.data;
        return { token };
      }
      throw new Error('OFFICERND_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithNexudusAndGetBusinesses(username, password) {
  return async (dispatch, getState) => {
    const clientId = uuidv4();
    try {
      const loginResponse = await IntegrationsAPI.loginWithNexudus(username, password, clientId);
      if (loginResponse) {
        const { access_token, refresh_token } = loginResponse.data;
        const businessesResponse = await IntegrationsAPI.getNexudusUserBusinesses(access_token);
        if (businessesResponse && businessesResponse.data && businessesResponse.data.Records && !_.isEmpty(businessesResponse.data.Records)) {
          dispatch(setNexudusClientId(clientId));
          return {
            businesses: businessesResponse.data.Records,
            accessToken: access_token,
            refreshToken: refresh_token,
          };
        }
        throw new Error('NEXUDUS_ACTIVATION_FAILED_NO_BUSINESSES');
      }
      throw new Error('NEXUDUS_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function activateNexudusIntegrationForBusiness(token, refreshToken, selectedBusinessIds, syncAllUsers) {
  return async (dispatch, getState) => {
    const { nexudusClientId } = getState().user;
    try {
      const activationResponse = await IntegrationsAPI.activateNexudusIntegration(token, refreshToken, selectedBusinessIds, syncAllUsers, nexudusClientId);
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('NEXUDUS_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithChainelsandGetCommunities(authCode) {
  return async (dispatch, getState) => {
    try {
      const clientId = localStorage.getItem('chainelsClientId');
      const clientSecret = localStorage.getItem('chainelsClientSecret');
      const loginResponse = await IntegrationsAPI.loginWithChainels(clientId, clientSecret, authCode);
      if (loginResponse) {
        const { access_token, refresh_token } = loginResponse.data;
        const userResponse = await IntegrationsAPI.getChainelsUser(access_token);
        // GO TO SETTINGS AND LET CUSTOMER CHOSE COMM.
        if (userResponse && userResponse.data && userResponse.data.companies) {
          const authData = {
            clientId,
            clientSecret,
            authCode,
            access_token,
            refresh_token,
            companies: userResponse.data.companies,
            jago_token: localStorage.token,
          }
          dispatch(setChainelsAuthParameters(authData))
          return authData;
        }
        throw new Error('CHAINELS_ACTIVATION_FAILED_NO_BUSINESSES');
      }
      throw new Error('CHAINELS_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  }
}

export function loginWithCloudBedsAndActivate(authCode) {
  return async (dispatch, getState) => {
    try {
      const clientId = localStorage.getItem('cloudBedsClientId');
      const clientSecret = localStorage.getItem('cloudBedsClientSecret');
      const timezone = localStorage.getItem('cloudBedsTimezone');
      // 1. Get token from BE
      const authenticationResponse = await dispatch(authorizationCodeExchangeCloudbeds(authCode, clientId, clientSecret));
      if (authenticationResponse) {
        const { access_token, refresh_token } = authenticationResponse.data;
        // 2. Activate full integration
        const activationResponse = await IntegrationsAPI.activateCloudbedsIntegration(access_token, refresh_token, clientId, clientSecret, timezone);
        if (activationResponse) {
          dispatch(ModalActions.showModal({
            modalType: 'SUCCESS_ALERT',
            modalProps: {
              message: (<h6 className="snack-title"><Entity entity="integrationConnectionSuccess" /></h6>),
            },
          }));
          localStorage.setItem('cloudBedsClientId', null);
          localStorage.setItem('cloudBedsClientSecret', null);
          localStorage.setItem('cloudBedsTimezone', null);
          dispatch(push('/settings'))
          return authenticationResponse;
        }
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
          },
        }));
        dispatch(push('/'))
        throw new Error('CLOUDBEDS_ACTIVATION_FAILED_NO_BUSINESSES');
      }
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
      dispatch(push('/'))
      throw new Error('CLOUDBEDS_LOGIN_FAILED');
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
      dispatch(push('/'))
      throw error;
    }
  }
}

export function activateChainelsIntegrationForCommunity(communityId) {
  return async (dispatch, getState) => {
    const { chainelsAuthParameters } = getState().user;
    const {
      clientId,
      clientSecret,
      access_token,
      refresh_token,
    } = chainelsAuthParameters;
    try {
      const activationResponse = await IntegrationsAPI.activateChainelIntegration(access_token, refresh_token, clientId, clientSecret, communityId);
      if (activationResponse && activationResponse.data) {
        localStorage.clear();
        return activationResponse.data;
      }
      throw new Error('CHAINELS_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function authorizationCodeExchangeChainels(authCode) {
  return async (dispatch, getState) => {
    try {
      const redirectUri = `${window.location.protocol}//${window.location.host}`;
      const authCodeExchangeResponse = await RestService.authorizationCodeExchangeChainels(authCode, redirectUri);
      await dispatch(loginWithOauthProvider('CHAINELS', authCodeExchangeResponse.data.access_token));
    } catch (error) {
      throw error;
    }
  };
}

export function authorizationCodeExchangeCloudbeds(authCode, clientId, clientSecret) {
  return async (dispatch, getState) => {
    try {
      const redirectUri = `${window.location.protocol}//${window.location.host}`;
      const authCodeExchangeResponse = await RestService.authorizationCodeExchangeCloudbeds(authCode, redirectUri, clientId, clientSecret);
      return authCodeExchangeResponse;
    } catch (error) {
      throw error;
    }
  };
}

export function getAndcardsOrganizations(clientId, clientSecret) {
  return async (dispatch, getState) => {
    try {
      const organizationsResponse = await IntegrationsAPI.getAndcardsOrganization(clientId, clientSecret);
      if (organizationsResponse && organizationsResponse.data && organizationsResponse.data.organizations && !_.isEmpty(organizationsResponse.data.organizations)) {
        return organizationsResponse.data.organizations;
      }
      throw new Error('ANDCARDS_ACTIVATION_FAILED_NO_BUSINESSES');
    } catch (error) {
      throw error;
    }
  };
}

export function activateAndcardsIntegrationForOrganization(clientId, clientSecret, organizationId) {
  return async (dispatch, getState) => {
    try {
      const activationResponse = await IntegrationsAPI.activateAndcardsIntegration(clientId, clientSecret, organizationId);
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('ANDCARD_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}


//------------- OFFICE365 -----------------//

export function loginWithOffice365AndGetCalendars(clientId, clientSecret, tenantId, principalEmail) {
  return async (dispatch, getState) => {
    try {
      const tokenResponse = await IntegrationsAPI.loginWithOffice365(clientId, clientSecret, tenantId);
        if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
        const accessToken = tokenResponse.data.access_token;
        const userResponse = await IntegrationsAPI.getMicrosoftUserByPrincipalName(principalEmail, accessToken);
        if (userResponse && userResponse.data) {
          const userId = userResponse.data.id;
          const calendarResponse = await IntegrationsAPI.getMicrosoftUserCalendars(userId, accessToken);
          return {
            calendars: calendarResponse.data.value,
            userId,
          };
        }
        throw new Error('OFFICE365_ACTIVATION_FAILED_NO_CALENDARS');
      }
      throw new Error('OFFICE365_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}


export function activateMicrosoftIntegration(clientId, clientSecret, tenantId, userId, calendarId) {
  return async (dispatch, getState) => {
    try {
      const activationResponse = await RestService.activateOffice365Integration({ clientId, clientSecret, tenantId, userId, calendarId });
      if (activationResponse) {
        return activationResponse;
      }
    } catch (error) {
      throw error;
    }
  };
}


export function disableIntegration(integrationId, token) {
  return async (dispatch, getState) => {
    try {
      const deactivationResponse = await IntegrationsAPI.disableIntegration(integrationId, token);
      if (deactivationResponse && deactivationResponse.data) {
        return deactivationResponse.data;
      }
      throw new Error('INTEGRATION_DEACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithOauthProvider(provider, token) {
  return async (dispatch, getState) => {
    try {
      const authResponse = await IntegrationsAPI.loginWithOauthProvider(provider, token);
      if (authResponse && authResponse.data && authResponse.data.accessToken) {
        const accessToken = authResponse.data.accessToken;
        dispatch(processAndStoreAccessToken(accessToken));
        const decodedJWT = jwt.decode(accessToken);
        const user = await dispatch(fetchCurrentUser());
        if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_INSTALLER') {
          dispatch(ModalActions.showModal({
            modalType: 'ERROR_MODAL',
            modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="guestLoginNotAllowed" /> },
          }));
          dispatch(push('/login'));
          dispatch(UtilsActions.setSpinnerVisibile(false));
        } else if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_GUEST') {
          dispatch(processAndStoreAccessToken(accessToken));
          dispatch(push('/remoteOpen'));
        } else if (user && user.using2FA) {
          dispatch(UtilsActions.setSpinnerVisibile(false));
          dispatch(push('/loginTwoFactor'));
          dispatch(editField('password', ''));
        } else {
          return dispatch(applicationStartup(accessToken));
        }
      }
    } catch (error) {
      throw error;
    }
  };
}


export function sendOTPEmail(email) {
  return async (dispatch, getState) => {
    const { user: { plantName } } = getState();
    try {
      await RestService.sendOTPViaEmail(email);
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      if (error && error.response && error.response.data && error.response.data.code === 10121) {
        return;
      }
      throw error;
    }
  };
}

export function loginWithOTP(code) {
  return async (dispatch, getState) => {
    const loginStartTime = moment().valueOf();
    const { user: { plantName } } = getState();
    try {
      const authResponse = await RestService.verifyOTP(code);
      if (authResponse.data) {
        const { access_token } = authResponse.data;
        dispatch(processAndStoreAccessToken(access_token));
        const decodedJWT = jwt.decode(access_token);
        const user = await dispatch(fetchCurrentUser());
        if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_INSTALLER') {
          dispatch(ModalActions.showModal({
            modalType: 'ERROR_MODAL',
            modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="guestLoginNotAllowed" /> },
          }));
          dispatch(push('/login'));
          dispatch(UtilsActions.setSpinnerVisibile(false));
        } else if (decodedJWT && decodedJWT.authorities && decodedJWT.authorities.length === 1 && decodedJWT.authorities[0] === 'ROLE_GUEST') {
          dispatch(processAndStoreAccessToken(access_token));
          dispatch(push('/remoteOpen'));
        } else {
          return dispatch(applicationStartup(access_token));
        }
      }
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.code === 11601) {
        setTimeout(() => dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="loginJagoOTPFailed" /> },
        })), 700);
      }
      const loginTotalTime = moment().diff(loginStartTime);
      throw error;
    }
  };
}

export function loginWithInvitationCode(code) {
  return async (dispatch, getState) => {
    const loginStartTime = moment().valueOf();
    const { user: { plantName } } = getState();
    try {
      const codeResponse = await InvitationsAPI.redeemInvitationCode({ code });
      if (codeResponse.data) {
        const { accessToken } = codeResponse.data;
        dispatch(processAndStoreAccessToken(accessToken));
        dispatch(push('/remoteOpen'));
      }
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.code === 11601) {
        setTimeout(() => dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="loginJagoOTPFailed" /> },
        })), 700);
      }
      throw error;
    }
  };
}


export function synchronizeV364SmartLocks() {
  return async (dispatch, getState) => {
    try {
      const synchResponse = await RestService.synchronizeV364SmartLocks();
      return synchResponse;
    } catch (error) {
      throw error;
    }
  };
}

export function loginWithMewsAndGetEnterprises(accessToken) {
  return async (dispatch, getState) => {
    try {
      const enterprisesResponse = await IntegrationsAPI.getMewsEnterprises({ token: accessToken });
      if (enterprisesResponse && enterprisesResponse.data && enterprisesResponse.data.enterprises && !_.isEmpty(enterprisesResponse.data.enterprises)) {
        return enterprisesResponse.data.enterprises;
      }
      throw new Error('MEWS_ACTIVATION_FAILED_NO_BUSINESSES');
    } catch (error) {
      throw error;
    }
  }
}

export function activateMewsIntegrationForBusiness(token, enterprises, coldStart) {
  return async (dispatch, getState) => {
    const activationDTO = {
      token, 
      reservationsDaysBefore: 2,
      reservationsDaysAfter: 2,
      enterprises,
      coldStart,
    }
    try {
      const activationResponse = await IntegrationsAPI.activateMewsIntegration(activationDTO);
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('MEWS_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  }
}

export function getOptixResourceType(token) {
  return async (dispatch, getState) => {
    try {
      const optixResourceTypesResponse = await IntegrationsAPI.fetchOptixResourceTypes({ token });
      if (optixResourceTypesResponse && optixResourceTypesResponse.data && optixResourceTypesResponse.data.resourceTypes && !_.isEmpty(optixResourceTypesResponse.data.resourceTypes)) {
        return optixResourceTypesResponse.data.resourceTypes;
      }
      throw new Error('OPTIX_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function activateOptixIntegrationWithResourceTypes(token, resourceTypeIds, coldStartResources) {
  return async (dispatch, getState) => {
    const activationDTO = {
      token,
      reservationsDaysBefore: 2,
      reservationsDaysAfter: 2,
      coldStartResources,
      resourceTypeIds,
    }
    try {
      const activationResponse = await IntegrationsAPI.activateOptixIntegration(activationDTO);
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('OPTIX_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function activateTenupIntegration(codeClub) {
  return async (dispatch, getState) => {
    try {
      const activationResponse = await IntegrationsAPI.activateTenupIntegration({ codeClub });
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('TENUP_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function createIseoZendeskTicket(ticketData) {
  return async (dispatch, getState) => {
    const userData = getState().user.data;
    const licenseData = getState().user.token;
    const { companyInfo } = getState().user;
    const email = userData.email || 'no-email';
    const firstName = userData.firstname || 'no-firstname';
    const lastName = userData.lastname || 'no-lastname';
    const cluster = companyInfo && companyInfo.clusterName ? formatClusterForZenDeskPortal(companyInfo.clusterName) : 'nocluster';
    const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain'; 
    const { groupId  } = getIseoZendeskParameters();
    try {
      const ticketDTO = {
        ticket: {
          comment: {
            html_body: (
              `<div>
                <h4 style="font-weight: 400;">Ticket details</h4>
                <h4 style="font-weight: 400;">Customer: ${firstName} ${lastName} ${email}</h4>
                <h4 style="font-weight: 400;">System: ${plantName}</h4>
                <h4 style="font-weight: 400;">================</h4>
                <h4 style="font-weight: 400;">"${ticketData.comment}"</h4>
              </div>`
            ),
          },
          priority: "normal",
          requester: { "name": `${firstName} ${lastName}`, "email": email },
          subject: ticketData.subject,
          group_id: groupId,
          tags: ['luckey', cluster]
        }
      }
      const ticketResponse = await ZendeskAPI.createZendeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      const { message } = error;
      if (message === "Network Error") {
        return true;
      }
      throw error;
    }
  }
}

export function activateZapfloorIntegration() {
  return async (dispatch, getState) => {
    try {
      const activationResponse = await IntegrationsAPI.activateZapfloorIntegration();
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('ZAPFLOOR_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function activateOctorateIntegration(activationDTO) {
  return async (dispatch, getState) => {
    try {
      const activationResponse = await IntegrationsAPI.activateOctorateIntegration(activationDTO);
      if (activationResponse && activationResponse.data) {
        return activationResponse.data;
      }
      throw new Error('OCTORATE_ACTIVATION_FAILED');
    } catch (error) {
      throw error;
    }
  };
}

export function getOctorateAccommodations(token) {
  return async (dispatch, getState) => {
    try {
      const octorateAccommodationsResponse = await IntegrationsAPI.fetchOctorateAccommodations({ token });
      if (octorateAccommodationsResponse && octorateAccommodationsResponse.data) {
        return octorateAccommodationsResponse.data;
      }
      throw new Error('OCTORATE_LOGIN_FAILED');
    } catch (error) {
      throw error;
    }
  };
}
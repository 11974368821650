// @ts-nocheck
import React, { Component } from 'react';
import {
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import {
  FormControl,
  Icon,
} from '@sketchpixy/rubix';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import CheckIcon from '@material-ui/icons/Done';
import CopyIcon from '@material-ui/icons/InsertDriveFile';
import MDButton from '../MDButton/MDButton.jsx';
import { BLUE, GREEN, ORANGE } from '../../_config/consts';
import CustomField from '../forms/Fields/CustomField.jsx';
import Warning from '@material-ui/icons/Warning.js';

export default class InvitationShareSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      shareText: '',
    };
  }

  onCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  onTextChange(text) {
    this.setState({ shareText: text });
  }

  render() {
    const { resource } = this.props;
    const { copied, shareText } = this.state;
    const prefixForCode = L20NContext.getSync('invitationCode');
    const textToShare = `${shareText} ${prefixForCode}: ${resource}\n`;
    return (
      <div>
        <h4 style={{ color: '#3f3f3f', fontSize: 17 }}><Entity entity="shareCodeDescription" /></h4>
        <FormControl
          componentClass="textarea"
          className="form-control-custom"
          placeholder={L20NContext.getSync('shareCodePlaceholder')}
          style={{ height: 100 }}
          defaultValue={textToShare}
          onChange={e => this.onTextChange(e.target.value)}
        />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
          <Warning style={{ color: ORANGE, fontSize: 20 }} />
          <h5 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10, marginTop: 2 }}><Entity entity="shareCodeWarning" /></h5>
        </div>
        <div style={{ paddingTop: 20, display: 'flex', alignItems: 'center' }}>
          <CopyToClipboard text={textToShare} onCopy={() => this.onCopy()}>
            <div>
              <MDButton
                title={copied ? <Entity key={copied} entity="copied" /> : <Entity key={copied} entity="copyText" />}
                backgroundColor={copied ? GREEN : BLUE}
                onClick={() => null}
                containerStyle={{ marginLeft: 0 }}
                style={{ color: 'white', fontSize: 15, marginRight: 10 }}
                icon={copied ? <CheckIcon style={{ marginLeft: 10 }} /> : <CopyIcon style={{ marginLeft: 10 }} />}
              />
            </div>
          </CopyToClipboard>
          <WhatsappShareButton url={textToShare} style={{ marginLeft: 0, marginRight: 10 }}>
            <WhatsappIcon round size={36} />
          </WhatsappShareButton>
          <EmailShareButton url={textToShare} style={{ marginRight: 10 }}>
            <EmailIcon round size={36} />
          </EmailShareButton>
          <TelegramShareButton url={textToShare} style={{ marginRight: 10 }}>
            <TelegramIcon round size={36} />
          </TelegramShareButton>
        </div>
      </div>
    );
  }
}

import Card from '@material-ui/core/Card';
import Popover from '@material-ui/core/Popover';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import ReactTooltip from 'react-tooltip';
import { BLUE, DND_ELEMENTS, RED } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';
import { getLockImage } from '../../_config/utils.js';


export default function FloorElementPoint({ element, x, y, onRemoveElementFromMap }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DND_ELEMENTS.SMART_LOCK_POINT,
      item: { name: element.name, id: element.id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <>
      <Card
        data-tip={element.name}
        ref={dragRef}
        className={`card card-rounded`}
        style={{ margin: 0, display: 'flex', position: 'absolute', left: x, top: y, borderRadius: 40, width: 50, height: 50, backgroundColor: BLUE, alignItems: 'center', justifyContent: 'center' }}
        elevation={2}
        onClick={handleClick}
      >
        <img className="lock-icon" style={{ width: 40, height: 40 }} src={getLockImage(element.model)} />
      </Card>
      <ReactTooltip delayShow={0} effect="float" />
      <Popover
        id={id}
        open={open}
        style={{ zoom: '80%'}}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 15 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <img className="lock-icon" style={{ width: 40, height: 40 }} src={getLockImage(element.model)} />
            <div className="card-inner-body" style={{ overflowWrap: 'break-word', paddingLeft: 10 }}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginBottom: 0, marginTop: 0 }}>{`${element.name}`}</h4>
            </div>
          </div>
          <MDButton
            title={<Entity entity="remove" />}
            containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
            style={{ height: 35, borderRadius: 0  }}
            backgroundColor={RED}
            onClick={() => {
              handleClick();
              onRemoveElementFromMap();
            }}
          />
        </div>
      </Popover>
    </>
  );
}

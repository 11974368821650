// @ts-nocheck
import BluetoothIcon from '@material-ui/icons/BluetoothConnected';
import GatewayIcon from '@material-ui/icons/Cast';
import HyperTagIcon from '@material-ui/icons/Nfc';
import SmartClassicIcon from '@material-ui/icons/WifiTethering';
import {
  Col, Row
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ACCESSORIES_TYPES, BATTERY_IMAGES, GREEN, LOCKS_MODELS, SOURCE_TYPE } from '../../_config/consts';
import { getLockImage, getStatusColorFromBatteryLevel } from '../../_config/utils';
import DoorSensorIconCustom from '../CustomIcons/DoorSensorIconCustom.jsx';
import PrivacyIconCustom from '../CustomIcons/PrivacyIconCustom.jsx';
import SmartReaderIconCustom from '../CustomIcons/SmartReaderIconCustom.jsx';

const LockTechnicalDetailsView = ({ lock, onGatewayClick }) => {
  const isConnectedToGateway = lock.gateway && lock.gateway.id && lock.gateway.name;
  const textStatusColor = getStatusColorFromBatteryLevel(lock.battery);
  const isF9000Cilinder = lock && lock.model === LOCKS_MODELS.F9000;
  const isFullOnlineGate = lock && lock.configuration && lock.configuration.remoteOpenEnabled;
  const canBeOpenRemotely = lock.gatewayId || isFullOnlineGate;
  let connectionDescriptionText = canBeOpenRemotely ? <Entity key={lock.id} entity="lockIsSmartClassicOrOnline" /> : <Entity key={lock.id} entity="lockConnectionTypes" data={{ type: lock.connectionType }} />;
  connectionDescriptionText = isConnectedToGateway ? <Entity key={lock.id} entity="lockIsConnectedToGatewayName" data={{ name: lock.gateway.name }} /> : connectionDescriptionText;
  connectionDescriptionText = isF9000Cilinder ? <Entity key={lock.id} entity="lockIsMechatronicCylinder" /> : connectionDescriptionText;
  let connectionIcon = isFullOnlineGate ? <SmartClassicIcon style={{ fontSize: 20, marginLeft: 10, marginBottom: 2, color: '#3f3f3f' }} /> : <BluetoothIcon style={{ fontSize: 20, marginLeft: 10, marginBottom: 2, color: '#3f3f3f' }} />;
  connectionIcon = isConnectedToGateway ? <GatewayIcon style={{ fontSize: 20, marginLeft: 10, marginBottom: 2, color: '#3f3f3f' }} /> : connectionIcon;
  connectionIcon = isF9000Cilinder ? <div /> : connectionIcon;
  const lockSensor = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.DOOR_SENSOR) : null;
  const lockReader = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.BT_READER) : null;
  return (
    <div headerStyle={{ marginBottom: 20 }}>
      {lock.connectionType ? (
        <Row style={{ marginTop: 20, marginBottom: 40 }}>
          <Col sm={12}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold', cursor: isConnectedToGateway ? 'pointer' : 'none' }}><Entity entity="lockConnectionType" /></h4>
              <hr className="separator" />
              <div style={{ display: 'flex', alignItems: 'center' }} onClick={isConnectedToGateway ? () => onGatewayClick(lock.gateway.id) : null}>
                <h4 style={{ margin: 0, color: '#3f3f3f', textDecoration: isConnectedToGateway ? 'underline' : 'auto' }} className={isConnectedToGateway ? 'link-label' : ''}>
                  {connectionDescriptionText}
                </h4>
                {connectionIcon}
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {lock.hyperTagId ? (
        <Row style={{ marginTop: 20, marginBottom: 40 }}>
          <Col sm={12}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="hyperTag" /></h4>
              <hr className="separator" />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h4 style={{ margin: 0, marginRight: 10, color: '#3f3f3f' }}><Entity key={lock.hyperTagId} entity="hyperTagDescription" data={{ hyperTagId: lock.hyperTagId }} /></h4>
                <HyperTagIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {lock.privacyModeEnabled ? (
        <Row style={{ marginTop: 20, marginBottom: 40 }}>
          <Col sm={12}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="doorPrivacyMode" /></h4>
              <hr className="separator" />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <PrivacyIconCustom style={{ width: 20, color: GREEN }} />
                <h4 style={{ margin: 0, marginLeft: 10, color: '#3f3f3f' }}><Entity key={lock.id} entity="privacyModeEnabledDescription" /></h4>
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {lockSensor ? (
        <Row style={{ marginTop: 20, marginBottom: 40 }}>
          <Col sm={12}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="doorStatus" /></h4>
              <hr className="separator" />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <DoorSensorIconCustom style={{ width: 25, color: '#3f3f3f' }} />
                <h4 style={{ margin: 0, marginLeft: 5, color: '#3f3f3f' }}><Entity key={lockSensor.id} entity="doorStatusSensorDescription" data={{ sensorId: lockSensor.name }} /></h4>
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {lockReader ? (
        <Row style={{ marginTop: 20, marginBottom: 40 }}>
          <Col sm={12}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="smartReader" /></h4>
              <hr className="separator" />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h4 style={{ margin: 0, marginRight: 10, color: '#3f3f3f' }}><Entity key={lockReader.id} entity="lockIsConnectedWithAReaderName" data={{ readerName: lockReader.name }} /></h4>
                <img style={{ width: 40, height: 40 }} src={getLockImage(lockReader.model)} />
                <img alt="battery" src={BATTERY_IMAGES[lockReader.battery]} style={{ width: 22, height: 9.5, marginLeft: 0, alignSelf: 'flex-end' }} />
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {lock.configuration && lock.configuration.openMode ? (
        <Row style={{ marginTop: 20, marginBottom: 40 }}>
          <Col sm={12}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="openModeTitle" /></h4>
              <hr className="separator" />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 style={{ margin: 0, color: '#3f3f3f' }}>
                  <Entity key={lock.id} entity="openModeTranslations" data={{ mode: lock.configuration.openMode }} />
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      <Row style={{ marginTop: 20, marginBottom: 40 }}>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="serialNumber" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{lock.serialNumber}</h4>
          </div>
        </Col>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="model" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{LOCKS_MODELS[lock.model]}</h4>
          </div>
        </Col>
        {!isF9000Cilinder ? (
          <Col sm={4}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="sourceType" /></h4>
              <hr className="separator" />
              <h4 style={{ margin: 0, color: '#3f3f3f' }}>
                <Entity key={lock.id} entity="powerSourceTypes" data={{ type: lock.powerSourceType }} />
              </h4>
            </div>
          </Col>) : null}
      </Row>
      <Row style={{ marginBottom: 20 }}>
        {!isF9000Cilinder ? (
          <Col sm={4}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="battery" /></h4>
              <hr className="separator" />
              {lock.powerSourceType === SOURCE_TYPE.BATTERY && lock.battery !== undefined && lock.battery !== -1 ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img alt="battery" src={BATTERY_IMAGES[lock.battery]} style={{ width: 32, height: 12.5 }} />
                  <h4 style={{ margin: 0, marginLeft: 10, color: textStatusColor, fontWeight: 'bold' }}>{lock.battery}%</h4>
                </div>
              ) : null}
              {lock.powerSourceType === SOURCE_TYPE.PLUGGED ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img alt="battery" src={'/imgs/common/batteryIcons/lightning.png'} style={{ width: 15, height: 25, marginRight: 10 }} />
                  <h4 style={{ margin: 0, color: 'goldenrod', fontWeight: 'bold' }}><Entity key={lock.id} entity="powerSourceTypes" data={{ type: lock.powerSourceType }} /></h4>
                </div>
              ) : null}
            </div>
          </Col>
        ) : null}
        {lock.vendor ? (
          <Col sm={4}>
            <div>
              <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="vendor" /></h4>
              <hr className="separator" />
              <h4 style={{ margin: 0, color: '#3f3f3f' }}>
                <Entity key={lock.id} entity="vendorsTranslations" data={{ vendor: lock.vendor }} />
              </h4>
            </div>
          </Col>
        ) : null}
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="firmwareVersion" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{lock.firmwareVersion || '---'}</h4>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="hardwareVersion" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{lock.hardwareVersion || '---'}</h4>
          </div>
        </Col>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="featureLevel" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{lock.featureLevel || '---'}</h4>
          </div>
        </Col>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="bluetoothFirmwareVersion" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{lock.bluetoothFirmwareVersion || '---'}</h4>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="bluetoothHardwareVersion" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{lock.bluetoothHardwareVersion || '---'}</h4>
          </div>
        </Col>
        <Col sm={4}>
          <div>
            <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="lastUpdate" /></h4>
            <hr className="separator" />
            <h4 style={{ margin: 0, color: '#3f3f3f' }}>{moment(lock.updatedAt).format('LLL') || '---'}</h4>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default LockTechnicalDetailsView;

import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm, reset } from 'redux-form';
import EventsNumberIcon from '@material-ui/icons/ClearAll';
import * as GuestActions from '../../../redux/actions/guest.actions';
import * as LocksActions from '../../../redux/actions/lock.actions';
import SearchFiltersAccordion from '../../Logs/SearchFiltersAccordion.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import CustomField from '../Fields/CustomField.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';
import EventsExportMenu from '../../ExportMenus/EventsExportMenu.jsx';
import { DOOR_LOCK_EVENT_TYPE_FILTER_MAP, GREEN } from '../../../_config/consts';
import { IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
@connect(state => ({}))
@reduxForm({ form: 'SmartLocksEventsSearchBar' })
class SmartLocksEventsSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locksOptions: [],
      entitiesOptions: [],
      locksTagsOptions: [],
      guestTagsOptions: [],
    };
  }
  async onFilterGuests(value) {
    const { dispatch } = this.props;
    dispatch(GuestActions.setFilter('search', value));
    const guests = await dispatch(GuestActions.fetchGuests());
    const actors = [...guests];
    const options = _.map(actors, actor => ({ value: actor.id, label: `${actor.firstname} ${actor.lastname} ${actor.email}` }));
    this.setState({ entitiesOptions: options });
    return options;
  }

  async onFilterLocks(value) {
    const { dispatch } = this.props;
    dispatch(LocksActions.setFilter('name', value));
    const locks = await dispatch(LocksActions.fetchLocks());
    const options = _.map(locks, lock => ({ value: lock.id, label: lock.name }));
    this.setState({ locksOptions: options });
    return options;
  }

  onHandleFilerChange(value, field) {
    const { onFilterChange, onEventTypeFilterChange, onResetFilterField } = this.props;
    if (!value) return onResetFilterField(field);
    switch (field) {
      case 'eventTypes': {
        if (value === 'ALL') {
          return onEventTypeFilterChange(undefined, undefined);
        } else {
          const filterValues = DOOR_LOCK_EVENT_TYPE_FILTER_MAP[value].events;
          const outcomesValues = DOOR_LOCK_EVENT_TYPE_FILTER_MAP[value].outcomes;
          onEventTypeFilterChange(filterValues, outcomesValues);
          break;
        }
      }
      default: return onFilterChange(field, value);
    }
  }

  onSearchReset() {
    const { dispatch, onSearchReset } = this.props;
    dispatch(reset('SmartLocksEventsSearchBar'));
    if (onSearchReset) {
      onSearchReset();
    } else {
      this.onSearchSubmit();
    }
  }

  onRefresh() {
    const { onSearchRefresh } = this.props;
    if (onSearchRefresh) {
      onSearchRefresh();
    }
  }

  async onFilterLocksTags(name) {
    const { dispatch } = this.props;
    const tags = await dispatch(LocksActions.fetchLocksTagsByName(name));
    const filteredTags = _.filter(tags, tag => tag.type !== 'lock');
    const options = _.map(filteredTags, tag => ({ value: tag.id, label: tag.name }));
    this.setState({ locksTagsOptions: options });
    return options;
  }

  async onFilterGuestTags(name) {
    const { dispatch } = this.props;
    const tags = await dispatch(GuestActions.fetchGuestsTagsByName(name));
    const filteredTags = _.filter(tags, tag => tag.type !== 'user');
    const options = _.map(filteredTags, tag => ({ value: tag.id, label: tag.name }));
    this.setState({ guestTagsOptions: options });
    return options;
  }

  onSelectGuestTags(tags) {
    const { onFilterChange } = this.props;
    if (tags && !_.isEmpty(tags)) {
      const formattedTags = _.map(tags, tag => tag.value);
      onFilterChange('userTagIds', formattedTags);
    } else {
      onFilterChange('userTagIds', []);
    }
  }

  onSelectLockTags(tags) {
    const { onFilterChange } = this.props;
    if (tags && !_.isEmpty(tags)) {
      const formattedTags = _.map(tags, tag => tag.value);
      onFilterChange('lockTagIds', formattedTags);
    } else {
      onFilterChange('lockTagIds', []);
    }
  }

  render() {
    const { themeName, eventTypesOptions, numberOfElements, dispatch, onResetFilterField, exportSmartLocksEvents } = this.props;
    const { entitiesOptions, guestTagsOptions, locksOptions, locksTagsOptions } = this.state;
    const themeClass = `list-view-header-inplace bg-${themeName}-theme`;
    const formattedNumerOfElements = numberOfElements ? numberOfElements.toLocaleString(undefined) : 0;
    return (
      <div className={themeClass} style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ minHeight: 70, padding: 0, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'column', marginLeft: 0, position: 'relative' }}>
          <SearchFiltersAccordion
            defaultExpanded
            containerstyle={{ marginTop: 5 }}
            primaryFilters={
              <h3 style={{ fontWeight: 'bold', margin: 0, color: 'white' }}><Entity entity="eventfilters" /></h3>
            }
          >
            <div style={{ marginTop: -15, paddingLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <div style={{ display: 'flex' }}>
                  <Field
                    title={<Entity entity="selectUser" />}
                    placeholder={<Entity entity="selectUserTag" />}
                    name="guestSelected"
                    component={SelectableField}
                    options={entitiesOptions}
                    titleStyle={{ color: 'white', fontSize: 16 }}
                    containerstyle={{ minWidth: 300, marginRight: 20 }}
                    onInputChange={value => this.onFilterGuests(value)}
                    onSelect={guestData => this.onHandleFilerChange(guestData && guestData.value ? guestData.value : null, 'actorId')}
                  />
                  <Field
                    name="guestTagSelectedSearchLogs"
                    title={<Entity entity="guestsTags" />}
                    component={SelectableField}
                    noCache
                    multi
                    options={guestTagsOptions}
                    titleStyle={{ color: 'white', fontSize: 16 }}
                    placeholder={<Entity entity="searchTags" />}
                    containerstyle={{ minWidth: 300, marginRight: 20 }}
                    onInputChange={value => this.onFilterGuestTags(value)}
                    onSelect={tagData => this.onSelectGuestTags(tagData)}
                  />
                  <Field
                    id="eventType"
                    name="eventTypes"
                    className="form-control-select"
                    title={<h4 style={{ fontWeight: 'bold', fontSize: 16, color: 'white' }}><Entity entity="eventType" /></h4>}
                    component={CustomField}
                    componentClass="select"
                    containerStyle={{ width: 350 }}
                    onHandleChange={value => this.onHandleFilerChange(value, 'eventTypes')}
                  >
                    {_.map(eventTypesOptions, (type) =>
                      <Entity
                        key={type.entity}
                        componentClass={TranslatableOption}
                        value={type.value}
                        componentAttribute="text"
                        entity={type.entity}
                      />)}
                  </Field>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                  <Field
                    name="lockSelected"
                    title={<Entity entity="lockNameSearch" />}
                    placeholder={<Entity entity="selectLock" />}
                    titleStyle={{ color: 'white', fontSize: 16, marginTop: 0 }}
                    component={SelectableField}
                    options={locksOptions}
                    containerstyle={{ zIndex: 10, minWidth: 300, marginRight: 20 }}
                    onInputChange={value => this.onFilterLocks(value)}
                    onSelect={lock => this.onHandleFilerChange(lock && lock.value ? lock.value : null, 'smartLockId')}
                  />
                  <Field
                    name="lockTagSelectedSearchLogs"
                    title={<Entity entity="lockTags" />}
                    component={SelectableField}
                    noCache
                    multi
                    options={locksTagsOptions}
                    placeholder={<Entity entity="searchTags" />}
                    titleStyle={{ color: 'white', fontSize: 16, marginTop: 0 }}
                    containerstyle={{ zIndex: 10, minWidth: 300, marginRight: 20 }}
                    onInputChange={value => this.onFilterLocksTags(value)}
                    onSelect={tagData => this.onSelectLockTags(tagData)}
                  />
                  <div style={{ marginTop: -5 }}>
                    <h4 style={{ marginTop: 5, marginBottom: 10, fontWeight: 'bold', fontSize: 16, color: 'white' }}><Entity entity="start" /></h4>
                    <Field
                      id="fromDate"
                      name="fromDate"
                      showClearDate={false}
                      placeholder={'--/--/----'}
                      showDefaultInputIcon
                      onHandleChange={(value) => {
                        dispatch(change('SmartLocksEventsSearchBar', 'fromDate', value));
                        if (value) {
                          this.onHandleFilerChange(moment(value).startOf('day').valueOf(), 'fromDate');
                        } else {
                          onResetFilterField('fromDate');
                        }
                      }}
                      component={DatePickerField}
                    />
                  </div>
                  <div style={{ marginTop: -5, marginLeft: 15 }}>
                    <h4 style={{ marginTop: 5, marginBottom: 10, fontWeight: 'bold', fontSize: 16, color: 'white' }}><Entity entity="end" /></h4>
                    <Field
                      id="toDate"
                      name="toDate"
                      showClearDate
                      placeholder={'--/--/----'}
                      showDefaultInputIcon
                      onHandleChange={(value) => {
                        dispatch(change('SmartLocksEventsSearchBar', 'toDate', value));
                        if (value) {
                          this.onHandleFilerChange(moment(value).endOf('day').valueOf(), 'toDate');
                        } else {
                          onResetFilterField('toDate');
                        }
                      }}
                      component={DatePickerField}
                    />
                  </div>
                </div>
              </div>
              <div>
                <MDButton
                  containerStyle={{ margin: 0, marginLeft: 35, marginRight: 10 }}
                  backgroundColor={GREEN}
                  title={<Entity entity="refresh" />}
                  onClick={() => this.onRefresh()}
                />
                <MDButton
                  containerStyle={{ margin: 0, marginLeft: 35, marginRight: 10, marginTop: 20 }}
                  backgroundColor="#D32F2F"
                  title={<Entity entity="resetFilters" />}
                  onClick={() => this.onSearchReset()}
                />
              </div>
            </div>
          </SearchFiltersAccordion>
        </div>
        {exportSmartLocksEvents ? (
          <div style={{ marginLeft: 45, marginTop: 25, marginBottom: 20 }}>
            <EventsExportMenu
              numberOfElements={numberOfElements}
              onExportLogs={format => exportSmartLocksEvents(format)}
            />
          </div>
        ): null}
      </div>
    );
  }
} 

export default SmartLocksEventsSearchBar;

// @ts-nocheck
import { Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { change } from 'redux-form';
import CameraSnippetsListView from '../../components/Cameras/CameraSnippetsListView.jsx';
import SubcompaniesSmartLocksEventsSearchBar from '../../components/forms/LogsEvents/SubcompaniesSmartLocksEventsSearchBar.jsx';
import LogEventsList from '../../components/LogEvents/LogEventsList.jsx';
import * as CamerasActions from '../../redux/actions/cameras.actions.js';
import * as CustomFieldsActions from '../../redux/actions/customFields.actions.js';
import * as LocksActions from '../../redux/actions/lock.actions.js';
import * as EventsActions from '../../redux/actions/logEvents.actions.js';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import * as UtilsActions from '../../redux/actions/utils.actions.js';
import { DOOR_LOCK_EVENT_TYPE_FILTERS, DOOR_LOCK_EVENT_TYPE_FILTERS_NAME } from '../../_config/consts.js';

const eventTypesOptions = _.map(DOOR_LOCK_EVENT_TYPE_FILTERS, doorEvent => ({ entity: DOOR_LOCK_EVENT_TYPE_FILTERS_NAME[doorEvent], value: _.findKey(DOOR_LOCK_EVENT_TYPE_FILTERS, event => event === doorEvent) }));
@connect(state => ({
  smartLocksEvents: state.logEvents.subcompaniesSmartLocksEvents,
  themeName: state.settings.items.theme.data.themeName,
  viewLoading: state.utils.viewLoading,
  companyConfigurations: state.user.companyConfigurations,
  subcompanies: state.settings.subcompanies,
  routing: state.router,
}))
class SubcompaniesSmartLocksEvents extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        text: '',
      },
    };
  }
  async componentWillMount() {
    const { dispatch, routing, avoidReloading, companyConfigurations, subcompanies } = this.props;
    if (!avoidReloading) {
      dispatch(EventsActions.resetAdminEventsData());
      dispatch(EventsActions.resetSubcompaniesSmartLocksEventsFilters());
      const initialFromDate = moment().subtract(1, 'month').valueOf();
      dispatch(change('SubcompaniesSmartLocksEventsSearchBar', 'fromDate', initialFromDate));
      dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('fromDate', initialFromDate));

      if (subcompanies && subcompanies.length) {
        const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
        const subcompanyId = parsed.subcompanyId;
        dispatch(change('SubcompaniesSmartLocksEventsSearchBar', 'companyId', subcompanyId || subcompanies[0].id));
        dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('companyId', subcompanyId || subcompanies[0].id));
      }
      
      await this.onFetchLogs();
      const isCameraSectionActive = dispatch(UtilsActions.isEagleEyeIntegrationActive());
      if (isCameraSectionActive) {
        dispatch(CustomFieldsActions.fetchLocksCustomFields());
        await dispatch(CamerasActions.fetchLocksWithCameras());
      }
    }
    const isArgoOfflineLocksFeaturesEnabled = companyConfigurations && companyConfigurations.argoOfflineEnabled;
    if (isArgoOfflineLocksFeaturesEnabled && !avoidReloading) {
      this.onCheckOfflineLocks();
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { routing: nextRouting, subcompanies } = nextProps;
    const { routing: oldRouting, dispatch } = this.props;
    if (nextRouting && oldRouting && nextRouting !== oldRouting && nextRouting.location && oldRouting.location && nextRouting.location.search !== oldRouting.location.search) {
      if (subcompanies && subcompanies.length) {
        const parsed = qs.parse(nextRouting.location.search, { ignoreQueryPrefix: true });
        const subcompanyId = parsed.subcompanyId;
        dispatch(change('SubcompaniesSmartLocksEventsSearchBar', 'companyId', subcompanyId || subcompanies[0].id));
        dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('companyId', subcompanyId || subcompanies[0].id));
      }
      await this.onFetchLogs();
    }

  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(ModalActions.hideModal());
  }


  onSearchKeyPressed(e) {
    const { activeIndex } = this.state;
    if (e) {
      const charCode = e.which || e.keyCode;
      // If ENTER is pressed
      if (charCode === 13) {
        this.onFetchLogs();
      }
    }
  }

  async onFetchLogs() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onAppendLogs(page) {
    const { dispatch } = this.props;
    dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents(page, 50, true));
  }

  async onResetSearchFilters() {
    const { dispatch, subcompanies, routing } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(EventsActions.resetSmartLocksEventsData());
      dispatch(EventsActions.resetSubcompaniesSmartLocksEventsFilters());
      const initialFromDate = moment().subtract(1, 'month').valueOf();
      dispatch(change('SubcompaniesSmartLocksEventsSearchBar', 'fromDate', initialFromDate));
      dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('fromDate', initialFromDate));

      if (subcompanies && subcompanies.length) {
        const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
        const subcompanyId = parsed.subcompanyId;
        dispatch(change('SubcompaniesSmartLocksEventsSearchBar', 'companyId', subcompanyId || subcompanies[0].id));
        dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('companyId', subcompanyId || subcompanies[0].id));
      }

      await dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onSearchRefresh() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onHandleFilerChange(field, value) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter(field, value));
      await dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onEventTypeFilterChange(types, outcomes) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('eventTypes', types));
      dispatch(EventsActions.setSubcompaniesSmartLocksEventsFilter('eventOutcomes', outcomes));
      await dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onClearFilterField(field) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(EventsActions.clearSmartLockEventsFilter(field));
      await dispatch(EventsActions.fetchSubcompaniesSmartLocksEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onLockClick(lockId) {
    const { dispatch } = this.props;
    dispatch(push(`/locks?lockId=${lockId}`));
  }

  onUserClick(userId) {
    const { dispatch } = this.props;
    dispatch(push(`/guests?guestId=${userId}`));
  }

  onInvitationCodeClick(invitationCode) {
    const { dispatch } = this.props;
    dispatch(push(`/invitations?invitationId=${invitationCode}`));
  }

  async onCheckOfflineLocks() {
    const { dispatch } = this.props;
    try {
      const offLineLocks = await dispatch(LocksActions.fetchOfflineLocksNumber());
      if (offLineLocks > 0) {
        dispatch(ModalActions.showModal({
          modalType: 'WARNING_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="offlineLocksEventsWarning" /></h6>),
          },
        }));
      }
    } catch (error) {
    }
  }

  async onShowCameraSnippet(lockId, event) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const { timestamp } = event;
      const fromDate = moment(timestamp).subtract(5, 'minutes').valueOf();
      const toDate = moment(timestamp).add(5, 'minutes').valueOf();
      const cameraId = dispatch(CamerasActions.findCameraIdFromAssociatedLock(lockId));
      const cameraSnippets = await dispatch(CamerasActions.fetchCameraMedias(cameraId, fromDate, toDate));
      if (cameraSnippets && !_.isEmpty(cameraSnippets)) {
        const params = {
          modalType: 'CONTENT_MODAL',
          modalProps: {
            title: <Entity entity="cameraSnippets" />,
            content: (
              <CameraSnippetsListView
                cameraSnippets={cameraSnippets}
              />
            ),
            modalStyle: { height: '90% !important' },
            onOutsideClick: () => dispatch(ModalActions.hideModal()),
          },
        };
        dispatch(ModalActions.showModal(params));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'WARNING_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="cameraSnippetNotFound" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorGettingCameraSnippet" /></h6>),
        },
      }));
    }

  }

  exportSmartLocksEvents(format) {
    const { dispatch } = this.props;
    dispatch(EventsActions.exportSubCompanySmartLocksEvents(`luckey-locks-events-export-${moment().format('DD-MM-YYYY-HH:mm')}`, format));
  }

  render() {
    const { themeName, avoidUserClick, hideFilters, viewLoading, containerStyle, listContainerStyle, smartLocksEvents: { content: smartLocksEventsData, pagination: smartLocksPagination } } = this.props;
    return (
      <div className="smartlocks-events-container" style={{ marginTop: -10, backgroundColor: 'white', ...containerStyle }}>
        {!hideFilters && (
          <SubcompaniesSmartLocksEventsSearchBar
            themeName={themeName}
            eventTypesOptions={eventTypesOptions}
            numberOfElements={smartLocksPagination.totalElements}
            onFilterChange={(field, value) => this.onHandleFilerChange(field, value)}
            onEventTypeFilterChange={(types, outcomes) => this.onEventTypeFilterChange(types, outcomes)}
            onSearchReset={() => this.onResetSearchFilters()}
            onSearchRefresh={() => this.onSearchRefresh()}
            onResetFilterField={field => this.onClearFilterField(field)}
            exportSmartLocksEvents={(format) => this.exportSmartLocksEvents(format)}
          />)}
        <div className="smartlocks-events-list-container" style={{ paddingLeft: 20, marginTop: 0, backgroundColor: 'white', ...listContainerStyle }}>
          <LogEventsList
            eventType="DOOR"
            avoidUserClick={avoidUserClick}
            viewLoading={viewLoading}
            events={smartLocksEventsData}
            pagination={smartLocksPagination}
            listStyle={{ paddingBottom: 320 }}
            onLoadMore={page => this.onAppendLogs(page)}
            onLockClick={lock => this.onLockClick(lock)}
            onUserClick={userId => this.onUserClick(userId)}
            onShowCameraSnippet={(lockId, event) => this.onShowCameraSnippet(lockId, event) }
            onInvitationCodeClick={invitationCode => this.onInvitationCodeClick(invitationCode)}
          />
        </div>
      </div>
    );
  }
} 

export default SubcompaniesSmartLocksEvents;

import { Card, createTheme, IconButton, withStyles } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Cancel';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import LivePlayer from '@een/live-video-web-sdk'
import React from 'react';
import LockOpen from '@material-ui/icons/LockOpen';
import { BLUE, PERMISSION_ENTITIES, PERMISSIONS } from '../../_config/consts';
import { getLockImage } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import MDButton from '../MDButton/MDButton.jsx';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
    fontSize: 30,
    marginLeft: 20,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  closeIcon: {
    fontSize: 35,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class CameraLiveFeedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingStream: true,
    }
  }

  componentDidMount() {
    const { camera } = this.props;
    if (camera && !camera.isFakeCamera) {
      const config = {
        videoElement: document.getElementById("videoElement"),
        videoTech: "WebCodecs",
        cameraId: camera.id,
        jwt: camera.accessToken,
      }
      const player = new LivePlayer();
      player.start(config);
    }
  }


  render() {
    const { camera, classes, onCloseModal, onOpenLockForCamera, showFakeSnippet, fromDate, toDate } = this.props;
    const canSendGatewayNotification = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <h4 className={classes.rowTitle}>{camera.name || <Entity entity="cameraLiveFeed" />}</h4>
          <IconButton onClick={() => onCloseModal()}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        {camera && !camera.isFakeCamera ? (
          <video id="videoElement" controls autoPlay muted height={500} style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }} />
        ) : (
          <div style={{ display: 'flex', margin: 'auto' }}>
            {showFakeSnippet ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4 className={classes.rowTitle} style={{ marginLeft: 0, fontSize: 25 }}>{`Video #1`}</h4>
                <h4 className={classes.rowBody} style={{ marginBottom: 0 }}>{`${moment(fromDate).format('LLL')} - ${moment(toDate).format('LLL')}`}</h4>
                <video controls autoPlay muted height={500} style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }}>
                  <source src='/videos/person_entering_1.mp4' type='video/mp4' />
                </video>
              </div>
            ) : (
              <img alt="camera-preview" src={'/imgs/common/eagleEye/video_static.png'} height={500} style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }} />
            )}
          </div>
        )}
        {camera && camera.smartLocks && !_.isEmpty(camera.smartLocks) ? (
          <div style={{ padding: 20, paddingTop: 0 }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold'}}><Entity entity="monitoredDoors" /></h3>
            {_.map(camera.smartLocks, lockAssociated => {
              const isFullOnlineGate = lockAssociated.configuration && lockAssociated.configuration.remoteOpenEnabled;
              const canBeOpenRemotely = (lockAssociated.gatewayId && canSendGatewayNotification) || isFullOnlineGate;
              return (
              <Card className='card card-rounded card-noHover' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img alt="lock" className="lock-icon" style={{ width: 45, height: 45, marginRight: 15 }} src={getLockImage(lockAssociated.model)} />
                <h4>{lockAssociated.name}</h4>
                {canBeOpenRemotely && (
                  <MDButton
                    containerStyle={{ margin: 0, marginLeft: 30 }}
                    onClick={() => onOpenLockForCamera(lockAssociated)}
                    icon={<LockOpen style={{ color: 'white', marginLeft: 10, fontSize: 15 }} />}
                    title={<Entity entity="openLock" />}
                  >
                  </MDButton>
                )}
              </Card>
            )})}
          </div>
        ) : null}
      </div>
    );
  }

}
export default withStyles(styles)(CameraLiveFeedView);
